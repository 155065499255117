<template>
  <div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Process Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Packet / Lot</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.packet_no"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search Packet / Kapan"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-2">
                <label for="update-profile-form-6" class="form-label">Select Kapan</label>
                <TomSelect v-model="filter.kapan_id" :options="{
              placeholder: 'Select Kapan',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Process'
                }
              }
            }" class="w-full">
                    <option value="0">Select Kapan</option>
                    <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-2">
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3 h-6"
                type="checkbox" v-model="filter.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                class="form-control"
                :disabled="filter.issue_pending"
                placeholder=""
                v-model="filter.start_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.end_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Party</label
              >
              <TomSelect
                v-model="filter.party_id"
                :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Party</option>
              <option v-for="party in page_data.parties" :key="party.id" :value="party.id">{{party.first_name}} {{ party.last_name }}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Process</label
              >
              <TomSelect
                v-model="filter.process_id"
                @change="getProcessChange"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Process</option>
              <option v-for="process in page_data.processes" :key="process.id" :value="process.id">{{process.process_name}} ( {{ process.process_short_code ? process.process_short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_role == 1">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Department</label
              >
              <TomSelect
                v-model="filter.department_id"
                :options="{
                  placeholder: 'Select Department',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Department'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Department</option>
              <option v-for="department in page_data.departments" :key="department.id" :value="department.id">{{department.department_name}} ( {{ department.department_short_code ? department.department_short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_role == 1">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Manager</label
              >
              <TomSelect
                v-model="filter.manager_id"
                @change="getEmployeeDetails"
                :options="{
                  placeholder: 'Select Manager',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Manager'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Manager</option>
              <option v-for="manager in page_data.managers" :key="manager.id" :value="manager.id">{{manager.first_name}} {{manager.last_name}} ( {{ manager.short_code ? manager.short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Employee Type</label
              >
              <TomSelect
                v-model="filter.employee_type"
                @change="getEmployeeDetails"
                :options="{
                  placeholder: 'Select Employee Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Employee Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Karigar Type</option>
              <option value="1">Employee</option>
              <option value="2">Planner / Checker</option>
              <option value="3">Jobwork Party</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="filter.employee_type != '0'">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Employee</label
              >
              <TomSelect
                v-model="filter.employee_id"
                :options="{
                  placeholder: 'Select Employee',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Employee'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Employee</option>
              <option v-for="employee in page_data.employees" :key="employee.id" :value="employee.id">{{employee.first_name}} {{employee.last_name}} ( {{ employee.process_short_code ? employee.short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Type</label
              >
              <TomSelect
                v-model="filter.type"
                :options="{
                  placeholder: 'Select Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Type</option>
              <option value="makeable">Makeable</option>
              <option value="rough">Rough</option>
              <option value="video">Video</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Status</label
              >
              <TomSelect
                v-model="filter.status"
                :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Status</option>
              <option value="1">Pending</option>
              <option value="2">Received</option>
              <option value="3">Cancelled</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
      </div>

      <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="page_data.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="w-full mt-3 border-2">
                  <thead>
                    <tr class="border-b bg-gray-300 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Packet No</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Stock No</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Proc</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Type</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Dept</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Mgr</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Emp</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">SCPCPSFTS</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><span>Issue Weight</span><br><span>Receive Weight</span></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><span>Issue Date</span><br><span>Receive Date</span></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><span>Issue Comment</span><br><span>Receive Comment</span></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Lose Wt.</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Rejection Wt.</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">New Rejection Wt.</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"><span>{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</span><br><span>{{ page_data.total.receive_weight ? $h.myFloat(page_data.total.receive_weight) + ' Ct' : '-' }}</span></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.lose_weight ? $h.myFloat(page_data.total.lose_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.assortment_weight ? $h.myFloat(page_data.total.assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.net_assortment_weight ? $h.myFloat(page_data.total.net_assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    </tr>
                    <tr v-for="(single_item,ind) in page_data.items" :key="ind" class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (ind % 2) == 0}">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ ind + 1 }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"><a  href="javascript:;" title="Preview Image" data-toggle="modal" data-target="#preview-image-modal" @click="emitModalData(single_item)" > {{ single_item.number }}</a></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"><a  href="javascript:;" title="Preview Image" data-toggle="modal" data-target="#preview-image-modal" @click="emitModalData(single_item)" > {{ single_item.vepari_no }}</a></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.process_short_code ? single_item.process_short_code : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.packet_type ? single_item.packet_type : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.department_short_code ? single_item.department_short_code : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.manager_short_name ? single_item.manager_short_name : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.employee_short_name ? single_item.employee_short_name : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ ((single_item.shape ? single_item.shape : '-') + ', ' + (single_item.color ? single_item.color : '-') + ', ' + (single_item.purity ? single_item.purity : '-') + ', ' + (single_item.cut ? single_item.cut : '-') + ', ' + (single_item.polish ? single_item.polish : '-') + ', ' + (single_item.symmetry ? single_item.symmetry : '-') + ', ' + (single_item.fluerocent ? single_item.fluerocent : '-') + ', ' + (single_item.tension ? single_item.tension : '-') + ', ' + (single_item.stone ? single_item.stone : '-')) }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"><span>{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) + ' Ct' : '-' }}</span><br><span>{{ single_item.receive_weight ? $h.myFloat(single_item.receive_weight) + ' Ct' : '-' }}</span></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"><span>{{ single_item.issue_date ? single_item.issue_date : '-' }}</span><br><span>{{ single_item.receive_date ? single_item.receive_date : '-' }}</span></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"><span>{{ single_item.issue_comment ? single_item.issue_comment : '-' }}</span><br><span>{{ single_item.receive_comment ? single_item.receive_comment : '-' }}</span></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.lose_weight ? $h.myFloat(single_item.lose_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.assortment_weight ? $h.myFloat(single_item.assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.net_assortment_weight ? $h.myFloat(single_item.net_assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"><span v-if="single_item.status == 1" class="text-theme-1">In Process</span><span v-if="single_item.status == 2" class="text-theme-9">Received</span><span v-if="single_item.status == 3" class="text-theme-6">Cancelled</span></td>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"><span>{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</span><br><span>{{ page_data.total.receive_weight ? $h.myFloat(page_data.total.receive_weight) + ' Ct' : '-' }}</span></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.lose_weight ? $h.myFloat(page_data.total.lose_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.assortment_weight ? $h.myFloat(page_data.total.assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.net_assortment_weight ? $h.myFloat(page_data.total.net_assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    </tr>
                  </tbody>

                </table>
                <!-- BEGIN: Pagination -->
                  <div v-if="page_data.items.length > 0"
                      class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
                    >
                      <ul class="pagination">
                        <li>
                          <button class="pagination__link" :disabled="page_data.current_page == 1" @click="getReportDetailPage(page_data.first_page_url)">
                            <ChevronsLeftIcon class="w-4 h-4" />
                          </button>
                        </li>
                        <!-- <li>
                          <button class="pagination__link" :disabled="page_data.prev_page_url == null" @click="getReportDetailPage(page_data.prev_page_url)">
                            <ChevronLeftIcon class="w-4 h-4" />
                          </button>
                        </li> -->
                        <li v-for="(pagelink,index) in page_data.pagelinks" :key="index">
                          <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" :disabled="pagelink.url == null" @click='getReportDetailPage(pagelink.url)'><span v-if="index == 0"><ChevronLeftIcon class="w-4 h-4" /></span><span v-else-if="index == page_data.pagelinks.length - 1"><ChevronRightIcon class="w-4 h-4" /></span><span v-else>{{ pagelink.label }}</span></button>
                        </li>
                        <!-- <li>
                          <button class="pagination__link" :disabled="page_data.next_page_url == null" @click="getReportDetailPage(page_data.next_page_url)">
                            <ChevronRightIcon class="w-4 h-4" />
                          </button>
                        </li> -->
                        <li>
                          <button class="pagination__link" :disabled="page_data.current_page == page_data.last_page" @click="getReportDetailPage(page_data.last_page_url)">
                            <ChevronsRightIcon class="w-4 h-4" />
                          </button>
                        </li>
                      </ul>
                      <select class="w-20 form-select box mt-3 sm:mt-0" v-model="filter.size" @change="getAvailPkt">
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                      </select>
                  </div>
                  <!-- END: Pagination -->
              </div>
            </div>
          </div>
        </div>
    </div>
      <!-- END: HTML Table Data -->
      <div id="preview-image-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xxl">
        <div class="modal-content">
            <a data-dismiss="modal" href="javascript:;">
                <XIcon class="w-8 h-8 text-gray-500" />
            </a>
            <div class="modal-body p-10">
                <div class="flex flex-col justify-between mt-5">
                    <div class="intro-y overflow-auto">
                        <div class="flex items-center">
                          <span v-if="page_data.modalItem.number" class="text-lg font-medium">Packet Number: ({{ page_data.modalItem.number }}) -  ({{ page_data.modalItem.vepari_no }}) </span>
                                            </div>
                    </div>
                    <hr>
                    <!-- First Grid for Process Name, Manager Name, and Employee Name -->
                    <div class="grid grid-cols-12 gap-4">
                        <div class="intro-y col-span-6">
                            <!-- Display Process Name, Manager Name, and Employee Name -->
                            <div class="text-lg font-medium">
                                Process Name: {{ page_data.modalItem.process_short_code ? page_data.modalItem.process_short_code : '' }}
                            </div>
                            <div class="text-lg font-medium">
                                Manager Name: {{ page_data.modalItem.transaction_type == 1 ? page_data.modalItem.issue_short_code : page_data.modalItem.manager_short_code }}
                            </div>
                            <div class="text-lg font-medium">
                                Employee Name: {{ page_data.modalItem.transaction_type == 1 ? page_data.modalItem.manager_short_code : (page_data.modalItem.process_type == 1 || page_data.modalItem.process_type == 2 || page_data.modalItem.process_type == 5 || page_data.modalItem.process_type == 6 || page_data.modalItem.process_type == 7 || page_data.modalItem.process_type == 8 || page_data.modalItem.process_type == 10 ? (page_data.modalItem.external == 1 ? page_data.modalItem.jober_short_code :page_data.modalItem.employee_short_code) : (page_data.modalItem.process_type == 2 ? (page_data.modalItem.external == 1 ? page_data.modalItem.jober_short_code :page_data.modalItem.employee_short_code) : page_data.modalItem.planner_short_code)) }}
                            </div>
                        </div>
                        <div class="intro-y col-span-6">
                            <!-- Display Issue Date and Receive Date -->
                            <div class="text-lg font-medium">
                                Issue Date: {{ page_data.modalItem.issue_date }}
                            </div>
                            <div class="text-lg font-medium">
                                Receive Date: {{ page_data.modalItem.receive_date }}
                            </div>
                        </div>
                    </div>
                    <hr>
                    <!-- Second Grid for Image and Receive Image -->
                    <div class="grid grid-cols-12 gap-4 mt-5">
                        <div class="intro-y col-span-6 text-lg font-medium">
                            <!-- Display Image -->
                            Issue Weight: {{ page_data.modalItem.issue_weight }} <br>
                            <iframe :src="page_data.modalItem.issue_url" style="height:600px;width:500px" src="url" title="description"></iframe>
                        </div>
                        <div class="intro-y col-span-6 text-lg font-medium">
                            Receive Weight: {{ page_data.modalItem.receive_weight }} <br>
                            <!-- Display Receive Image -->
                            <iframe :src="page_data.modalItem.receive_url" style="height:600px;width:500px" src="url" title="description"></iframe>
                            <!-- <img :src="page_data.modalItem.receive_url" alt="Preview Image" class="w-full h-auto"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import { defineComponent, onMounted, reactive, toRef, watch, computed } from 'vue';
import store from '@/store'

export default defineComponent({
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const page_data = reactive({
      lot_items : [],
      total : [],
      items : [],
      parties : [],
      managers : [],
      processes : [],
      departments : [],
      employees : [],
      loading : false,
      pagelinks: [],
      modalItem: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
    })
    const filter = reactive({
      start_date : moment().startOf('month').format('YYYY-MM-DD'),
      end_date : moment().endOf('month').format('YYYY-MM-DD'),
      packet_no : '',
      party_id : '0',
      kapan_id : '0',
      process_id : '0',
      department_id : '0',
      manager_id : '0',
      employee_id : '0',
      employee_type : '0',
      status : '0',
      type : '0',
      size : '20',
      issue_pending : false,
    })
    const api_filter = reactive({
      start_date : '',
      end_date : '',
      packet_no : '',
      party_id : '0',
      kapan_id : '0',
      process_id : '0',
      department_id : '0',
      manager_id : '0',
      employee_id : '0',
      employee_type : '0',
      status : '0',
      type : '0',
      size : '20',
      issue_pending : false,
    })
    function getReportDetails() {
      api_filter.issue_pending = filter.issue_pending
      api_filter.start_date = filter.start_date
      api_filter.end_date = filter.end_date
      api_filter.packet_no = filter.packet_no
      api_filter.party_id = filter.party_id
      api_filter.kapan_id = filter.kapan_id
      api_filter.process_id = filter.process_id
      api_filter.department_id = filter.department_id
      api_filter.manager_id = filter.manager_id
      api_filter.employee_id = filter.employee_id
      api_filter.employee_type = filter.employee_type
      api_filter.status = filter.status
      api_filter.type = filter.type
      api_filter.size = filter.size
      getReportDetail()
      getReportDetailsTotal()
    }
    const getReportDetail = () => {
      page_data.loading = true

      let params = {
        start_date : api_filter.start_date,
        end_date : api_filter.end_date,
        issue_pending : api_filter.issue_pending,
        packet_no : window.btoa(api_filter.packet_no),
        party_id : api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id) ,
        kapan_id : api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id) ,
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        department_id : api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id) ,
        manager_id : api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id) ,
        employee_id : api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id) ,
        employee_type : api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type) ,
        status : api_filter.status == '0' ? '' : window.btoa(api_filter.status) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
        size : api_filter.size,
      }

      let promise = axios({
        url: 'api/report/get_process_report_details',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data.current_page)
          page_data.items = data.data
          page_data.pagelinks = data.links
          page_data.prev_page_url = data.prev_page_url
          page_data.next_page_url = data.next_page_url
          page_data.first_page_url = data.first_page_url
          page_data.last_page_url = data.last_page_url
          page_data.current_page = data.current_page
          page_data.last_page = data.last_page
          page_data.loading = false
        })
        .catch(_error => {
          // page_data.items = []
          page_data.loading = false
        });
    }
    const getReportDetailPage = (url) => {
      page_data.loading = true

      let params = {
        start_date : api_filter.start_date,
        end_date : api_filter.end_date,
        issue_pending : api_filter.issue_pending,
        packet_no : window.btoa(api_filter.packet_no),
        party_id : api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id) ,
        kapan_id : api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id) ,
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        department_id : api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id) ,
        manager_id : api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id) ,
        employee_id : api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id) ,
        employee_type : api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type) ,
        status : api_filter.status == '0' ? '' : window.btoa(api_filter.status) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
        size : api_filter.size,
      }

      let promise = axios({
        url: url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.items = data.data
          page_data.pagelinks = data.links
          page_data.prev_page_url = data.prev_page_url
          page_data.next_page_url = data.next_page_url
          page_data.first_page_url = data.first_page_url
          page_data.last_page_url = data.last_page_url
          page_data.current_page = data.current_page
          page_data.last_page = data.last_page
          page_data.loading = false
        })
        .catch(_error => {
          page_data.items = []
          page_data.loading = false
        });
    }
    const getReportDetailsTotal = () => {
      let params = {
        start_date : api_filter.start_date,
        end_date : api_filter.end_date,
        issue_pending : api_filter.issue_pending,
        packet_no : window.btoa(api_filter.packet_no),
        party_id : api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id) ,
        kapan_id : api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id) ,
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        department_id : api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id) ,
        manager_id : api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id) ,
        employee_id : api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id) ,
        employee_type : api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type) ,
        status : api_filter.status == '0' ? '' : window.btoa(api_filter.status) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
      }

      let promise = axios({
        url: 'api/report/get_process_report_details_total',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.total = data.total
        })
        .catch(_error => {
          page_data.total = []
        });
    }
    const getPartyDetails = () => {

        let params = {
          // department_id : window.btoa(filter.department_id)
        }
        let api_url = 'api/master/dp_parties'
        let promise = axios({
          url: api_url,
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            page_data.parties = data.items
          })
          .catch(_error => {
            page_data.parties = []
          });
    }
    const getDepartmentDetails = () => {
      let params = {
        // department_id : window.btoa(filter.department_id)
      }
      let api_url = 'api/master/dp_department'
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.departments = data.items
        })
        .catch(_error => {
          page_data.departments = []
        });
    }
    const getManagerDetails = () => {
      let params = {
        department_id : filter.department_id == '0' ? '' : window.btoa(filter.department_id),
        process_id : filter.process_id == '0' ? '' : window.btoa(filter.process_id)
      }
      let api_url = ''

      if(filter.department_id == '0' && filter.process_id == '0') {

        api_url = 'api/master/dp_managers'
      } else {
        api_url = 'api/master/get_manager_by_department'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.manager_id = '0'
          page_data.managers = data.items
        })
        .catch(_error => {
          filter.manager_id = '0'
          page_data.managers = []
        });
      }
    const getProcessDetails = () => {
      let params = {
        id : window.btoa(filter.manager_id)
      }
      let api_url = ''

      if(filter.manager_id == '0') {

        api_url = 'api/master/dp_process'
      } else {
        api_url = 'api/master/dp_process'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.process_id = '0'
          page_data.processes = data.items
        })
        .catch(_error => {
          filter.process_id = '0'
          page_data.processes = []
        });
    }
    const getLotDetails = async () => {
            let params = {
            }
            let api_url = 'api/master/dp_lot_all_details'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.kapan_id = '0'
                    page_data.lot_items = data.items
                })
                .catch(_error => {
                    filter.kapan_id = '0'
                    page_data.lot_items = []
                });
        }
    const getEmployeeDetails = () => {

      let params = {
        manager_id : user_details.value.user_role == 1 ? (filter.manager_id == '0' ? '' : window.btoa(filter.manager_id)) : user_details.value.account_id,
        process_id : filter.process_id == '0' ? '' : window.btoa(filter.process_id),
        employee_type : filter.employee_type == '0' ? '': window.btoa(filter.employee_type),
      }
      let api_url = ''

      if(filter.department_id == '0') {

        api_url = 'api/master/get_employees_by_manager_employee_type'
      } else {
        api_url = 'api/master/get_employees_by_manager_employee_type'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.employee_id = '0'
          page_data.employees = data.items
        })
        .catch(_error => {
          filter.employee_id = '0'
          page_data.employees = []
        });
    }
    function onResetFilter() {
      filter.start_date = moment().startOf('month').format('YYYY-MM-DD')
      filter.end_date = moment().endOf('month').format('YYYY-MM-DD')
      filter.packet_no = ''
      filter.issue_pending = false
      filter.party_id = '0'
      filter.kapan_id = '0'
      filter.process_id = '0'
      filter.department_id = '0'
      filter.manager_id = '0'
      filter.employee_id = '0'
      filter.employee_type = '0'
      filter.status = '0'
      filter.type = '0'
    }
    function generateReport() {
      getReportDetails()
    }
    function getProcessChange() {
      if(user_details.value.user_role == 1) {
        getManagerDetails()
      } else {
        getEmployeeDetails()
      }
    }
    const emitModalData = (items) => {
          page_data.modalItem = items
        }
    onMounted(() => {
      getPartyDetails()
      getLotDetails()
      getDepartmentDetails()
      getManagerDetails()
      getProcessDetails()
      generateReport()
    })
    return {
      page_data,
      filter,api_filter,user_details,generateReport,
      getProcessDetails,
      getManagerDetails,
      onResetFilter,
      getEmployeeDetails,
      getProcessChange,
      getReportDetailPage,
      emitModalData
    }
  }
})
</script>
