<template>
  <tr :class="{ 'text-theme-6': packet.blocked == 1 }">
    <td class="text-center text-sm py-1">
      <a class=" flex items-center justify-start" href="javascript:;" title="Preview Image" data-toggle="modal" data-target="#preview-image-modal" @click="emitModalData(packet);">
        <div class="mx-2">
          <div v-if="packet.status == 3 || packet.transaction_status == 3">
            <div class="text-theme-6">
              <ShieldOffIcon class=" h-5 w-5" />
            </div>
          </div>
          <div
            v-else-if="(packet.transaction_type == 1 || packet.transaction_type == 4) && packet.issue_by == user_details.id">
            <div class="text-theme-9">
              <ArrowUpRightIcon class=" h-6 w-6" />
            </div>
          </div>
          <div
            v-else-if="(packet.transaction_type == 1 || packet.transaction_type == 4) && packet.manager_id == user_details.account_id">
            <div class="text-theme-6">
              <ArrowDownLeftIcon class=" h-6 w-6" />
            </div>
          </div>
          <div
            v-else-if="(packet.transaction_type == 1 || packet.transaction_type == 4) && (packet.issue_by != user_details.id && packet.manager_id != user_details.account_id)">
            <div class="text-theme-1">
              <ExternalLinkIcon class=" h-5 w-5" />
            </div>
          </div>
          <!-- CheckIcon -->
          <div v-else-if="(packet.transaction_type == 2 && packet.status == 2)">
            <div class="text-theme-9">
              <CheckIcon class=" h-5 w-5" />
            </div>
            <!-- <button
          class="btn btn-primary mr-1 p-2" @click="page_data.editable = !page_data.editable" v-if="page_data.editable == false" :disabled="packetDet.blocked == 1 || packet.status == 3"
          >
          <ExternalLinkIcon class="h-4 w-4" />
        </button>
        <div v-else>
          <button class="btn bg-primary-9 text-white mr-1 p-2" :disabled="packet.blocked == 1 || packet.status == 3"><SaveIcon class="w-4 h-4" @click="updateParameters"/></button>
          <button class="btn btn-danger mr-1 p-2" @click="page_data.editable = !page_data.editable" v-if="page_data.editable == true" :disabled="packet.blocked == 1 || packet.status == 3"><XIcon class="h-4 w-4" /></button>
        </div> -->
          </div>
          <div v-else-if="(packet.transaction_type == 2 && packet.status == 1)">
            <div class="text-theme-1">
              <ShieldIcon class=" h-5 w-5" />
            </div>
          </div>
        </div>
        <div>{{ packet.transaction_code }}</div>
      </a>
    </td>
    <td class="text-center text-sm py-1">{{ packet.process_short_code ? packet.process_short_code : '' }}</td>
    <td class="text-center text-sm py-1">{{ packet.transaction_type == 1 ? packet.issue_short_code :
    packet.manager_short_code }}</td>
    <td class="text-center text-sm py-1">{{ packet.transaction_type == 1 ? packet.manager_short_code : ([3,4].includes(packet.process_type) ? packet.planner_short_code : (packet.external == 1 ? packet.jober_short_code : packet.employee_short_code))}}</td>
    <td class="text-center text-sm py-1">{{ packet.issue_weight }}</td>
    <td class="text-center text-sm py-1"><input v-if="page_data.editable" type="number"
        class="form-control w-20 text-center p-1" v-model="changes.receive_weight">
      <div v-else>{{ packet.receive_weight }}</div>
    </td>
    <td class="text-center text-sm py-1"><span>{{ packet.issue_date ? packet.issue_date : '-' }}</span><br><span>{{
    packet.receive_date ? packet.receive_date : '-' }}</span></td>
    <td class="text-center text-sm py-1">
      <div v-if="packet.shape">
        <!-- <div class="input-group mt-2 sm:mt-0" v-if="page_data.editable">
          <select class="form-select w-20 p-1" v-model="changes.shape" aria-label="Default select example">
            <option v-for="col in diamond.shapes" :key="col.id" :value="col.id">{{ col.shape_name }}</option>
          </select>
        </div> -->
        <div>
          {{ packet.shape.shape_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-1">
      <div v-if="packet.color">
        <!-- <div class="input-group mt-2 sm:mt-0" v-if="page_data.editable">
          <select class="form-select w-20 p-1" v-model="changes.color" aria-label="Default select example">
            <option v-for="col in diamond.colors" :key="col.id" :value="col.id">{{ col.color_name }}</option>
          </select>
        </div> -->
        <div>
          {{ packet.color.color_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-1">
      <div v-if="packet.purity">
        <!-- <div class="input-group mt-2 sm:mt-0" v-if="page_data.editable">
          <select class="form-select w-20 p-1" v-model="changes.purity" aria-label="Default select example">
            <option v-for="col in diamond.purities" :key="col.id" :value="col.id">{{ col.purity_name }}</option>
          </select>
        </div> -->
        <div>
          {{ packet.purity.purity_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-1">
      <div v-if="packet.cut">
        <!-- <div class="input-group mt-2 sm:mt-0" v-if="page_data.editable">
          <select class="form-select w-16 p-1" v-model="changes.cut" aria-label="Default select example">
            <option v-for="col in diamond.cuts" :key="col.id" :value="col.id">{{ col.cut_name }}</option>
          </select>
        </div> -->
        <div>
          {{ packet.cut.cut_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-1">
      <div v-if="packet.polish">
        <!-- <div class="input-group mt-2 sm:mt-0" v-if="page_data.editable">
          <select class="form-select w-16 p-1" v-model="changes.polish" aria-label="Default select example">
            <option v-for="col in diamond.polish" :key="col.id" :value="col.id">{{ col.polish_name }}</option>
          </select>
        </div> -->
        <div>
          {{ packet.polish.polish_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-1">
      <div v-if="packet.symmetry">
        <!-- <div class="input-group mt-2 sm:mt-0" v-if="page_data.editable">
          <select class="form-select w-16 p-1" v-model="changes.symmetry" aria-label="Default select example">
            <option v-for="col in diamond.symmetry" :key="col.id" :value="col.id">{{ col.symmetry_name }}</option>
          </select>
        </div> -->
        <div>
          {{ packet.symmetry.symmetry_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-1">
      <div class="flex justify-center items-center"
        v-if="packet.status == 1 && packet.transaction_type == 2 && packet.manager_id == user_details.account_id">
        <button class="btn btn-danger btn-sm p-1 px-2 mr-1" :disabled="packet.blocked != null || packet.discuss != null"
          @click="cancelProcess(packet.packet_id)" v-if="packetDet.status == 2">
          X
        </button>
      </div>
      <div class="flex justify-center items-center"
        v-else-if="packet.status == 2 && packet.transaction_type == 2 && user_details.user_role == 1 && page_data.editable == false"
        >
        <button class="btn btn-primary btn-sm p-1 mr-1"
        @click="updateMode()">
        <EditIcon class="h-4 w-4" />
        </button>
        <button class="btn btn-danger btn-sm p-1 px-2 mr-1" :disabled="packet.blocked != null || packet.discuss != null"
          @click="rejectProcess(packet.packet_id)">
          X
        </button>
      </div>
      <div class="flex justify-center items-center"
        v-else-if="index == 0 && packet.status == 2 && packet.transaction_type == 2 && packet.manager_id == user_details.account_id && page_data.editable == false"
        >
        <button class="btn btn-primary btn-sm p-1 mr-1"
        @click="updateMode()">
        <EditIcon class="h-4 w-4" />
        </button>
      </div>
      <div class="flex justify-center items-center"
        v-else-if="page_data.editable == true">
        <button class="btn btn-success btn-sm p-1 mr-1"
          @click="updateProcess(packet.packet_id)">
          <EditIcon class="h-4 w-4" />
        </button>
        <button class="btn btn-danger btn-sm p-1 px-2 mr-1" :disabled="packet.blocked != null || packet.discuss != null"
          @click="page_data.editable = false">
          X
        </button>
      </div>
      <div class="flex justify-center items-center"
        v-else>-</div>
    </td>
  </tr>
</template>

<script>
/* eslint-disable */
import {
  computed,
  defineComponent,
  inject,
  reactive,
  watch
} from 'vue'
import axios from 'axios'
import {
  BASE_URL
} from '@/constants/config'
import store from '@/store'
export default defineComponent({
  props: ['packet', 'index', 'diamond', 'packetDet'],
  emits: ['getDiamondDetail', 'updatParameters', 'getData', 'modelData'],
  setup(props, {
    emit
  }) {

    const swal = inject('$swal')
    const user_details = computed(() => store.state.user.currentUser)
    const page_data = reactive({
      editable: false,
      error: '',
      success: ''
    })

    const changes = reactive({
      id: props.packet.id,
      issue_weight: props.packet.issue_weight,
      receive_weight: props.packet.receive_weight,
      color: props.packet.color ? props.packet.color.id : '',
      cut: props.packet.cut ? props.packet.cut.id : '',
      purity: props.packet.purity ? props.packet.purity.id : '',
      polish: props.packet.polish ? props.packet.polish.id : '',
      shape: props.packet.shape ? props.packet.shape.id : '',
      symmetry: props.packet.symmetry ? props.packet.symmetry.id : '',
      fluerocent: props.packet.fluerocent ? props.packet.fluerocent.id : '',
      stone: props.packet.stone ? props.packet.stone.id : '',
      tension: props.packet.tension ? props.packet.tension.id : '',
    })

    const updateMode = () => {
      page_data.editable = true

      console.log(changes.id)
    }

    const cancelProcess = (id) => {

      swal({
        title: 'Are you sure?',
        text: 'Do you really want to Cancel Packet? This process cannot be undone.',
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm & Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          const params = {
            id: window.btoa(id)
          }

          let promise = axios({
            url: "api/process/cancel_process",
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if (data.status == "error") {
                swal("Error!", data.message, "error");
              } else {
                emit('getData')
                swal("Success!", data.message, "success");
              }
            })
            .catch(_error => {
              swal("Error!", 'Something went wrong', "error");
              return [];
            });
        },
        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
        if (result.value) {
          //swal("Success!", "User Password Updated.", "success");
        }
      });
    }

    const updateProcess = () => {

      swal({
        title: 'Are you sure?',
        text: 'Do you really want to Update packets weight? This process cannot be undone..',
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm & Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          const params = {
            id: changes.id,
            receive_weight: changes.receive_weight,
          }

          let promise = axios({
            url: "api/process/update_process_weight_and_params",
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if (data.status == "error") {
                swal("Error!", data.message, "error");
              } else {
                emit('getData')
                swal("Success!", data.message, "success");
              }
            })
            .catch(_error => {
              swal("Error!", 'Something went wrong', "error");
              return [];
            });
        },
        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
        if (result.value) {
          //swal("Success!", "User Password Updated.", "success");
        }
      });
    }

    const rejectProcess = () => {

      swal({
        title: 'Are you sure?',
        text: 'Do you really want to Delete packets process? This process cannot be undone..',
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm & Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          const params = {
            id: changes.id,
            receive_weight: changes.receive_weight,
          }

          let promise = axios({
            url: "api/process/reject_receive_process",
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if (data.status == "error") {
                swal("Error!", data.message, "error");
              } else {
                emit('getData')
                swal("Success!", data.message, "success");
              }
            })
            .catch(_error => {
              swal("Error!", 'Something went wrong', "error");
              return [];
            });
        },
        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
        if (result.value) {
          //swal("Success!", "User Password Updated.", "success");
        }
      });
    }

    function updateParameters() {
      if (props.packet.type == 'rough') {
        if (parseFloat(changes.weight) != parseFloat(props.packet.receive_weight)) {
          if (parseFloat(props.packet.issue_weight) < parseFloat(changes.weight)) {
            swal("Warning!", "Receive Weight Must Be Less then Issue Weight!", "warning");
            return
          }
          UpdateWeight()
          return
        } else {
          swal("Warning!", "Please Change Parameters !", "warning");
          return
        }
      } else {
        if (changes.color == props.packet.color.id && changes.cut == props.packet.cut.id && changes.purity == props.packet.purity.id && changes.polish == props.packet.polish.id &&
          changes.shape == props.packet.shape.id && changes.symmetry == props.packet.symmetry.id && changes.fluerocent == props.packet.fluerocent.id && changes.stone == props.packet.stone.id &&
          changes.tension == props.packet.tension.id) {
          if (parseFloat(changes.weight) != parseFloat(props.packet.receive_weight)) {
            if (parseFloat(props.packet.issue_weight) < parseFloat(changes.weight)) {
              swal("Warning!", "Receive Weight Must Be Less then Issue Weight!", "warning");
              return
            } else if (parseFloat(props.packetDet.cr_polish_weight) < parseFloat(changes.weight)) {
              UpdateWeight()
              return
            }
          } else {
            swal("Warning!", "Please Change Parameters !", "warning");
            return
          }
        } else {
          emit('updatParameters', changes)
          page_data.editable = false
          return
        }
      }
    }

    function UpdateWeight() {
      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You want to Update Weight ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: 'api/request/update_mdl_process_weight',
            method: "post",
            data: {
              weight: changes.weight,
              request_id: changes.id
            },
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              page_data.editable = false
              swal("Success!", "Transaction Weight is Updated !", "success");
              emit('getData')
            })
            .catch(_error => {
              swal("Warning!", "Somthing went Wrong !", "warning");
            });
        }
      })
    }

    const emitModalData = (items) => {
          emit('modelData', items)
        }
    return {
      changes,
      page_data,
      user_details,
      cancelProcess,
      updateMode,
      updateProcess,
      rejectProcess,
      emitModalData
    }
  }
})
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
