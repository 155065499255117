<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-2">
          <h2 class="text-lg font-medium mr-auto">Job Done Stocks</h2>
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <div class="text-sm font-bold mr-2 mt-5" v-if="page_data.packet_list.length > 0">Selected Stock : {{ page_data.packet_list.length }}</div>
              <div class="flex items-center mt-2 ml-2">
                  <div class="mr-2 flex items-center">
                      <input id="remember-me" type="checkbox" v-model="page_data.check" class="form-check-input border mr-2 bg-white" />
                      <label for="remember-me" class="text-md font-medium">Manual</label>
                  </div>
              </div>
              <div class="w-full flex sm:w-auto mt-3 ml-2">
                  <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
                      <input type="text" v-model="page_data.search" @change="getPacketDetailsScan" autofocus ref="focusMe" :disabled="page_data.scan_disable == true" class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Scan Packet" />
                      <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
                  </div>
                  <button class="btn btn-primary shadow-md mr-2" @click="add_stock_to_list">Add</button>
                  <a class="btn btn-primary shadow-md mr-2" href="javascript:;" data-toggle="modal" @click="AssignProcess" data-target="#new-process-modal" v-if="page_data.packet_list.length > 0">Assign Packets</a>
              </div>
          </div>
      </div>

      <!-- Begin: Scanned Lot Table -->
      <div class="intro-y box py-3 px-3 mt-2">
        <div class=" border border-r-0 border-l-0 border-t-0 pb-10" v-if="page_data.packet_list.length > 0">
          <table class="table w-full border">
              <thead>
                  <tr>
                      <th class="whitespace-nowrap">Pkt No</th>
                      <th class="whitespace-nowrap">Stock No</th>
                      <th class="whitespace-nowrap">Cr. Weight</th>
                      <th class="whitespace-nowrap">Exp. Wt</th>
                      <th class="whitespace-nowrap">Shape</th>
                      <th class="whitespace-nowrap">Color</th>
                      <th class="whitespace-nowrap">Purity</th>
                      <th class="whitespace-nowrap">Cut</th>
                      <th class="whitespace-nowrap">Polish</th>
                      <th class="whitespace-nowrap">Symmetry</th>
                      <th class="whitespace-nowrap">Stone</th>
                      <!-- <th class="whitespace-nowrap">Fluorescence</th> -->
                      <th class="whitespace-nowrap"></th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(packet, index) in page_data.packet_list" :key="index" class="border hover:bg-gray-200 p-0 cursor-pointer">
                      <td class="whitespace-nowrap">{{ packet.number }}</td>
                      <td class="whitespace-nowrap">{{ packet.vepari_no ? packet.vepari_no : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.cr_weight ? packet.cr_weight : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.cr_polish_weight ? packet.cr_polish_weight : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.shape ? packet.shape : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.color ? packet.color : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.purity ? packet.purity : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.cut ? packet.cut : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.polish ? packet.polish : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.symmetry ? packet.symmetry : '-' }}</td>
                      <!-- <td class="whitespace-nowrap">{{ packet.fluerocent ? packet.fluerocent : '-' }}</td> -->
                      <td class="whitespace-nowrap">
                        <TomSelect v-model="filter_data.job_done" :options="{
                          placeholder: 'Job Done Type',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Type'
                            }
                          }
                        }" class="w-full">
                                    <option value="1">Job Done</option>
                                    <option value="2">Cancelled</option>
                                    <!-- <option value="video">Video</option> -->
                                </TomSelect>
                      </td>

                      <td class="whitespace-nowrap">{{ packet.stone ? packet.stone : '-' }}</td>
                      <td class="py-2 font-medium text-center">
                          <div class="flex justify-start items-center p-0 m-0">
                            <a href="javascript:;" @click="removeItem(index)" class="btn btn-danger btn-sm mx-2">
                                  <XIcon class="w-4 h-4" /></a>
                          </div>
                      </td>
                  </tr>
              </tbody>
          </table>
        </div>
        <div class="mt-5">
            <div class="grid grid-cols-12 gap-5">
                <!-- <div class="col-span-12 md:col-span-12 lg:col-span-3 xl:col-span-2">
                        <h2 class="text-lg font-bold">Available Packets</h2>
                    </div> -->
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                    <div>
                        <input id="tabulator-html-filter-value" v-model="filter_data.stock_no" type="text" class="form-control 2xl:w-full" placeholder="Stock No" />
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                    <div>
                        <TomSelect v-model="filter_data.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }" class="w-full">
                            <option value="0">Select Kapan</option>
                            <option v-for="(item, index) in page_data.kapan_details" :key="index" :value="item.id">{{item.lot_no}} ({{ item.voucher_no }})</option>
                        </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                    <div>
                        <TomSelect v-model="filter_data.party_id" :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }" class="w-full">
                            <option value="0">Select Party</option>
                            <option v-for="(item, index) in page_data.vendor_parties" :key="index" :value="item.id">{{item.firm_name}}</option>
                        </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                    <div>
                        <TomSelect v-model="filter_data.packet_type" :options="{
                  placeholder: 'Select Packet Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packet Type'
                    }
                  }
                }" class="w-full">
                            <option value="0">Packet Type</option>
                            <option value="makeable">Makeable</option>
                            <option value="rough">Rough</option>
                            <!-- <option value="video">Video</option> -->
                        </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                    <div>
                        <button class="btn btn-secondary mr-2" @click="resetFilters">
                            Reset
                        </button>
                        <button class="btn btn-primary " @click="initTabulator">
                            Generate
                        </button>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-3">
                    <div class="flex flex-wrap">

                        <div class="text-sm font-bold mr-4">Total Stock: {{ page_data.total.total_packets ? page_data.total.total_packets : 0 }}</div>
                        <div class="text-sm font-bold">Total WT(Ct): {{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) : 0 }} Ct</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
      </div>
      <!-- End: Scanned Lot Table -->
  </div>
  </template>

  <script>
  /* eslint-disable */
  import {
      BASE_URL
  } from '@/constants/config'
  import axios from 'axios'
  import cash from 'cash-dom'
  import {
      computed,
      defineComponent,
      inject,
      onMounted,
      reactive,
      ref,
      toRef,
      watch
  } from 'vue'
  import print from 'vue3-print-nb'
  import feather from 'feather-icons'
  import Tabulator from 'tabulator-tables'
  import Toastify from 'toastify-js'
  import store from '@/store'
  import router from '@/router'
  import user from '@/store/user'
  export default defineComponent({
      directives: {
          print
      },
      setup() {
          const user_details = computed(() => store.state.user.currentUser)
          const focusMe = ref()
          const swal = inject('$swal')
          const page_data = reactive({
              search: '',
              search_type: '',
              check: false,
              type: '',
              addlist: [],
              packet_list: [],
              addornot: false,
              employees: [],
              processes: [],
              parties: [],
              submit: false,
              managers: [],
              vendor_parties: [],
              party_id: '',
              message: '',
              error: '',

              total: [],
              kapan_details: [],
          })
          // const success = toRef(page_data, 'success')
          const filter_data = reactive({
              kapan_id: '0',
              stock_no: '',
              packet_type: '0',
              party_id: '0',
              for_p: false,
              semi_polish: false,
          })
          const process = reactive({
              eternals: '0',
              manager_id: '0',
              process_id: '0',
              employee_id: '0',
              party_id: '0',
              transaction_type: '0',
              type: ''
          })
          const tableRef = ref()
          const tabulator = ref()

          const initTabulator = async () => {

              let filter_params = {
                  kapan_id: filter_data.kapan_id == '0' ? '' : window.btoa(filter_data.kapan_id),
                  party_id: filter_data.party_id == '0' ? '' : window.btoa(filter_data.party_id),
                  stock_no: filter_data.stock_no == '' ? '' : window.btoa(filter_data.stock_no),
                  packet_type: filter_data.packet_type == '0' ? '' : window.btoa(filter_data.packet_type),
                  for_p: filter_data.for_p,
                  semi_polish: filter_data.semi_polish
              }
              tabulator.value = new Tabulator(tableRef.value, {
                  ajaxURL: BASE_URL + 'api/job-done/get_available_packets',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: filter_params,
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  headerSort: false,
                  pagination: 'remote',
                  paginationSize: 20,
                  paginationSizeSelector: [20, 30, 40],
                  layout: 'fitColumns',
                  // responsiveLayout: 'collapse',
                  placeholder: 'No matching records found',
                  columns: [
                      // {
                      //       formatter: 'responsiveCollapse',
                      //       width: 40,
                      //       minWidth: 30,
                      //       hozAlign: 'center',
                      //       resizable: false,
                      //       headerSort: false
                      //   },

                      // For HTML table
                      {
                          title: 'Packet No',
                          responsive: 0,
                          minWidth: 130,
                          field: 'number',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().urgent == 1 ? '<span class="text-theme-6">'+ cell.getData().number +'</span>' : cell.getData().number
                }</div>
              </div>`
                          }
                      },
                      {
                          title: 'Stock No',
                          responsive: 0,
                          field: 'type',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 130,
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
              <div class="whitespace-nowrap">${
                cell.getData().vepari_no
              }</div>
            </div>`
                          }
                      },
                      {
                          title: 'Party',
                          responsive: 0,
                          field: 'type',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 130,
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
              <div class="whitespace-nowrap">${
                cell.getData().firm_name
              }</div>
            </div>`
                          }
                      },
                      {
                          title: 'Type',
                          responsive: 0,
                          field: 'type',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 100,
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().type
                }</div>
              </div>`
                          }
                      },
                      {
                          title: 'Cr.Weight(Ct)',
                          responsive: 0,
                          field: 'cr_weight',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 130,
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().cr_weight
                }</div>
              </div>`
                          }
                      },
                      {
                          title: 'Exp. Wt',
                          responsive: 0,
                          field: 'cr_polish_weight',
                          minWidth: 130,
                          print: false,
                          download: false
                      },
                      {
                          title: 'Shp',
                          field: 'shape',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 90,
                          print: false,
                          download: false
                      },
                      {
                          title: 'Clr',
                          field: 'color',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 90,
                          print: false,
                          download: false
                      },
                      {
                          title: 'Prt',
                          field: 'purity',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 90,
                          print: false,
                          download: false
                      },
                      {
                          title: 'Ct',
                          field: 'cut',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 90,
                          print: false,
                          download: false
                      },
                      {
                          title: 'Pl',
                          field: 'polish',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 90,
                          print: false,
                          download: false
                      },
                      {
                          title: 'Sym',
                          field: 'symmetry',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 90,
                          print: false,
                          download: false
                      },
                      {
                          title: 'Fluc',
                          field: 'fluerocent',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 90,
                          print: false,
                          download: false
                      },
                      // {
                      //     title: 'Tn',
                      //     field: 'tension',
                      //     hozAlign: 'center',
                      //     vertAlign: 'middle',
                      //     print: false,
                      //     download: false
                      // },
                      // {
                      //     title: 'Stn',
                      //     field: 'stone',
                      //     hozAlign: 'center',
                      //     vertAlign: 'middle',
                      //     print: false,
                      //     download: false
                      // },
                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })

              let promise = axios({
                  url: "api/job-done/available_packets_total",
                  method: "post",
                  data: filter_params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      console.log(data);
                      page_data.total = data.total
                  })
                  .catch(_error => {
                      page_data.total = []
                  });
          }

          const add_stock_to_list = () => {
              if (page_data.search != '') {
                  if (page_data.addlist.length <= 0) {
                      verify_stock()
                  } else {
                      for (let i = 0; i < page_data.packet_list.length; i++) {
                          if (page_data.packet_list[i].number == page_data.search || page_data.packet_list[i].barnum == page_data.search || page_data.packet_list[i].vepari_no == page_data.search) {
                              swal("Warning", "Scanned stock already in list", "warning")
                              page_data.search = ''
                              return;
                          } else {
                              page_data.addornot = false
                          }

                      }
                      if (page_data.addornot == false) {
                          verify_stock()
                      }
                  }
              }
          }

          const verify_stock = () => {
              const params = {
                  party_id: window.btoa(page_data.party_id),
                  number: window.btoa(page_data.search),
              }
              axios({
                  url: 'api/job-done/scan_job_done_packet',
                  method: 'post',
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {

                if(res.data.status == 'error') {

                  swal("Warning", res.data.message, "warning")
                } else {

                  const index = ref(page_data.addlist.length)
                  page_data.party_id = res.data.item.party_id
                  page_data.addlist[index.value] = res.data.item.number
                  page_data.packet_list[index.value] = res.data.item
                //   // page_data.search_type = res.data.packet.type
                  page_data.search = ''
                  focusMe.value.focus()
                }
                  // page_data.checklist[page_data.addlist[index.value].id] = true
              }).catch((err) => {
                swal("Warning", err.response.data.error, "warning")
                focusMe.value.focus()
                page_data.search = ''

              })
          }

          const removeItem = (id) => {

              page_data.addlist.splice(id, 1);
              page_data.packet_list.splice(id, 1);

              if (page_data.addlist.length <= 0) {
                  page_data.party_id = ''
              }
          }

          const getLotDetails = () => {
              axios({
                  url: 'api/master/dp_lot_details',
                  method: 'post',
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  page_data.kapan_details = res.data.items
              })
          }

          const getTransferCheck = () => {
              axios({
                  url: '/user/transferManager',
                  method: 'get',
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  page_data.managers = res.data.managers
              })
          }

          const AssignProcess = () => {
            swal({
                title: "Are you sure?",
                text: "You are You Want To Update Job Done!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let params = {
                      numbers: page_data.packet_list,
                      party_id: page_data.party_id,
                    }

                    let api_url = 'api/job-done/job_done_transfer';
                    let promise = axios({
                        url: api_url,
                        method: "post",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        },
                    });
                    return promise
                        .then((result) => result.data)
                        .then((data) => {
                            if (data.status == 'success') {
                                swal("Success!", data.message, "success");
                                initTabulator()
                                page_data.addlist = []
                                page_data.packet_list = []
                                page_data.party_id = ''
                            } else {

                                swal("Warning!", "Somthing Wants Wrong!", "warning");
                            }
                        })
                }
            });
          }

          const getEmployees = () => {
              const params = {
                  manager_id: window.btoa(user_details.value.account_id),
                  process_id: window.btoa(process.process_id),
                  process_type: window.btoa(page_data.search_type),
              }
              page_data.parties = []
              page_data.employees = []
              if (process.process_id != '0') {

                  axios({
                      url: 'api/master/get_employees_by_manager_process',
                      method: 'post',
                      baseURL: BASE_URL,
                      data: params,
                      headers: {
                          Authorization: 'Bearer ' + localStorage.getItem('token')
                      },
                      config: {
                          headers: {
                              'Accept': 'application/json'
                          }
                      }
                  }).then((res) => {
                      page_data.employees = res.data.items
                      process.employee_id = '0'
                  })
                  axios({
                      url: 'api/master/get_jober_details_by_process',
                      method: 'post',
                      baseURL: BASE_URL,
                      data: params,
                      headers: {
                          Authorization: 'Bearer ' + localStorage.getItem('token')
                      },
                      config: {
                          headers: {
                              'Accept': 'application/json'
                          }
                      }
                  }).then((res) => {
                      page_data.parties = res.data.items
                      process.party_id = '0'
                  })
              } else {
                  page_data.employees = []
                  page_data.parties = []
                  process.party_id = '0'
                  process.employee_id = '0'
              }
          }

          const getProcessData = () => {
              const params = {
                  user_id: user_details.value.account_id,
                  process_type: window.btoa(page_data.search_type)
              }
              axios({
                  url: 'api/master/manager_processes',
                  method: 'post',
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  page_data.processes = res.data.items
                  process.process_id = '0'
              })
          }

          const getAllManager = () => {
              axios({
                  url: 'api/master/dp_managers',
                  method: 'post',
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  page_data.managers = res.data.items
                  process.manager_id = '0'
              })
          }

          const getPartyDetails = () => {
              axios({
                  url: 'api/master/dp_parties',
                  method: 'post',
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  page_data.vendor_parties = res.data.items
                  filter_data.party_id = '0'
              })
          }

          const validateManagerId = () => {
              if (user_details.value.account_id == process.manager_id) {
                  process.manager_id = '0'
                  swal("Warning!", "Self Transfre is Not Allowed !", "warning");
              }
          }

          const getPacketDetailsScan = () => {
              if (!page_data.check && page_data.search) {
                  add_stock_to_list();
              }
          }

          const resetFilters = () => {
              filter_data.packet_type = '0'
              filter_data.kapan_id = '0'
              filter_data.party_id = '0'
              filter_data.stock_no = ''
              filter_data.for_p = false
              filter_data.semi_polish = false

              initTabulator()
          }

          onMounted(() => {
              initTabulator()
              getAllManager()
              getPartyDetails()
              getLotDetails()
              focusMe.value.focus()
          })

          return {
              page_data,
              filter_data,
              focusMe,
              user_details,
              process,
              getPacketDetailsScan,
              tableRef,
              add_stock_to_list,
              removeItem,
              getTransferCheck,
              getEmployees,
              getAllManager,
              getPartyDetails,
              getProcessData,
              AssignProcess,
              initTabulator,
              validateManagerId,
              resetFilters,
          }
      }
      // ,
      // mounted() {
      //   this.$refs.focusMe.focus()
      // }
  })
  </script>
