<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <a href="">Application</a>
      <ChevronRightIcon class="breadcrumb__icon" />
      <!-- <template v-for="(item,key) in masters" :key="key">
        <span href="" class="breadcrumb--active" v-if="this.$route.name == item.to.name">
          <a href="" class="breadcrumb--active" v-if="this.$route.name == item.to.name">Masters <ChevronRightIcon class="breadcrumb__icon" /></a>
          <a href="" class="breadcrumb--active" v-if="this.$route.name == item.to.name">{{ item.name}}</a>
      </span>
      </template>
      <template v-for="(item,key) in departments" :key="key">
        <span href="" class="breadcrumb--active" v-if="this.$route.name == item.to.name">
          <a href="" class="breadcrumb--active" v-if="this.$route.name == item.to.name">{{ item.name}}</a>
      </span>
      </template>
      <template v-for="(item,key) in users" :key="key">
        <span href="" class="breadcrumb--active" v-if="this.$route.name == item.to.name">
          <a href="" class="breadcrumb--active" v-if="this.$route.name == item.to.name">{{ item.name}}</a>
      </span>
      </template>
      <template v-for="(item,key) in Rates" :key="key">
        <span href="" class="breadcrumb--active" v-if="this.$route.name == item.to.name">
          <a href="" class="breadcrumb--active" v-if="this.$route.name == item.to.name">{{ item.name}}</a>
      </span>
      </template> -->
        <span  v-for="(item,key) in breadcrumbs" :key="key">
          <a href="#" class="breadcrumb--active" v-if="key != 0"><ChevronRightIcon class="breadcrumb__icon" /></a>
          <a href="#" class="breadcrumb--active">{{ item}}</a>
      </span>
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <!-- <div class="intro-x relative mr-3 sm:mr-6">
      <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input form-control border-transparent placeholder-theme-13"
          placeholder="Search..."
          @focus="showSearchDropdown"
          @blur="hideSearchDropdown"
        />
        <SearchIcon class="search__icon dark:text-gray-300" />
      </div>
      <a class="notification sm:hidden" href="">
        <SearchIcon class="notification__icon dark:text-gray-300" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">Pages</div>
          <div class="mb-5">
            <a href="" class="flex items-center">
              <div
                class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"
              >
                <InboxIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Mail Settings</div>
            </a>
            <a href="" class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"
              >
                <UsersIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Users & Permissions</div>
            </a>
            <a href="" class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"
              >
                <CreditCardIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Transactions Report</div>
            </a>
          </div>
          <div class="search-result__content__title">Users</div>
          <div class="mb-5">
            <a
              v-for="(faker, fakerKey) in $_.take($f(), 4)"
              :key="fakerKey"
              href
              class="flex items-center mt-2"
            >
              <div class="w-8 h-8 image-fit">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="rounded-full"
                  :src="require(`@/assets/images/${faker.photos[0]}`).default"
                />
              </div>
              <div class="ml-3">{{ faker.users[0].name }}</div>
              <div
                class="ml-auto w-48 truncate text-gray-600 text-xs text-right"
              >
                {{ faker.users[0].email }}
              </div>
            </a>
          </div>
          <div class="search-result__content__title">Products</div>
          <a
            v-for="(faker, fakerKey) in $_.take($f(), 4)"
            :key="fakerKey"
            href
            class="flex items-center mt-2"
          >
            <div class="w-8 h-8 image-fit">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                :src="require(`@/assets/images/${faker.images[0]}`).default"
              />
            </div>
            <div class="ml-3">{{ faker.products[0].name }}</div>
            <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">
              {{ faker.products[0].category }}
            </div>
          </a>
        </div>
      </div>
    </div> -->
    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <div class="p-4 px-0 border-theme-27 dark:border-dark-3 text-right">
          <div class="font-medium">{{ userDetails.first_name }} {{ userDetails.last_name }}</div>
          <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
            {{ userDetails.email }}
          </div>
        </div>
    <div class="intro-x dropdown mr-auto sm:mr-6">
      <!-- <div
        class="dropdown-toggle notification notification--bullet cursor-pointer"
        role="button"
        aria-expanded="false"
      >
        <BellIcon class="notification__icon dark:text-gray-300" />
      </div> -->
      <!-- <div class="notification-content pt-2 dropdown-menu">
        <div
          class="notification-content__box dropdown-menu__content box dark:bg-dark-6"
        >
          <div class="notification-content__title">Notifications</div>
          <div

            class="cursor-pointer relative flex items-center"

          >
            <p>No new notifications!</p>
          </div>
        </div>
      </div> -->
    </div>
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
        role="button"
        aria-expanded="false"
      >
        <img
          alt="Icewall Tailwind HTML Admin Template"
          :src="require(`@/assets/images/user.png`)"
        />
      </div>
      <div class="dropdown-menu w-56">
        <div
          class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white"
        >
          <div class="p-4 border-b border-theme-27 dark:border-dark-3">
            <div class="font-medium">{{ userDetails.first_name }} {{ userDetails.last_name }}</div>
            <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
              {{ userDetails.email }}
            </div>
          </div>
          <div class="p-2">
             <router-link tag='a'
            :to="{ name: 'change-password' }"

              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <UserIcon class="w-4 h-4 mr-2" /> Change Password
            </router-link>

          </div>
          <!-- <div class="p-2">
             <router-link tag='a'
            :to="{ name: 'update-profile' }"

              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <UserIcon class="w-4 h-4 mr-2" /> My Profile
            </router-link>

          </div> -->
          <div class="p-2 border-t border-theme-27 dark:border-dark-3">
            <span
              @click="logOut"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" /> Logout
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
/* eslint-disable */
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const searchDropdown = ref(false)

    const breadcrumbs = computed(() => {
      return route.meta;
    });
    const masters = [
      {
        name: 'Color Master',
        to: { name: 'color' }
      },
      {
        name: 'Cut Master',
        to: { name: 'cut' }
      },
      {
        name: 'Purity Master',
        to: { name: 'purity' }
      },
      {
        name: 'Polish Master',
        to: { name: 'polish' }
      },
      {
        name: 'Shape Master',
        to: { name: 'shape' }
      },
      {
        name: 'Stone Master',
        to: { name: 'stone' }
      },
      {
        name: 'Symmetry Master',
        to: { name: 'symmetry' }
      },
      {
        name: 'Fluorescence Master',
        to: { name: 'fluerocent' }
      },
      {
        name: 'Tension Master',
        to: { name: 'tension' }
      },
      {
        name: 'Lab. Certificate Master',
        to: { name: 'lab-certificate' }
      },
      {
        name: 'Ratio Master',
        to: { name: 'ratio' }
      },
      {
        name: 'Tabl Master',
        to: { name: 'tabl' }
      },
      {
        name: 'Height Master',
        to: { name: 'height' }
      },
      {
        name: 'Length Master',
        to: { name: 'length' }
      },
      {
        name: 'Width Master',
        to: { name: 'width' }
      },
      {
        name: 'Discount Master',
        to: { name: 'back' }
      },
      {
        name: 'Charni Master',
        to: { name: 'charni' }
      },
    ]
    const departments = [
      {
        name: 'Process',
        to: { name: 'process' },
        access: 205
      },
      {
        name: 'Department',
        to: { name: 'department' },
        access: 206
      },
    ]
    const users = [
      {
        name: 'Vendor',
        to: { name: 'vendor-details' },
        access: 201
      },
      {
        name: 'Manager',
        to: { name: 'manager-details' },
        access: 202
      },
      {
        name: 'Employee',
        to: { name: 'employee-details' },
        access: 203
      },
      {
        name: 'Job Work',
        to: { name: 'jober-details' },
        access: 204
      },
      {
        name: 'Planner',
        to: { name: 'planner-details' },
        access: 240
      },
    ]
    const Rates = [
      {
        name: 'Manual Calculate',
        to: { name: 'manual-rate-list' },
        access: 208
      },
      {
        name: 'Rapo Rates',
        to: { name: 'repo-rates' },
        access: 247
      },
      {
        name: 'Majuri Alert',
        to: { name: 'costing-rates' },
        access: 114
      },
      {
        name: 'Margin / Costing',
        to: { name: 'margin-costing-rates' },
        access: 114
      },
    ]

    const userDetails = computed(() => store.state.user.currentUser)

    const logOut = () => {
      console.log('hi');
      store.dispatch('signOut').then(() => {
        router.go("/user/login");
      });
    }

    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    onMounted(() => {
      console.log(breadcrumbs.value)
    })

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      userDetails,
      logOut,
      masters,
      users,
      departments,
      Rates,
      breadcrumbs
    }
  }
})
</script>
