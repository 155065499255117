<template>
  <div>
    <div class="intro-y flex items-center mt-2">
      <h2 class="text-lg font-medium mr-auto">Kapan Report</h2>
    </div>
    <div class="intro-y box p-5 mt-3" style="z-index: 50;">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-8 sm:col-span-6 md:col-span-6 xl:col-span-6">
          <div>
            <TomSelect v-model="page_data.kapan_id" :options="{
              placeholder: 'Select Kapan',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Kapan'
                }
              }
            }" multiple class="w-full">
              <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{ item.lot_no }} (
                {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">

          <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5"
            :disabled="page_data.r_loading == true" @click="getReportDetails()">
            Generate
          </button>
        </div>
      </div>

      <div class="mt-5">

        <div id="responsive-table" class="p-5" v-if="page_data.r_loading">
          <div class="preview">
            <div class="overflow-x-auto">
              <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                <LoadingIcon icon="bars" class="w-8 h-8" />
                <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div v-if="page_data.generate == 'gen'">


            <div class="intro-y box">
              <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base mr-auto">Kapan Planning Prediction</h2>
              </div>
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <div class="grid grid-cols-12 gap-x-5">
                  <div class="col-span-12 lg:col-span-5">

                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Kapan No. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label">
                        <span v-for="(item, key) in page_data.kapan_details" :key="key">

                          <span v-if="key != 0">, </span>{{ item.voucher_no }}
                        </span>
                      </label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Purchase No. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label">
                        <span v-for="(item, key) in page_data.kapan_details" :key="key">

                          <span v-if="key != 0">, </span>{{ item.lot_no }}
                        </span>
                      </label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Average Weight (Ct).
                        :&nbsp;</label><label for="update-profile-form-6" class="form-label"> <span
                          class="flex items-center">$ <input id="pos-form-1" v-model="page_data.conversation_rate"
                            name="conversation_rate" type="text" class="form-control flex-1 p-1 m-0 mx-2"
                            placeholder="Rate ($)" @input="updateInrRates" @keypress="$h.preventInvalidInput" /></span>
                      </label>
                    </div>

                  </div>
                  <div class="col-span-12 lg:col-span-5">

                    <div class="mt-2 cursor-pointer" @click="get_pending_packets">
                      <label for="update-profile-form-6" class="form-label font-bold">Prnding Rough Pieces. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label"> {{ page_data.pending_total_pieces }}</label>
                    </div>
                    <div class="mt-2 cursor-pointer" @click="get_pending_packets">
                      <label for="update-profile-form-6" class="form-label font-bold">Prnding Rough Weight (Ct).
                        :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{
                          (page_data.pending_total_weight ? $h.myFloat(page_data.pending_total_weight) : '0') }} Ct</label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Rough Pieces. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label"> {{ page_data.rough_total_pieces }}</label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Rough Weight (Ct).
                        :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{
                          (page_data.rough_total_weight ? $h.myFloat(page_data.rough_total_weight) : '0') }} Ct</label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Average Weight (Ct).
                        :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{
                          (page_data.rough_avg_weight ? $h.myFloat(page_data.rough_avg_weight) : '0') }} Ct</label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">RP Polish (%).
                        :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{
                          (page_data.plan_polish_per ? $h.myFloat(page_data.plan_polish_per) : '0') }} %</label>
                    </div>

                  </div>

                </div>
              </div>
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <div class="">
                  <div class="border">
                    <div class="overflow-x-auto">
                      <table class="w-full text-center text-xs">
                        <thead>
                          <tr  class="dark:bg-dark-1 bg-dark-1 text-white">
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">NO</th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">SIZE</th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">POINTER</th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PIECES</th>
                            <!-- <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Rough Weight</th> -->
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">POLISH WEIGHT(CT)
                            </th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">POLISH (%)</th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo($)</th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">MFG PER/CT($)
                            </th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMOUNT ($)
                            </th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo(INR)
                            </th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">MFG PER/CT(INR)
                              AVG</th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMOUNT (INR)
                            </th>
                          </tr>
                        </thead>
                        <tbody class="text-center" v-for="(field, inx) in page_data.shape_plans" :key="inx">
                          <tr class="bg-gray-200">
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap bg-gray-300 text-red" colspan="12">{{ field.shape_group }}</th>
                          </tr>
                          <tr class="hover:bg-gray-200 border-2 border-b-0" v-for="(sub_field, index) in field.size_plans" :key="index">
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ index + 1 }}</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap cursor-pointer" @click="get_purity_charni_list(sub_field,field.shape_group)">{{ sub_field.charni_name ? sub_field.charni_name : "-" }}</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap cursor-pointer" @click="get_purity_charni_list(sub_field,field.shape_group)">{{ sub_field.charni_code ? sub_field.charni_code : "-" }}</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.total_pieces ? sub_field.total_pieces : "-" }}</td>
                            <!-- <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.rough_weight ? $h.myFloat(sub_field.rough_weight) : "0" }} Ct</td> -->
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.polish_weight ? $h.myFloat(sub_field.polish_weight) : "0" }} Ct</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.plan_polish_per ? $h.myFloat(sub_field.plan_polish_per) : "0" }} %</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.avg_repo ? $h.myFloat(sub_field.avg_repo) : "0" }}</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.avg_value ? $h.myFloat(sub_field.avg_value) : "0" }}</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.total_value ? $h.myFloat(sub_field.total_value) : "0" }}</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.inr_avg_repo ? $h.myFloat(sub_field.inr_avg_repo) : "0" }}</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.inr_avg_value ? $h.myFloat(sub_field.inr_avg_value) : "0" }}</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.inr_total_value ? $h.myFloat(sub_field.inr_total_value) : "0" }}</td>
                          </tr>
                          <tr class="bg-gray-200">
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap" colspan="3">Total</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{ field.total_pieces ? field.total_pieces : "-" }}</th>
                            <!-- <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ field.rough_weight ? $h.myFloat(field.rough_weight) : "0" }} Ct</td> -->
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{ field.polish_weight ? $h.myFloat(field.polish_weight) : "0" }} Ct</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{ field.plan_polish_per ? $h.myFloat(field.plan_polish_per) : "0" }} %</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.avg_repo ? $h.myFloat(field.avg_repo) : "0" }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.avg_value ? $h.myFloat(field.avg_value) : "0" }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.total_value ? $h.myFloat(field.total_value) : "0" }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.inr_avg_repo ? $h.myFloat(field.inr_avg_repo) : "0" }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.inr_avg_value ? $h.myFloat(field.inr_avg_value) : "0" }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.inr_total_value ? $h.myFloat(field.inr_total_value) : "0" }}</th>
                          </tr>
                        </tbody>
                        <tr class="dark:bg-dark-1 bg-dark-1 text-white">
                            <th class="m-1 border-b border-r py-2 px-2 whitespace-nowrap" colspan="3">Final Total</th>
                            <th class="m-1 border-b border-r py-2 px-2 whitespace-nowrap">{{ page_data.shape_plans[0].total_pieces ? page_data.shape_plans[0].total_pieces : "-" }}</th>
                            <th class="m-1 border-b border-r py-2 px-2 whitespace-nowrap">{{ page_data.shape_plans[0].polish_weight ? $h.myFloat(page_data.shape_plans[0].polish_weight) : "0" }} Ct</th>
                            <th class="m-1 border-b border-r py-2 px-2 whitespace-nowrap">{{ page_data.shape_plans[0].plan_polish_per ? $h.myFloat(page_data.shape_plans[0].plan_polish_per) : "0" }} %</th>
                            <th class="m-1 border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.shape_plans[0].avg_repo ? $h.myFloat(page_data.shape_plans[0].avg_repo) : "0" }}</th>
                            <th class="m-1 border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.shape_plans[0].avg_value ? $h.myFloat(page_data.shape_plans[0].avg_value) : "0" }}</th>
                            <th class="m-1 border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.shape_plans[0].total_value ? $h.myFloat(page_data.shape_plans[0].total_value) : "0" }}</th>
                            <th class="m-1 border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.shape_plans[0].inr_avg_repo ? $h.myFloat(page_data.shape_plans[0].inr_avg_repo) : "0" }}</th>
                            <th class="m-1 border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.shape_plans[0].inr_avg_value ? $h.myFloat(page_data.shape_plans[0].inr_avg_value) : "0" }}</th>
                            <th class="m-1 border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.shape_plans[0].inr_total_value ? $h.myFloat(page_data.shape_plans[0].inr_total_value) : "0" }}</th>
                          </tr>
                      </table>
                      <table class="w-full text-center text-xs mt-5">
                        <thead>
                          <tr class="bg-gray-200">
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap bg-gray-300 text-red" colspan="10">0.050 To 0.094 CHARNI</th>
                          </tr>
                          <tr  class="dark:bg-dark-1 bg-dark-1 text-white">
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">POINTER</th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PIECES</th>
                            <!-- <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Rough Weight</th> -->
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">POLISH WEIGHT(CT)
                            </th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">POLISH (%)</th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo($)</th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">MFG PER/CT($)
                            </th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMOUNT ($)
                            </th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo(INR)
                            </th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">MFG PER/CT(INR)</th>
                            <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMOUNT (INR)
                            </th>
                          </tr>
                        </thead>
                          <tbody class="text-center" v-for="(field, inx) in page_data.manual_charni" :key="inx">
                          <tr class="bg-gray-200" :class="{'dark:bg-dark-1 bg-dark-1 text-white': inx == (page_data.manual_charni.length - 1)}">
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{ field.charni_name }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{ field.total_pieces ? field.total_pieces : "-" }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{ field.polish_weight ? $h.myFloat(field.polish_weight) : "0" }} Ct</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{ field.plan_polish_per ? $h.myFloat(field.plan_polish_per) : "0" }} %</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.avg_repo ? $h.myFloat(field.avg_repo) : "0" }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.avg_value ? $h.myFloat(field.avg_value) : "0" }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.total_value ? $h.myFloat(field.total_value) : "0" }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.inr_avg_repo ? $h.myFloat(field.inr_avg_repo) : "0" }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.inr_avg_value ? $h.myFloat(field.inr_avg_value) : "0" }}</th>
                            <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ field.inr_total_value ? $h.myFloat(field.inr_total_value) : "0" }}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>



            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- END: Data List -->
    <div id="purity-size-charni-planning-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header p-5">
                <div class="flex justify-between items-center w-full">
                  <h2 class="text-lg font-medium mr-auto">Purity Wise List ({{ page_data.size_arr.charni_name }})</h2>
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                </div>

              </div>
                <div class="modal-body">

                  <div class="border-b border-gray-200 dark:border-dark-5">
                    <div class="">
                      <div class="border">
                        <div class="overflow-x-auto">
                          <table class="w-full text-center text-xs">
                            <thead>
                              <tr  class="dark:bg-dark-1 bg-dark-1 text-white">
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PURITY</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PIECES</th>
                                <!-- <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Rough Weight</th> -->
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">POLISH WT(CT)
                                </th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">POLISH (%)</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo($) </th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">MFG PER/CT($)
                                </th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMOUNT ($)
                                </th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo (INR)
                                </th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">MFG PER/CT(INR)</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMOUNT (INR)
                                </th>
                              </tr>
                            </thead>
                            <tbody class="text-center">
                              <tr class="bg-gray-200">
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap bg-gray-300 text-red" colspan="10">{{ page_data.shape_group }} ({{ page_data.size_arr.charni_name }})</th>
                              </tr>
                              <tr class="hover:bg-gray-200 border-2 border-b-0" v-for="(sub_field, index) in page_data.purity_list" :key="index">
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap cursor-pointer" @click="get_plan_list(sub_field)">{{ sub_field.purity_name ? sub_field.purity_name : "-" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.total_pieces ? sub_field.total_pieces : "-" }}</td>
                                <!-- <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.rough_weight ? $h.myFloat(sub_field.rough_weight) : "0" }} Ct</td> -->
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.polish_weight ? $h.myFloat(sub_field.polish_weight) : "0" }} Ct</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.plan_polish_per ? $h.myFloat(sub_field.plan_polish_per) : "0" }} %</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.avg_repo ? $h.myFloat(sub_field.avg_repo) : "0" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.avg_value ? $h.myFloat(sub_field.avg_value) : "0" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.total_value ? $h.myFloat(sub_field.total_value) : "0" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.inr_avg_repo ? $h.myFloat(sub_field.inr_avg_repo) : "0" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.inr_avg_value ? $h.myFloat(sub_field.inr_avg_value) : "0" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.inr_total_value ? $h.myFloat(sub_field.inr_total_value) : "0" }}</td>
                              </tr>
                              <tr class="dark:bg-dark-1 bg-dark-1 text-white">
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap">Total</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{ page_data.size_arr.total_pieces ? page_data.size_arr.total_pieces : "-" }}</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{ page_data.size_arr.polish_weight ? $h.myFloat(page_data.size_arr.polish_weight) : "0" }} Ct</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{ page_data.size_arr.plan_polish_per ? $h.myFloat(page_data.size_arr.plan_polish_per) : "0" }} %</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.size_arr.avg_repo ? $h.myFloat(page_data.size_arr.avg_repo) : "0" }}</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.size_arr.avg_value ? $h.myFloat(page_data.size_arr.avg_value) : "0" }}</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.size_arr.total_value ? $h.myFloat(page_data.size_arr.total_value) : "0" }}</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.size_arr.inr_avg_repo ? $h.myFloat(page_data.size_arr.inr_avg_repo) : "0" }}</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.size_arr.inr_avg_value ? $h.myFloat(page_data.size_arr.inr_avg_value) : "0" }}</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.size_arr.inr_total_value ? $h.myFloat(page_data.size_arr.inr_total_value) : "0" }}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: DATA List -->
    <!-- END: Data List -->
    <div id="planning-purity-size-charni-planning-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header p-5">
                <div class="flex justify-between items-center w-full">
                  <h2 class="text-lg font-medium mr-auto">Purity Wise Plan List ({{ page_data.purity_arr.purity_name }})</h2>
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                </div>

              </div>
                <div class="modal-body">

                  <div class="border-b border-gray-200 dark:border-dark-5">
                    <div class="">
                      <div class="border">
                        <div class="overflow-x-auto">
                          <table class="w-full text-center text-xs">
                            <thead>
                              <tr  class="dark:bg-dark-1 bg-dark-1 text-white">
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PACKET NO</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">STOCK NO</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">POLISH WT(CT)</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">SHAPE</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">COLOR</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PURITY</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">REPO($) </th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">BACK(%) </th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">MFG PER/CT($)</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMOUNT ($)</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo (INR)</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">MFG PER/CT(INR)</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMOUNT (INR)</th>
                              </tr>
                            </thead>
                            <tbody class="text-center">
                              <tr class="bg-gray-200">
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap bg-gray-300 text-red" colspan="13">{{ page_data.shape_group }} ({{ page_data.size_arr.charni_name }})</th>
                              </tr>
                              <tr class="hover:bg-gray-200 border-2 border-b-0" v-for="(sub_field, index) in page_data.plan_list" :key="index">
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.packet_no ? sub_field.packet_no : "-" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.stock_no ? sub_field.stock_no : "-" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.polish_weight ? $h.myFloat(sub_field.polish_weight) : "0" }} Ct</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.shape_name ? sub_field.shape_name : "-" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.color_name ? sub_field.color_name : "-" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.purity_name ? sub_field.purity_name : "-" }}</td>
                                <!-- <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.rough_weight ? $h.myFloat(sub_field.rough_weight) : "0" }} Ct</td> -->
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.repo_price ? $h.myFloat(sub_field.repo_price) : "0" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.discount ? $h.myFloat(sub_field.discount) : "0" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.back_price ? $h.myFloat(sub_field.back_price) : "0" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.total_value ? $h.myFloat(sub_field.total_value) : "0" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.repo_price_inr ? $h.myFloat(sub_field.repo_price_inr) : "0" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.back_price_inr ? $h.myFloat(sub_field.back_price_inr) : "0" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ sub_field.total_value_inr ? $h.myFloat(sub_field.total_value_inr) : "0" }}</td>
                              </tr>
                              <tr class="dark:bg-dark-1 bg-dark-1 text-white">
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap" colspan="2">Total</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{ page_data.purity_arr.polish_weight ? $h.myFloat(page_data.purity_arr.polish_weight) : "0" }} Ct</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap"></th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap"></th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap"></th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.purity_arr.avg_repo ? $h.myFloat(page_data.purity_arr.avg_repo) : "0" }}</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap"></th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.purity_arr.avg_value ? $h.myFloat(page_data.purity_arr.avg_value) : "0" }}</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.purity_arr.total_value ? $h.myFloat(page_data.purity_arr.total_value) : "0" }}</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.purity_arr.inr_avg_repo ? $h.myFloat(page_data.purity_arr.inr_avg_repo) : "0" }}</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.purity_arr.inr_avg_value ? $h.myFloat(page_data.purity_arr.inr_avg_value) : "0" }}</th>
                                <th class="border-b border-r py-2 px-2 whitespace-nowrap text-right">{{ page_data.purity_arr.inr_total_value ? $h.myFloat(page_data.purity_arr.inr_total_value) : "0" }}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: DATA List -->
    <!-- END: Data List -->
    <div id="pending-packets-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header p-5">
                <div class="flex justify-between items-center w-full">
                  <h2 class="text-lg font-medium mr-auto">Pending Plan Stocks</h2>
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                </div>

              </div>
                <div class="modal-body">

                  <div class="border-b border-gray-200 dark:border-dark-5">
                    <div class="">
                      <div class="border">
                        <div class="overflow-x-auto">
                          <table class="w-full text-center text-xs">
                            <thead>
                              <tr  class="dark:bg-dark-1 bg-dark-1 text-white">
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PACKET NO</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">STOCK NO</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">CURRENT WT(CT)</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">POLISH WT(CT)</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">MANAGER</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PROCESS</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EMPLOYEE</th>
                                <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">ISSUE DATE</th>
                              </tr>
                            </thead>
                            <tbody class="text-center">
                              <tr class="hover:bg-gray-200 border-2 border-b-0" v-for="(sub_field, index) in page_data.pending_packets_arr" :key="index">
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.packet_no ? sub_field.packet_no : "-" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.stock_no ? sub_field.stock_no : "-" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.cr_weight ? sub_field.cr_weight : "-" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.polish_weight ? $h.myFloat(sub_field.polish_weight) : "0" }} Ct</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.manager_short_name ? sub_field.manager_short_name : "-" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.process_short_code ? sub_field.process_short_code : "-" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.employee_short_name ? sub_field.employee_short_name : "-" }}</td>
                                <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ sub_field.issue_date ? sub_field.issue_date : "-" }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: DATA List -->
  </div>
</template>

<script>
import { computed, defineComponent, inject, onMounted, reactive, ref, toRefs } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import { helper as $h } from '@/utils/helper'
import store from '@/store';

export default defineComponent({
  setup() {

    // const user_details = computed(() => store.state.user.currentUser)
    // const route = useRoute()
    const swal = inject('$swal')

    const page_data = reactive({
      "r_loading": false,
      "generate": "no",
      "kapan_id": '0',
      "lot_items": [],
      "kapan_details": [],
      "conversation_rate": 0,
      "rough_total_pieces": 0,
      "rough_total_weight": 0,
      "rough_avg_weight": 0,
      "pending_total_pieces": 0,
      "pending_total_weight": 0,
      "pending_avg_weight": 0,
      "plan_polish_weight": 0,
      "plan_rough_weight": 0,
      "plan_total_pieces": 0,
      "plan_polish_per": 0,
      "plan_18_up_polish_per": 0,
      "shape_group_arr": [],
      "shape_plans": [],
      "manual_charni_arr": [],
      "manual_charni": [],
      "purity_list": [],
      "plan_list": [],
      "pending_packets_arr": [],
      "size_id": null,
      "size_name": null,
      "size_arr": [],
      "purity_arr": [],
      "shape_group": null,

    })

    const getReportDetails = () => {

      const params = {
        kapan_id: page_data.kapan_id
      };

      page_data.r_loading = true

      let promise = axios({
        url: "api/report/rpp-index",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          page_data.r_loading = false

          if (data.status == "success") {

            page_data.kapan_details = data.data.kapan_details;
            page_data.conversation_rate = data.data.conversation_rate;
            page_data.rough_total_pieces = data.data.rough_total_pieces;
            page_data.rough_total_weight = data.data.rough_total_weight;
            page_data.rough_avg_weight = data.data.rough_avg_weight;
            page_data.pending_total_pieces = data.data.pending_total_pieces;
            page_data.pending_total_weight = data.data.pending_total_weight;
            page_data.pending_avg_weight = data.data.pending_avg_weight;
            page_data.plan_polish_weight = data.data.plan_polish_weight;
            page_data.plan_rough_weight = data.data.plan_rough_weight;
            page_data.plan_total_pieces = data.data.plan_total_pieces;
            page_data.plan_polish_per = data.data.plan_polish_per;
            page_data.plan_18_up_polish_per = data.data.plan_18_up_polish_per;
            page_data.shape_group_arr = data.data.shape_group_arr;
            page_data.shape_plans = data.data.shape_plans;
            page_data.manual_charni_arr = data.data.manual_charni_arr;
            page_data.manual_charni = data.data.manual_charni;

            page_data.generate = 'gen';
          } else {
            swal("Warning !", data.message, "warning")
          }


        })
        .catch(_error => {
          page_data.r_loading = false
          swal("Error !", "Something went wrong", "error")
        });

    }

    const getLotDetails = async () => {
      let params = {
      }
      let api_url = 'api/master/dp_lot_all_details'

      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.kapan_id = []
          page_data.lot_items = data.items
        })
        .catch(_error => {
          page_data.kapan_id = []
          page_data.lot_items = []
        });
    }

    const get_purity_charni_list = (items, shape) => {
      const params = {
        kapan_id: page_data.kapan_id,
        conversation_rate: window.btoa(page_data.conversation_rate),
        size_id: window.btoa(items.id),
        shape_group: window.btoa(shape),
        plan_polish_weight: window.btoa(page_data.plan_polish_weight)
      };

      let promise = axios({
        url: "api/report/rpp-pwbs",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.purity_list = data.items;
          page_data.size_id = items.id;
          page_data.size_name = items.size_name;
          page_data.shape_group = shape;
          page_data.size_arr = items;
          cash('#purity-size-charni-planning-modal').modal('show')

        })
        .catch(_error => {
        });
    }
    const get_plan_list = (items) => {
      const params = {
        kapan_id: page_data.kapan_id,
        conversation_rate: window.btoa(page_data.conversation_rate),
        size_id: window.btoa(page_data.size_id),
        purity_id: window.btoa(items.purity),
        shape_group: window.btoa(page_data.shape_group),
      };

      let promise = axios({
        url: "api/report/rpp-lwpwbs",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.plan_list = data.items;
          page_data.purity_arr = items;
          cash('#planning-purity-size-charni-planning-modal').modal('show')

        })
        .catch(_error => {
        });
    }
    const get_pending_packets = () => {
      const params = {
        kapan_id: page_data.kapan_id
      };

      let promise = axios({
        url: "api/report/rpp-fppp",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
      .then(result => result.data)
      .then(data => {
        page_data.pending_packets_arr = data.items;
        cash('#pending-packets-modal').modal('show')

        })
        .catch(_error => {
          page_data.pending_packets_arr = [];
        });
    }

    const updateInrRates = () => {

      if(page_data.conversation_rate > 0) {

        page_data.conversation_rate = parseFloat(page_data.conversation_rate)
      } else {
        page_data.conversation_rate = 0
      }

      page_data.shape_plans.forEach(element => {

        element.size_plans.forEach(sub_element => {
          sub_element.inr_avg_repo = parseFloat(sub_element.avg_repo * page_data.conversation_rate).toFixed(0)
          sub_element.inr_avg_value = parseFloat(sub_element.avg_value * page_data.conversation_rate).toFixed(0)
          sub_element.inr_total_value = parseFloat(sub_element.total_value * page_data.conversation_rate).toFixed(0)
        });

        element.inr_avg_repo = parseFloat(element.avg_repo * page_data.conversation_rate).toFixed(0)
        element.inr_avg_value = parseFloat(element.avg_value * page_data.conversation_rate).toFixed(0)
        element.inr_total_value = parseFloat(element.total_value * page_data.conversation_rate).toFixed(0)
      });
      page_data.manual_charni.forEach(element => {

        element.inr_avg_repo = parseFloat(element.avg_repo * page_data.conversation_rate).toFixed(0)
        element.inr_avg_value = parseFloat(element.avg_value * page_data.conversation_rate).toFixed(0)
        element.inr_total_value = parseFloat(element.total_value * page_data.conversation_rate).toFixed(0)
      });

      // Update Rates
    }

    onMounted(() => {
      getLotDetails()
    })
    return {
      page_data,
      getReportDetails,
      updateInrRates,
      get_purity_charni_list,
      get_plan_list,
      get_pending_packets
    }
  }

})
</script>
