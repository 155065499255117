<template>
  <div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-2">

          <div class="grid grid-cols-12 gap-x-2">
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Packet / Lot</label>
                      <input id="tabulator-html-filter-value" v-model="filter.packet_no" type="text" class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0" placeholder="Search Packet / Kapan" />
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Kapan</label>
                      <TomSelect v-model="filter.kapan_id" :options="{
                    placeholder: 'Select Kapan',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Kapan</option>
                          <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-0"
                >Select Party</label
              >
              <TomSelect
                v-model="filter.party_id"
                :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Party</option>
              <option v-for="party in page_data.parties" :key="party.id" :value="party.id">{{party.first_name}} {{ party.last_name }}</option>
              </TomSelect>
            </div>
        </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select Department</label
                    >
                    <TomSelect
                      v-model="filter.department_id"
                      :options="{
                        placeholder: 'Select Department',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Department'
                          }
                        }
                      }"
                      class="w-full"
                    >
                    <option value="0">Select Department</option>
                    <option v-for="department in page_data.departments" :key="department.id" :value="department.id">{{department.department_name}} ( {{ department.department_short_code ? department.department_short_code : '-' }} )</option>
                    </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Process</label>
                      <TomSelect v-model="filter.process_id" @change="getProcessChange" :options="{
                    placeholder: 'Select Process',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Process</option>
                          <option value="stock">In Stock</option>
                          <option value="in_process">In Process</option>
                          <option v-for="item in page_data.process_items" :key="item.id" :value="item.id">{{item.process_name}} ( {{ item.process_short_code ? item.process_short_code : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_role == 1">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Manager</label>
                      <TomSelect v-model="filter.manager_id" @change="getEmployeeDetails" :options="{
                    placeholder: 'Select Manager',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Manager'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Manager</option>
                          <option v-for="item in page_data.manager_items" :key="item.id" :value="item.id">{{item.first_name}} {{item.last_name}} ( {{ item.short_code ? item.short_code : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Karigar Type</label>
                      <TomSelect v-model="filter.employee_type" @change="getEmployeeDetails" :options="{
                    placeholder: 'Select Karigar Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Karigar Type'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Karigar Type</option>
                          <option value="1">Employee</option>
                          <option value="2">Planner / Checker</option>
                          <option value="3">Job Party</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="filter.employee_type != '0'">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Employee</label>
                      <TomSelect v-model="filter.employee_id" :options="{
                    placeholder: 'Select Employee',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Employee'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Employee</option>
                          <option v-for="item in page_data.employee_items" :key="item.id" :value="item.id">{{item.first_name}} {{item.last_name}} ( {{ item.process_short_code ? item.short_code : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Type</label>
                      <TomSelect v-model="filter.packet_type" :options="{
                    placeholder: 'Select Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Type'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Type</option>
                          <option value="makeable">Makeable</option>
                          <option value="rough">Rough</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Stock Type</label>
                      <TomSelect v-model="filter.stock_type" :options="{
                    placeholder: 'Stock Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Stock Type'
                      }
                    }
                  }" class="w-full">
                          <option value="0">All</option>
                          <option value="1">Home Party</option>
                          <option value="2">Job Work</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Stock Status</label>
                      <TomSelect v-model="filter.stock_status" :options="{
                    placeholder: 'Stock Status',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Stock Status'
                      }
                    }
                  }" class="w-full">
                          <option value="0">All</option>
                          <option value="1">Internal</option>
                          <option value="2">External</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
              <div>
                <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-0"
                  >Select Start Date</label
                ><input
                  class="show-code form-check-switch mr-0 ml-3 h-5"
                  type="checkbox" v-model="filter.issue_pending"
                /></div>
                <input
                  type= "date"
                  id="update-profile-form-13"
                  :disabled="filter.issue_pending"
                  class="form-control"
                  placeholder=""
                  v-model="filter.issue_date"
                />
              </div>

          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-0"
                >Select End Date</label
              >
              <input
                type= "date"
                id="update-profile-form-13"

                class="form-control"
                placeholder=""
                :disabled="filter.issue_pending"
                v-model="filter.receive_date"
              />
            </div>
          </div>
          <div class="col-span-2 md:col-span-2 xl:col-span-1">
              <div class="mt-7 flex">

                  <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                      Reset
                  </button>
              </div>
          </div>
          <div class="col-span-2 md:col-span-2 xl:col-span-1">
              <div class="mt-7 flex">

                  <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="getReportDetails()">
                      Generate
                  </button>
              </div>
          </div>
          <div class="col-span-2 md:col-span-2 xl:col-span-1">
              <div class="mt-7 flex">

                  <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="exportReportDetails">
                      Export
                  </button>
              </div>
          </div>
        </div>

          <div>
              <!-- BEgin: Process Report Start-->
              <div id="responsive-table" class="p-5" v-if="page_data.loading">
                  <div class="preview">
                      <div class="overflow-x-auto">
                          <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                              <LoadingIcon icon="bars" class="w-8 h-8" />
                              <div class="text-center text-xs mt-2">Getting Latest Price</div>
                          </div>
                      </div>
                  </div>
              </div>

              <div v-else>
                <div class="scrollbar-hidden">
                  <div class="intro-y col-span-12 overflow-auto tableFixHead">
                    <table class="w-full mt-3 border-2">
                      <thead>
                        <tr class="border-b bg-gray-300 dark:bg-dark-1">
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Packet</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Stock No</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Proc</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Type</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Dept</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Mgr</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Emp</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">R Wt.</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Iss Wt.</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">PL Wt.</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Shape.</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Color.</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Purity.</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Cut.</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Polish.</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap">Symmetry.</th>
                          <th class="py-2 text-xs lg:text-md">Iss Date</th>
                          <th class="py-2 text-xs lg:text-md">Status</th>
                        </tr>
                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.rough_weight ? $h.myFloat(page_data.total.rough_weight) + ' Ct' : '-' }}</td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.polish_weight ? $h.myFloat(page_data.total.polish_weight) + ' Ct' : '-' }}</td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                        </tr>
                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.packet_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.stock_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.process_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.type_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.department_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.manager_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.employee_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.rough_weight" class="form-control p-1 m-0 rounded-none" @keypress="$h.preventInvalidInput" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.cr_weight" class="form-control p-1 m-0 rounded-none" @keypress="$h.preventInvalidInput" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.polish_weight" class="form-control p-1 m-0 rounded-none" @keypress="$h.preventInvalidInput" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.shape_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.color_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.purity_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.cut_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.polish_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="searchItems" v-model="filter.symmetry_str" class="form-control p-1 m-0 rounded-none" /></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-0 border-b text-xs lg:text-md text-center border-r"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(single_item,ind) in page_data.main_tbl" :key="ind" class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (ind % 2) == 0}">
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ ind + 1 }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.number }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.vepari_no }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.process_short_code ? single_item.process_short_code : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.packet_type ? single_item.packet_type : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.department_short_code ? single_item.department_short_code : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.manager_short_name ? single_item.manager_short_name : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.employee_short_name ? single_item.employee_short_name : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.weight ? $h.myFloat(single_item.weight) + ' Ct' : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) + ' Ct' : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.cr_polish_weight ? $h.myFloat(single_item.cr_polish_weight) + ' Ct' : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.shape ? single_item.shape : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.color ? single_item.color : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.purity ? single_item.purity : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.cut ? single_item.cut : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.polish ? single_item.polish : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.symmetry ? single_item.symmetry : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap">{{ single_item.issue_date ? single_item.issue_date : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r whitespace-nowrap"><span v-if="single_item.transaction_status == 1" class="text-theme-11">Hold</span>
                                        <span v-if="single_item.transaction_status == 2" class="text-theme-1">Active</span></td>
                        </tr>
                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.rough_weight ? $h.myFloat(page_data.total.rough_weight) + ' Ct' : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.polish_weight ? $h.myFloat(page_data.total.polish_weight) + ' Ct' : '-' }}</td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                        </tr>
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
          </div>
      </div>
      <!-- END: HTML Table Data -->

  </div>
  </template>

  <script setup>
  /* eslint-disable */
  import {
      BASE_URL
  } from '@/constants/config';
  import axios from 'axios';
  import moment from 'moment';
  import {
      defineComponent,
      onMounted,
      reactive,
      toRef,
      watch,
      computed
  } from 'vue';
  import store from '@/store'
  import { helper as $h } from '@/utils/helper'
  // import MultipleSelect from '@/components/MultipleSelect/Main.vue'

  // export default defineComponent({
  //   components:{
  //       // MultipleSelect
  //     },
  //     setup() {
    const emit = defineEmits(['export'])
          const user_details = computed(() => store.state.user.currentUser)
          const page_data = reactive({
              total: [],
              items: [],
              main_tbl: [],
              manager_items: [],
              process_items: [],
              departments: [],
              parties: [],
              lot_items: [],
              employee_items: [],
              loading: false,
              parameters: {
                color: [],
                cut: [],
                purity: [],
                polish: [],
                shape: [],
                symmetry: [],
                fluerocent: [],
                tension: [],
                stone: []
              }
          })

          const filter = reactive({
            report_type: "1",
            packet_no: "",
            kapan_id: '0',
            manager_id: '0',
            department_id: '0',
            process_id: '0',
            employee_id: '0',
            employee_type: '0',
            stock_type: '0',
            packet_type: '0',
            stock_status: '0',
            issue_pending : true,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD'),
            color: [],
            cut: [],
            purity: [],
            polish: [],
            shape: [],
            symmetry: [],
            fluerocent: [],
            tension: [],
            stone: [],
            process_done : '0',
            party_id : '0',
            days : '',
            late : '',
            day_order : true,
            late_order : false,

            rough_weight: "",
            polish_weight: "",
            cr_weight: "",
            packet_str: "",
            stock_str: "",
            color_str: "",
            cut_str: "",
            purity_str: "",
            polish_str: "",
            shape_str: "",
            symmetry_str: "",
            fluerocent_str: "",
            tension_str: "",
            stone_str: "",
            process_str: "",
            type_str: "",
            department_str: "",
            manager_str: "",
            employee_str: "",
          })

          const api_filter = reactive({
            report_type: "1",
            packet_no: "",
            kapan_id: '0',
            manager_id: '0',
            department_id: '0',
            process_id: '0',
            employee_id: '0',
            employee_type: '0',
            stock_type: '0',
            packet_type: '0',
            stock_status: '0',
            issue_pending : true,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD'),
            color: [],
            cut: [],
            purity: [],
            polish: [],
            shape: [],
            symmetry: [],
            fluerocent: [],
            tension: [],
            stone: [],
            process_done : '0',
            party_id : '0',
            days : '',
            late : '',
            day_order : true,
            late_order : false,
          })

          const load_details = () => {

          }

          const getReportDetails = async () => {

              api_filter.report_type = filter.report_type
              api_filter.packet_no = filter.packet_no
              api_filter.kapan_id = filter.kapan_id
              api_filter.manager_id = filter.manager_id
              api_filter.department_id = filter.department_id
              api_filter.process_id = filter.process_id
              api_filter.employee_id = filter.employee_id
              api_filter.employee_type = filter.employee_type
              api_filter.stock_type = filter.stock_type
              api_filter.packet_type = filter.packet_type
              api_filter.issue_pending = filter.issue_pending
              api_filter.issue_date = filter.issue_date
              api_filter.stock_status = filter.stock_status
              api_filter.receive_date = filter.receive_date
              api_filter.color = filter.color
              api_filter.cut = filter.cut
              api_filter.purity = filter.purity
              api_filter.polish = filter.polish
              api_filter.shape = filter.shape
              api_filter.symmetry = filter.symmetry
              api_filter.fluerocent = filter.fluerocent
              api_filter.tension = filter.tension
              api_filter.stone = filter.stone
              api_filter.process_done = filter.process_done
              api_filter.days = filter.days
              api_filter.late = filter.late
              api_filter.day_order = filter.day_order
              api_filter.late_order = filter.late_order
              api_filter.party_id = filter.party_id

              page_data.main_tbl = []
              page_data.items = []

              const params = {
                report_control: window.btoa("lot"),
                report_type: window.btoa(api_filter.report_type),
                stock_status: api_filter.stock_status == '0' ? '' : window.btoa(api_filter.stock_status),
                party_id: api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id),
                packet_no: window.btoa(api_filter.packet_no),
                process_id: api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id),
                kapan_id: api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
                department_id: api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id),
                manager_id: api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id),
                employee_id: api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id),
                employee_type: api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type),
                stock_type: api_filter.stock_type == '0' ? '' : window.btoa(api_filter.stock_type),
                packet_type: api_filter.packet_type == '0' ? '' : window.btoa(api_filter.packet_type),
                issue_pending : api_filter.issue_pending,
                issue_date : api_filter.issue_date,
                receive_date : api_filter.receive_date,
                color : api_filter.color,
                cut : api_filter.cut,
                purity : api_filter.purity,
                polish : api_filter.polish,
                shape : api_filter.shape,
                symmetry : api_filter.symmetry,
                fluerocent : api_filter.fluerocent,
                tension : api_filter.tension,
                stone : api_filter.stone,
                days : api_filter.days,
                late : api_filter.late,
                day_order : api_filter.day_order,
                late_order : api_filter.late_order,
                process_done : api_filter.process_done,
              }
              page_data.loading = true

              let promise = axios({
                  url: 'api/report/ssr-ssr',
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      page_data.main_tbl = data.items
                      page_data.items = data.items
                      // page_data.items.splice(0, page_data.main_tbl.length, ...page_data.main_tbl);

                      page_data.total = data.total
                      page_data.loading = false
                  })
                  .catch(_error => {
                      page_data.items = []
                      page_data.total = []
                      page_data.loading = false
                  });
          }

          const exportReportDetails = async () => {


            const params = {
                report_control: window.btoa("lot"),
                report_type: window.btoa(api_filter.report_type),
                stock_status: api_filter.stock_status == '0' ? '' : window.btoa(api_filter.stock_status),
                party_id: api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id),
                packet_no: window.btoa(api_filter.packet_no),
                process_id: api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id),
                kapan_id: api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
                department_id: api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id),
                manager_id: api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id),
                employee_id: api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id),
                employee_type: api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type),
                stock_type: api_filter.stock_type == '0' ? '' : window.btoa(api_filter.stock_type),
                packet_type: api_filter.packet_type == '0' ? '' : window.btoa(api_filter.packet_type),
                issue_pending : api_filter.issue_pending,
                issue_date : api_filter.issue_date,
                receive_date : api_filter.receive_date,
                color : api_filter.color,
                cut : api_filter.cut,
                purity : api_filter.purity,
                polish : api_filter.polish,
                shape : api_filter.shape,
                symmetry : api_filter.symmetry,
                fluerocent : api_filter.fluerocent,
                tension : api_filter.tension,
                stone : api_filter.stone,
                days : api_filter.days,
                late : api_filter.late,
                day_order : api_filter.day_order,
                late_order : api_filter.late_order,
                process_done : api_filter.process_done,
              }

            emit('export', params)
          }

          const getManagerDetails = async () => {
              let params = {
                  process_id: filter.process_id == '0' ? '' : window.btoa(filter.process_id)
              }
              let api_url = ''

              if (filter.process_id == '0') {

                  api_url = 'api/master/dp_managers'
              } else {
                  api_url = 'api/master/get_manager_by_department'
              }
              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.manager_id = '0'
                      page_data.manager_items = data.items
                  })
                  .catch(_error => {
                      filter.manager_id = '0'
                      page_data.manager_items = []
                  });
          }

          const getProcessDetails = async () => {
              let params = {
                  id: window.btoa(filter.manager_id)
              }
              let api_url = ''

              if (filter.manager_id == '0') {

                  api_url = 'api/master/dp_process'
              } else {
                  api_url = 'api/master/dp_process'
              }
              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.process_id = '0'
                      page_data.process_items = data.items
                  })
                  .catch(_error => {
                      filter.process_id = '0'
                      page_data.process_items = []
                  });
          }

          const getLotDetails = async () => {
              let params = {
                  id: window.btoa(filter.manager_id)
              }
              let api_url = 'api/master/dp_lot_all_details'

              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.kapan_id = '0'
                      page_data.lot_items = data.items
                  })
                  .catch(_error => {
                      filter.kapan_id = '0'
                      page_data.lot_items = []
                  });
          }

          const getEmployeeDetails = async () => {

              let params = {
                  manager_id: user_details.value.user_role == 1 ? (filter.manager_id == '0' ? '' : window.btoa(filter.manager_id)) : user_details.value.account_id,
                  process_id: filter.process_id == '0' ? '' : window.btoa(filter.process_id),
                  employee_type: filter.employee_type == '0' ? '' : window.btoa(filter.employee_type),
              }
              let api_url = ''

              if (filter.department_id == '0') {

                  api_url = 'api/master/get_employees_by_manager_employee_type'
              } else {
                  api_url = 'api/master/get_employees_by_manager_employee_type'
              }
              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.employee_id = '0'
                      page_data.employee_items = data.items
                  })
                  .catch(_error => {
                      filter.employee_id = '0'
                      page_data.employee_items = []
                  });
          }

          const getDepartmentDetails = () => {
            let params = {
              // department_id : window.btoa(filter.department_id)
            }
            let api_url = 'api/master/dp_department'
            let promise = axios({
              url: api_url,
              method: "post",
              data: params,
              baseURL: BASE_URL,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: { headers: { Accept: "application/json" } }
            });
            return promise
              .then(result => result.data)
              .then(data => {
                page_data.departments = data.items
              })
              .catch(_error => {
                page_data.departments = []
              });
          }

          function onResetFilter() {
            filter.report_type = "1";
            filter.packet_no = "";
            filter.kapan_id = '0';
            filter.manager_id = '0';
            filter.process_id = '0';
            filter.employee_id = '0';
            filter.department_id = '0';
            filter.employee_type = '0';
            filter.stock_type = '0';
            filter.packet_type = '0';
            filter.stock_status = '0';
            filter.issue_pending = true;
            filter.issue_date = moment().startOf('month').format('YYYY-MM-DD');
            filter.receive_date = moment().format('YYYY-MM-DD');
            filter.color = []
            filter.cut = []
            filter.purity = []
            filter.polish = []
            filter.shape = []
            filter.symmetry = []
            filter.fluerocent = []
            filter.tension = []
            filter.stone = []
            filter.process_done = '0'
            filter.days = ''
            filter.late = ''

            filter.rough_weight = ""
            filter.polish_weight = ""
            filter.cr_weight = ""
            filter.packet_str = ""
            filter.stock_str = ""
            filter.color_str = ""
            filter.cut_str = ""
            filter.purity_str = ""
            filter.polish_str = ""
            filter.shape_str = ""
            filter.symmetry_str = ""
            filter.fluerocent_str = ""
            filter.tension_str = ""
            filter.stone_str = ""
            filter.process_str = ""
            filter.type_str = ""
            filter.department_str = ""
            filter.manager_str = ""
            filter.employee_str = ""
          }

          const searchItems = () => {

                page_data.main_tbl.splice(0, page_data.main_tbl.length, ...page_data.items);
                let results = [];
                for (let j = 0; j < page_data.items.length; j++) {

                  // setTimeout(() => {
                    // if (searchingFunc(page_data.items[j], (search.value.toLowerCase()))) {
                    //   results.push(page_data.items[j]);
                    //   }
                    if(

                      ((page_data.items[j].number.toLowerCase()).includes((filter.packet_str).toLowerCase())) &&
                      ((page_data.items[j].vepari_no.toLowerCase()).includes((filter.stock_str).toLowerCase())) &&
                      (page_data.items[j].process_short_code != null ? (page_data.items[j].process_short_code.toLowerCase()).includes((filter.process_str).toLowerCase()) : ("-").includes((filter.process_str).toLowerCase())) &&
                      (page_data.items[j].packet_type != null ? (page_data.items[j].packet_type.toLowerCase()).includes((filter.type_str).toLowerCase()) : ("-").includes((filter.type_str).toLowerCase())) &&
                      (page_data.items[j].department_short_code != null ? (page_data.items[j].department_short_code.toLowerCase()).includes((filter.department_str).toLowerCase()) : ("-").includes((filter.department_str).toLowerCase())) &&
                      (page_data.items[j].manager_short_name != null ? (page_data.items[j].manager_short_name.toLowerCase()).includes((filter.manager_str).toLowerCase()) : ("-").includes((filter.manager_str).toLowerCase())) &&
                      (page_data.items[j].employee_short_name != null ? (page_data.items[j].employee_short_name.toLowerCase()).includes((filter.employee_str).toLowerCase()) : ("-").includes((filter.employee_str).toLowerCase())) &&
                      ((page_data.items[j].weight).includes(filter.rough_weight)) &&
                      ((page_data.items[j].issue_weight).includes(filter.cr_weight)) &&
                      (page_data.items[j].cr_polish_weight != null ? (page_data.items[j].cr_polish_weight).includes(filter.polish_weight) : ("-").includes(filter.polish_weight)) &&
                      (page_data.items[j].shape != null ? (page_data.items[j].shape.toLowerCase()).includes((filter.shape_str).toLowerCase()) : ("-").includes((filter.shape_str).toLowerCase())) &&
                      (page_data.items[j].color != null ? (page_data.items[j].color.toLowerCase()).includes((filter.color_str).toLowerCase()) : ("-").includes((filter.color_str).toLowerCase())) &&
                      (page_data.items[j].purity != null ? (page_data.items[j].purity.toLowerCase()).includes((filter.purity_str).toLowerCase()) : ("-").includes((filter.purity_str).toLowerCase())) &&
                      (page_data.items[j].cut != null ? (page_data.items[j].cut.toLowerCase()).includes((filter.cut_str).toLowerCase()) : ("-").includes((filter.cut_str).toLowerCase())) &&
                      (page_data.items[j].symmetry != null ? (page_data.items[j].symmetry.toLowerCase()).includes((filter.symmetry_str).toLowerCase()) : ("-").includes((filter.symmetry_str).toLowerCase())) &&
                      (page_data.items[j].polish != null ? (page_data.items[j].polish.toLowerCase()).includes((filter.polish_str).toLowerCase()) : ("-").includes((filter.polish_str).toLowerCase()))
                    ) {


                      results.push(page_data.items[j]);
                    }
                  // }, 50);
                }
                page_data.main_tbl.splice(0, page_data.main_tbl.length, ...results);
            };

            // const searchingFunc = (obj: any, value: string): boolean => {
            //     for (let key in obj) {
            //       if (obj['rapnet_stock_no'].toLowerCase().includes(value) || obj['certi_report_no'].toLowerCase().includes(value)) {

            //         if (value_2.length > 0) {
            //             if (value_2.includes('fancy') && value_2.includes('round')) {
            //                 return true;

            //             } else if (value_2.includes('round')) {
            //                 if (obj['shape'] == 1) {
            //                     return true;
            //                 }

            //             } else if (value_2.includes('fancy')) {
            //                 if (obj['shape'] != 1) {
            //                     return true;
            //                 }
            //             }
            //         } else {
            //             return true;
            //         }
            //       }
            //         if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
            //             if (obj[key].toLowerCase().includes(value)) {
            //                 return true;
            //             }
            //         }
            //     }
            //     return false;
            // };

          const getPartyDetails = () => {

            let params = {
              // department_id : window.btoa(filter.department_id)
            }
            let api_url = 'api/master/dp_parties'
            let promise = axios({
              url: api_url,
              method: "post",
              data: params,
              baseURL: BASE_URL,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: { headers: { Accept: "application/json" } }
            });
            return promise
              .then(result => result.data)
              .then(data => {
                page_data.parties = data.items
              })
              .catch(_error => {
                page_data.parties = []
              });
            }

          function getDiamontData() {
            axios({
              url: 'api/master/get_all_parameters',
              method: 'post',
              baseURL: BASE_URL,
              data: filter,
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              page_data.parameters.color = res.data.color
              page_data.parameters.cut = res.data.cut
              page_data.parameters.purity = res.data.purity
              page_data.parameters.symmetry = res.data.symmetry
              page_data.parameters.polish = res.data.polish
              page_data.parameters.fluerocent = res.data.fluerocent
              page_data.parameters.tension = res.data.tension
              page_data.parameters.shape = res.data.shape
              page_data.parameters.stone = res.data.stone
            })
          }

          function getProcessChange() {
              if (user_details.value.user_role == 1) {
                  getManagerDetails()
              }
              getEmployeeDetails()
          }

          function onColorCheck(val) {
            filter.color = val;
            getReportDetails()
          }
          function onPurityCheck(val) {
            filter.purity = val;
            getReportDetails()
          }
          function onCutCheck(val) {
            filter.cut = val;
            getReportDetails()
          }
          function onPolishCheck(val) {
            filter.polish = val;
            getReportDetails()
          }
          function onShapeCheck(val) {
            filter.shape = val;
            getReportDetails()
          }
          function onSymmetryCheck(val) {
            filter.symmetry = val;
            getReportDetails()
          }
          function onStoneCheck(val) {
            filter.stone = val;
            getReportDetails()
          }
          function onFluerocentCheck(val) {
            filter.fluerocent = val;
            getReportDetails()
          }
          function onTensionCheck(val) {
            filter.tension = val;
            getReportDetails()
          }

          const setProcessItems = (items) => {
      filter.process_id = '0'
      page_data.process_items = items
    }
    const setKapanItems = (items) => {
      filter.kapan_id = '0'
      page_data.lot_items = items
    }
    const setPartyItems = (items) => {
      filter.party_id = '0'
      page_data.parties = items
    }
    const setManagerItems = (items) => {
      filter.manager_id = '0'
          page_data.manager_items = items
    }
    const setDepartmentItems = (items) => {
          page_data.departments = items
    }
    const setParamsItems = (items) => {

      page_data.parameters.color = items.color
      page_data.parameters.cut = items.cut
      page_data.parameters.purity = items.purity
      page_data.parameters.symmetry = items.symmetry
      page_data.parameters.polish = items.polish
      page_data.parameters.fluerocent = items.fluerocent
      page_data.parameters.tension = items.tension
      page_data.parameters.shape = items.shape
      page_data.parameters.stone = items.stone
    }
    const setLotItems = (items) => {
          page_data.lot_items = items
    }
    defineExpose({
            setProcessItems,
            setKapanItems,
            setPartyItems,
            setManagerItems,
            setDepartmentItems,
            setParamsItems,
            setLotItems
          })
          onMounted(() => {
            // filter.manager_id = "1"
            //   getReportDetails()
          })
          // return {
          //     page_data,
          //     filter,
          //     api_filter,
          //     user_details,
          //     getReportDetails,
          //     getProcessDetails,
          //     getManagerDetails,
          //     onResetFilter,
          //     getEmployeeDetails,
          //     getProcessChange,
          //     onColorCheck,
          //     onPurityCheck,
          //     onCutCheck,
          //     onPolishCheck,
          //     onShapeCheck,
          //     onSymmetryCheck,
          //     onFluerocentCheck,
          //     onStoneCheck,
          //     onTensionCheck,
          //     searchItems,
          //     defineExpose
          // }
  //     }
  // })
  </script>
  <style scoped>
  .tableFixHead {
          overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
          max-height: 650px; /* gives an initial height of 200px to the table */
        }
        .tableFixHead thead {
          position: sticky; /* make the table heads sticky */
          top: 0px; /* table head will be placed from the top of the table and sticks to it */
        }
        </style>
