<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Search Stocks</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col">
        <div class="grid grid-cols-12 gap-x-2">
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-1">Packet / Kapan</label>
              <input id="tabulator-html-filter-value" v-model="filter_data.kapan_no" type="text"
                class="form-control" placeholder="Packet / Kapan" />
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-1">Select Stone</label>
              <TomSelect v-model="filter_data.stone_id" :options="{
                placeholder: 'Select Party',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Party'
                  }
                }
              }" class="w-full">
                <option value="0">Select Stone</option>
                <option value="1">N</option>
                <option value="2">CVD</option>
                <option value="3">HPHT</option>
              </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="flex justify-between align-center">
              <div class="mx-1">
                <label for="update-profile-form-6" class="form-label py-1">From</label>
                <input id="tabulator-html-filter-value" v-model="filter_data.from" type="text"
                  class="form-control" placeholder="From" />
              </div>
              <div class="mx-1">
                <label for="update-profile-form-6" class="form-label py-1">To</label>
                <input id="tabulator-html-filter-value" v-model="filter_data.to" type="text"
                  class="form-control" placeholder="To" />
              </div>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="">
                <label for="update-profile-form-6" class="form-label py-1">Shape</label>
                <TomSelect v-model="filter_data.shape" multiple :options="{
                  placeholder: 'Select Shape',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Shape'
                    }
                  }
                }" class="w-full">
                  <option v-for="item in page_data.shapes" :key="item.id" :value="item.id">{{ item.shape_name }}</option>
                </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="">
                <label for="update-profile-form-6" class="form-label py-1">Color</label>
                <TomSelect v-model="filter_data.color" multiple :options="{
                  placeholder: 'Select Color',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Color'
                    }
                  }
                }" class="w-full">
                  <option v-for="item in page_data.colors" :key="item.id" :value="item.id">{{ item.color_name }}</option>
                </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="">
                <label for="update-profile-form-6" class="form-label py-1">Purity</label>
                <TomSelect v-model="filter_data.purity" multiple :options="{
                  placeholder: 'Select Purity',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Purity'
                    }
                  }
                }" class="w-full">
                  <option v-for="item in page_data.purities" :key="item.id" :value="item.id">{{ item.purity_name }}</option>
                </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="">
                <label for="update-profile-form-6" class="form-label py-1">Cut</label>
                <TomSelect v-model="filter_data.cut" multiple :options="{
                  placeholder: 'Select Cut',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Cut'
                    }
                  }
                }" class="w-full">
                  <option v-for="item in page_data.cuts" :key="item.id" :value="item.id">{{ item.cut_name }}</option>
                </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="">
                <label for="update-profile-form-6" class="form-label py-1">Polish</label>
                <TomSelect v-model="filter_data.polish" multiple :options="{
                  placeholder: 'Select Polish',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Polish'
                    }
                  }
                }" class="w-full">
                  <option v-for="item in page_data.polish" :key="item.id" :value="item.id">{{ item.polish_name }}</option>
                </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="">
                <label for="update-profile-form-6" class="form-label py-1">Symmetry</label>
                <TomSelect v-model="filter_data.symmetry" multiple :options="{
                  placeholder: 'Select Summetry',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Summetry'
                    }
                  }
                }" class="w-full">
                  <option v-for="item in page_data.symmetry" :key="item.id" :value="item.id">{{ item.symmetry_name }}</option>
                </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-6">
            <div class="mt-7 flex justify-end pt-2">

              <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary mx-2 py-2"
                @click="onResetFilter">
                Reset
              </button>
              <button id="tabulator-html-filter-go" type="button" class="btn btn-primary mx-2 py-2" tabindex="5"
                @click="initTabulator()">
                Go
              </button>
              <button
                class="btn bg-white w-full sm:w-auto mx-1" @click="export_planner_info"
                aria-expanded="false"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Export
                <ChevronUpIcon class="w-4 h-4 ml-auto sm:ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 hidden">
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
          <div class="report-box zoom-in">
            <div class="box p-2">
              <div class="flex items-center justify-between">
                <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                <div class="text-base text-gray-600">Total Packets</div>
                <div class="text-xl font-medium leading-8">{{ page_data.total.total_packets ?
                  page_data.total.total_packets : 0 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
          <div class="report-box zoom-in">
            <div class="box p-2">
              <div class="flex items-center justify-between">
                <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                <div class="text-base text-gray-600">Issue Weight</div>
                <div class="text-xl font-medium leading-8">{{ page_data.total.total_weight ?
                  $h.myFloat(page_data.total.total_weight) + ' ct' : '0.00 ct' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
          <div class="report-box zoom-in">
            <div class="box p-2">
              <div class="flex items-center justify-between">
                <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                <div class="text-base text-gray-600">Final Weight</div>
                <div class="text-xl font-medium leading-8">{{ page_data.total.final_polish_weight ?
                  $h.myFloat(page_data.total.final_polish_weight) + ' ct' : '0.00 ct' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
          <div class="report-box zoom-in">
            <div class="box p-2">
              <div class="flex items-center justify-between">
                <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                <div class="text-base text-gray-600">Total Majuri</div>
                <div class="text-xl font-medium leading-8">{{ page_data.total.total_majuri ?
                  $h.myFloat(page_data.total.total_majuri) : '0' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Plans Details -->
    <div id="planning-detail-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <div class="flex items-center justify-between w-full">
              <h2 class="font-medium text-base mr-auto">Packet Planning Details</h2>
            </div>
          </div>
          <div class="modal-body p-3" id="planning-details">

            <div class="flex flex-wrap">

              <p class="font-medium text-base mr-auto"><b>Packet Number : </b>{{ page_data.pack_code }} {{
                page_data.packet_details.vepari_no ? ' ( ' + page_data.packet_details.vepari_no + ' ) ' : '' }} <span
                  v-if="page_data.cosing_value">( {{ page_data.cosing_value }} $ )</span></p>
              <p class="font-medium text-base mr-auto" v-if="page_data.packet_details.final_plan_date"><b>Final Plan Date
                  : </b>{{ page_data.packet_details.final_plan_date ? page_data.packet_details.final_plan_date : '' }}</p>
            </div>
            <div class="mt-3">
              <table class="mt-3 w-full border-2">
                <thead>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">No</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Makeable Wt (Ct)</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt (Ct)</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Pieces</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Planner / Checker / Job Party</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Total Plan Value ($)</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Plan Date</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Status</th>
                  </tr>
                </thead>
                <tbody v-for="(plan, index) in page_data.plan_details" :key="index">
                  <tr class="border-b bg-gray-100 dark:bg-dark-1">
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{ index + 1
                    }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{
                      plan.total_weight }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{
                      plan.total_pwt }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{
                      plan.pieces }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{
                      [1, 2, 8].includes(plan.planner_role) ? (plan.manager_first_name + ' ' + plan.manager_last_name) :
                      ([5, 6].includes(plan.planner_role) ? (plan.first_name + ' ' + plan.last_name) :
                        ([4].includes(plan.planner_role) ? (plan.party_first_name + ' ' + plan.party_last_name) : "-")) }}
                    </th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{
                      plan.price }}
                      <span v-if="page_data.cosing_value">
                        <span v-if="plan.price - page_data.cosing_value > 0" class="text-theme-9">
                          <ArrowUpIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (plan.price - page_data.cosing_value)
                            / page_data.cosing_value).toFixed(2) }} % )
                        </span>
                        <span v-else class="text-theme-6">
                          <ArrowDownIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (page_data.cosing_value -
                            plan.price) / page_data.cosing_value).toFixed(2) }} % )
                        </span>
                      </span>
                      <span v-if="page_data.cosing_value_manager && [1, 2, 8, 6, 3].includes(user_details.user_role)">
                        <span v-if="plan.price - page_data.cosing_value_manager > 0" class="text-theme-9">
                          <ArrowUpIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (plan.price -
                            page_data.cosing_value_manager) / page_data.cosing_value_manager).toFixed(2) }} % )
                        </span>
                        <span v-else class="text-theme-6">
                          <ArrowDownIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (page_data.cosing_value_manager -
                            plan.price) / page_data.cosing_value_manager).toFixed(2) }} % )
                        </span>
                      </span>
                    </th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer w-20">{{
                      plan.created_at }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer"><span
                        class="text-theme-6" v-if="plan.status == 0">{{ plan.status == 0 ? 'Active' : 'Selected'
                        }}</span><span class="text-theme-9" v-else>{{ plan.status == 0 ? 'Active' : 'Selected' }}</span>
                    </th>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <table class="my-3 w-full border-2">
                        <thead>
                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">No</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Makeable Wt (Ct)</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt (Ct)</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Shp</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Clr</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Prt</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Cut</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">PL</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Symm</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Fluc</th>
                            <!-- <th class="py-2 border-b text-xs lg:text-md text-center border-r">TN</th> -->
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stn</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Ratio</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Tabl</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Height</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Repo</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Value</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Back</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(plan_det, index) in plan.plan_details" :key="index"
                            class="border hover:bg-gray-200 p-0 cursor-pointer">
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ index + 1 }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.weight }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.polish_weight }}
                            </td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.shape }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.color }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.purity }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.cut }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.polish }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.symmetry }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.fluerocent }}
                            </td>
                            <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.tension }}</td> -->
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.stone }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.ratio }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.tabl }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.height }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.r_rate }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.value }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.discount }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="index == 0"
                              :rowspan="plan.plan_details.length">{{ $h.myFloat(plan.total_value) }}</td>
                          </tr>

                        </tbody>
                      </table>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Plan Details -->
  </div>
</template>

<script>
/* eslint-disable */
import {
  BASE_URL
} from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  watch
} from 'vue'
import {
  helper as $h
} from '@/utils/helper'
import store from '@/store'
import moment from 'moment';
import FileDownload from "js-file-download";

export default defineComponent({
  components: {
    // ActionDropdown,
  },
  setup() {
    const swal = inject('$swal')
    const user_details = computed(() => store.state.user.currentUser)
    const tableRef = ref()
    const tabulator = ref()

    const page_data = reactive({
      planner_details: [],
      plan_details: [],
      total: [],
      packet_details: [],
      colors: [],
      cuts: [],
      purities: [],
      polish: [],
      shapes: [],
      symmetry: [],
      fluerocents: [],
      tensions: [],
      stones: [],
      labs: []

    })

    const filter_data = reactive({
      kapan_no: '',
      from: '',
      to: '',
      stone_id: '0',
      issue_pending: true,
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      final_plan: '0',
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
    })

    const export_data = reactive({
      kapan_no: '',
      from: '',
      to: '',
      stone_id: '0',
      issue_pending: true,
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      final_plan: '0',
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
    })

    const initTabulator = () => {

      export_data.kapan_no = filter_data.kapan_no
      export_data.from = filter_data.from
      export_data.to = filter_data.to
      export_data.stone_id = filter_data.stone_id
      export_data.issue_pending = filter_data.issue_pending
      export_data.start_date = filter_data.start_date
      export_data.end_date = filter_data.end_date
      export_data.final_plan = filter_data.final_plan
      export_data.color = filter_data.color
      export_data.cut = filter_data.cut
      export_data.purity = filter_data.purity
      export_data.polish = filter_data.polish
      export_data.shape = filter_data.shape
      export_data.symmetry = filter_data.symmetry

      const params = {
        kapan_no: (filter_data.kapan_no == '' ? '' : window.btoa(filter_data.kapan_no)),
        from: (filter_data.from == '' ? '' : window.btoa(filter_data.from)),
        to: (filter_data.to == '' ? '' : window.btoa(filter_data.to)),
        stone_id: (filter_data.stone_id == '0' ? '' : window.btoa(filter_data.stone_id)),
        final_plan: (filter_data.final_plan == '0' ? '' : window.btoa(filter_data.final_plan)),
        color: (filter_data.color.length == 0 ? '' : filter_data.color),
        cut: (filter_data.cut.length == 0 ? '' : filter_data.cut),
        purity: (filter_data.purity.length == 0 ? '' : filter_data.purity),
        polish: (filter_data.polish.length == 0 ? '' : filter_data.polish),
        shape: (filter_data.shape.length == 0 ? '' : filter_data.shape),
        symmetry: (filter_data.symmetry.length == 0 ? '' : filter_data.symmetry)
      }

      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/report/ssrc_stocks',
        ajaxConfig: {
          method: "GET", //set request type to Position
          headers: {
            "Content-type": 'application/json; charset=utf-8', //set specific content type
            "Authorization": "Bearer " + localStorage.getItem("token")
          },
        },
        ajaxParams: params,
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        headerSort: false,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [

          // For HTML table
          {
            title: 'Stock No',
            field: 'stock_no',
            responsive: 0,
            vertAlign: 'middle',
            hozAlign: 'left',
            minWidth: 90,
            print: false,
            download: false,
            formatter(cell) {

              if(cell.getData().status == 1) {

                return cell.getData().packet_code
              } else {

                return cell.getData().plan_packet_code
              }
            }
          },
          {
            title: 'Part No',
            field: 'plan_no',
            responsive: 0,
            vertAlign: 'middle',
            hozAlign: 'left',
            minWidth: 90,
            print: false,
            download: false,
            formatter(cell) {

              if(cell.getData().status == 1) {

                return "-"
              } else {

                return cell.getData().plan_no
              }
            }
          },
          {

            title: 'Manager/Worker',
            field: 'karigar_first_name',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

                if (cell.getData().transaction_type == 1) {
                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
        <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
        </div>`;
                } else if (cell.getData().transaction_type == 2) {
                    if (cell.getData().process_type == 1 || cell.getData().process_type == 2 || cell.getData().process_type == 5 || cell.getData().process_type == 6 || cell.getData().process_type == 7 || cell.getData().process_type == 8 || cell.getData().process_type == 9) {

                        if (cell.getData().employee_role == 7) {
                            return `<div class="">` + cell.getData().karigar_first_name + ' ' + cell.getData().karigar_last_name + `<br>
          <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
        </div>`;
                        } else if (cell.getData().employee_role == 4) {
                            return `<div class="">` + cell.getData().pp_first_name + ' ' + cell.getData().pp_last_name + `<br>
          <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
        </div>`;
                        }
                    } else if (cell.getData().process_type == 3 || cell.getData().process_type == 4) {
                        return `<div class="">` + cell.getData().planner_first_name + ' ' + cell.getData().planner_last_name + `<br>
          <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
        </div>`;
                    }
                } else if (cell.getData().transaction_type == 4) {
                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
        <p class="text-theme-9 font-12 font-bold">` + 'Job Done' + `</p>
        </div>`;
                } else if (cell.getData().transaction_type == 3) {
                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
        <p class="text-theme-9 font-12 font-bold">` + '' + `</p>
        </div>`;
                }
            }
            },
          {
            title: 'Rough WT',
            field: 'weight',
            responsive: 0,
            vertAlign: 'middle',
            hozAlign: 'center',
            minWidth: 90,
            print: false,
            download: false
          },
          {
            title: 'Polish WT',
            field: 'polish_weight',
            responsive: 0,
            vertAlign: 'middle',
            hozAlign: 'center',
            minWidth: 90,
            print: false,
            download: false
          },
          {
            title: 'Shape',
            field: 'shape',
            responsive: 0,
            vertAlign: 'middle',
            hozAlign: 'center',
            minWidth: 90,
            print: false,
            download: false
          },
          {
            title: 'Color',
            field: 'color',
            responsive: 0,
            vertAlign: 'middle',
            hozAlign: 'center',
            minWidth: 90,
            print: false,
            download: false
          },
          {
            title: 'Purity',
            field: 'purity',
            responsive: 0,
            vertAlign: 'middle',
            hozAlign: 'left',
            minWidth: 90,
            print: false,
            download: false
          },
          {
            title: 'Cut',
            field: 'cut',
            responsive: 0,
            vertAlign: 'middle',
            hozAlign: 'left',
            minWidth: 90,
            print: false,
            download: false
          },
          {
            title: 'Polish',
            field: 'polish',
            responsive: 0,
            vertAlign: 'middle',
            hozAlign: 'left',
            minWidth: 90,
            print: false,
            download: false
          },
          {
            title: 'Symm',
            field: 'symmetry',
            responsive: 0,
            vertAlign: 'middle',
            hozAlign: 'left',
            minWidth: 90,
            print: false,
            download: false
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })


      // axios({
      //   url: 'api/report/report-gpp-total',
      //   method: 'post',
      //   baseURL: BASE_URL,
      //   headers: {
      //     Authorization: 'Bearer ' + localStorage.getItem('token')
      //   },
      //   data: params,
      //   config: {
      //     headers: {
      //       'Accept': 'application/json'
      //     }
      //   }
      // }).then((res) => {
      //   page_data.total = res.data.items
      // })

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    const get_all_parameters = () => {
            axios({
                url: 'api/master/get_all_parameters',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                page_data.colors = res.data.color
                page_data.cuts = res.data.cut
                page_data.purities = res.data.purity
                page_data.polish = res.data.polish
                page_data.shapes = res.data.shape
                page_data.symmetry = res.data.symmetry
                page_data.fluerocents = res.data.fluerocent
                page_data.tensions = res.data.tension
                page_data.stones = res.data.stone
                page_data.labs = res.data.lab
            })
        }

    const export_planner_info = () => {
      let objectDate = new Date();

          let day = objectDate.getDate();
          let month = objectDate.getMonth();
          let year = objectDate.getFullYear();

          let format1 = day + "" + month + "" + year;


          let filename = "planning-report-" + format1 + ".xlsx";
          // let filename = "kapan-purchase" + ".xlsx";

          // let selected_items = []
          // if(page_data.selected_items.length > 0) {

          //   page_data.selected_items.forEach(element => {

          //     selected_items.push(element.id)
          //   });

          // }

          const params = {
            kapan_no: (export_data.kapan_no == '' ? '' : window.btoa(export_data.kapan_no)),
            from: (export_data.from == '' ? '' : window.btoa(export_data.from)),
            to: (export_data.to == '' ? '' : window.btoa(export_data.to)),
            stone_id: (export_data.stone_id == '0' ? '' : window.btoa(export_data.stone_id)),
            final_plan: (export_data.final_plan == '0' ? '' : window.btoa(export_data.final_plan)),
            color: (export_data.color.length == 0 ? '' : export_data.color),
            cut: (export_data.cut.length == 0 ? '' : export_data.cut),
            purity: (export_data.purity.length == 0 ? '' : export_data.purity),
            polish: (export_data.polish.length == 0 ? '' : export_data.polish),
            shape: (export_data.shape.length == 0 ? '' : export_data.shape),
            symmetry: (export_data.symmetry.length == 0 ? '' : export_data.symmetry),
          }
          let promise = axios({
              url: "/api/export/download_search_report",
              method: "post",
              data: params,
              baseURL: BASE_URL,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              },
              responseType: "blob",
          });

          return promise
              .then((result) => result.data)
              .then((data) => {
                  FileDownload(data, filename);
              })
              .catch((_error) => {
                  return [];
              });
    }

    const onResetFilter = () => {

      filter_data.kapan_no = ""
      filter_data.from = ""
      filter_data.to = ""
      filter_data.stone_id = "0"
      filter_data.issue_pending = true
      filter_data.start_date = moment().startOf('month').format('YYYY-MM-DD')
      filter_data.end_date = moment().format('YYYY-MM-DD')
      filter_data.final_plan = "0"

    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      get_all_parameters()

    })
    return {
      page_data,
      user_details,
      filter_data,
      initTabulator,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      tableRef,
      onResetFilter,
      export_planner_info

    }
  }
})
</script>
