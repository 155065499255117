<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Received Process Transactions</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <!-- <div class="mt-2">
        <label for="update-profile-form-6" class="form-label"
          >Transaction No</label
        >
        <input
          id="tabulator-html-filter-value"
          v-model="screenData.transaction_no"
          type="text"
          class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
          placeholder="Transaction No"
          @change="initTabulator"
        />
      </div> -->
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
</template>

<script>
import {
    defineComponent,
    computed,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import xlsx from 'xlsx'
import {
    useStore
} from '@/store'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'
import moment from 'moment'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()
        const route = useRoute()
        const tableRef = ref()
        const tabulator = ref()
        const filter = reactive({
            field: 'lot_no',
            type: 'like',
            value: ''
        })

        const swal = inject('$swal')
        const userDetails = computed(() => store.state.user.currentUser)

        const screenData = reactive({
            transaction_no: '',
            issuer: '',
            status: '0',
            employee_type: '3',
            employee_id: '',
            process_id: '0',
            employees: [],
            process_items: [],
            emp_hit: 1
        })

        const initTabulator = () => {

          localStorage.setItem('transaction_filters', JSON.stringify({
            employee_type: screenData.employee_type,
            employee_id: screenData.employee_id,
            process_id: screenData.process_id,
            status: screenData.status,
          }))
            let _this = this;
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + 'api/process/receive_list_items',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: {
                    transaction_no: screenData.transaction_no
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader: true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [

                    // For HTML table
                    {
                        title: 'Transaction No',
                        minWidth: 200,
                        responsive: 0,
                        field: 'transaction_code',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                        formatter(cell) {
                          const a = cash(`<div><i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>
                          ${
                            cell.getData().transaction_code
                          }
                          </div>`)
                          cash(a).on('click', function () {
                              router.push({
                                  'path': '/packets/receive/transaction/' + window.btoa(cell.getData().id)
                              })
                          })
                          return a[0]
                        }
                    },
                    {
                        title: 'Pieces',
                        field: 'receive_pieces',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Receive Weight',
                        field: 'receive_weight',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Receive Date',
                        field: 'created_time',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                      title: 'Status',
                      field: 'active_status',
                      vertAlign: 'middle',
                      print: false,
                      download: false,
                      formatter(cell) {
                        return `<div class="flex lg:justify-center ${
                          cell.getData().active_status == 'Active' ? 'text-theme-9' : 'text-theme-6'
                        }">
                          <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                            cell.getData().active_status == 'Active' ? 'Active' : 'Received'
                          }
                        </div>`
                      }
                    },

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Filter function
        const onFilter = () => {
            tabulator.value.setFilter(filter.field, filter.type, filter.value)
        }

        //Edit user
        const editUser = (id) => {
            console.log(id);
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'buyer_list.xlsx', {
                sheetName: 'Buyers'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {
          initTabulator()
          reInitOnResizeWindow()
        })

        return {
            tableRef,
            screenData,
            userDetails,
            filter,
            onFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            initTabulator,
            onPrint,
            editUser
        }

    }
})
</script>
