<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Employee Form</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div id="input" class="p-5">
            <div class="preview">
              <div>
                <label for="regular-form-2" class="form-label">First Name && Last Name</label>
                <div class="sm:grid grid-cols-3 gap-2">
                  <div>
                    <div>

                      <input v-model="formData.first_name"
                        id="regular-form-1"
                        type="text"
                        class="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.first_name">{{ pagedata.errors.first_name[0] }}</span>
                  </div>
                  <div>
                    <div>

                      <input v-model="formData.last_name"
                        id="regular-form-1"
                        type="text"
                        class="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.last_name">{{ pagedata.errors.last_name[0] }}</span>
                  </div>
                  <div>
                    <div>
                      <input v-model="formData.short_name"
                      id="regular-form-1"
                      type="text"
                      class="form-control"
                      placeholder="Short Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.short_name">{{ pagedata.errors.short_name[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div>

                  <label class="form-label">Email</label>
                  <div class="input-group">
                      <input v-model="formData.email"
                      id="regular-form-1"
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      />
                  </div>
                  <span class="text-primary-3" v-if="pagedata.errors.email">{{ pagedata.errors.email[0] }}</span>
                </div>
              </div>
              <div class="mt-3">
                <label for="regular-form-2" class="form-label">Mobile No & Birth Date</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div>
                    <div class="relative">
                      <div
                        class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
                      >
                        <CalendarIcon class="w-4 h-4" />
                      </div>
                      <Litepicker
                        v-model="formData.bdate"
                        :options="{
                          autoApply: true,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1950,
                            maxYear: 2010,
                            months: true,
                            years: true
                          }
                        }"
                        class="form-control pl-12"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.bdate">{{ pagedata.errors.bdate[0] }}</span>
                  </div>
                  </div>
                  <div>
                    <div>

                      <input
                        id="regular-form-2"
                        type="tel" v-model="formData.mobile_no"
                        class="form-control form-control"
                        placeholder="987-6543-210"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.mobile_no">{{ pagedata.errors.mobile_no[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label for="regular-form-4" class="form-label">Current Address</label>
                <textarea
                  id="regular-form-4"
                  type="password"
                  class="form-control"
                  v-model="formData.addr_current"
                  placeholder="Current Address"
                ></textarea>
              </div>
              <div class="mt-3">
                <label for="regular-form-5" class="form-label">Perminant Address</label>
                <textarea
                  id="regular-form-5"
                  type="text"
                  v-model="formData.addr_permant"
                  class="form-control"
                  placeholder="Permant Address"
                ></textarea>
              </div>
              <div class="mt-3">
              <label class="form-label">City & State</label>
              <div class="sm:grid grid-cols-2 gap-2">
                <div class="input-group">
                  <div id="input-group-3" class="input-group-text">City</div>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.city"
                    placeholder="City"
                    aria-describedby="input-group-3"
                  />
                </div>
                <div class="input-group mt-2 sm:mt-0">
                  <div id="input-group-4" class="input-group-text">State</div>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.state"
                    placeholder="State"
                    aria-describedby="input-group-4"
                  />
                </div>
              </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Pincode & Material Status</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div class="input-group">
                  <div id="input-group-3" class="input-group-text">Pincode</div>
                  <input
                    type="tel"
                    class="form-control"
                    placeholder="PinCode"
                    v-model="formData.pin"
                    aria-describedby="input-group-3"
                  />
                </div>
                  <div>
                    <select
                      class="form-select"
                      v-model="formData.material"
                      aria-label="Default select example"
                    >
                      <option value="merried">Merried</option>
                      <option value="unmerried">Unmerried</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Vertical Form -->
        <div class="intro-y box">
          <div id="vertical-form" class="p-5">
              <div class="mt-3">
                <label class="form-label">Referal Name & Mobile No</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div class="input-group">
                    <div id="input-group-3" class="input-group-text">Name</div>
                    <input
                      type="text"
                      v-model="formData.r_name"
                      class="form-control"
                      placeholder="Name"
                      aria-describedby="input-group-3"
                    />
                  </div>
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-4" class="input-group-text">Mobile</div>
                    <input
                      type="tel"
                      class="form-control"
                      v-model="formData.r_mobile"
                      placeholder="987-6543-210"
                      aria-describedby="input-group-4"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Joining Date & End Date</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div class="input-group">
                    <div id="input-group-3" class="input-group-text">Joining</div>
                    <Litepicker
                        v-model="formData.s_date"
                        :options="{
                          autoApply: true,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 2010,
                            maxYear: 2024,
                            months: true,
                            years: true,
                          },
                        }"
                        class="form-control block mx-auto"
                      />
                  </div>
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-3" class="input-group-text">End</div>
                    <Litepicker
                        v-model="formData.e_date"
                        :options="{
                          autoApply: true,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 2010,
                            maxYear: 2030,
                            months: true,
                            years: true,
                          },
                        }"
                        class="form-control block mx-auto"
                      />
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div>
                  <label class="form-label">Select Manager</label>
                  <TomSelect
                      v-model="formData.manager_id"
                      @change="getProcesses"
                      :options="{
                        placeholder: 'Select your favorite actors'
                      }"
                      class="w-full"
                    >
                    <option value="0">Select Manager</option>
                      <option v-for="dept in pagedata.managers" :key="dept.id" :value="dept.id">{{ dept.first_name + ' ' + dept.last_name }}</option>
                    </TomSelect>

                </div>
                <span class="text-primary-3" v-if="pagedata.errors.manager_id">{{ pagedata.errors.manager_id[0] }}</span>
              </div>
              <div class="mt-3">
                <div>
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text">Process</div>
                      <TomSelect
                        v-model="formData.process"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{ process.process_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.process">{{ pagedata.errors.process[0] }}</div>
                  </div>
              </div>
              <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-4" class="input-group-text">Limit</div>
                      <input
                        type="text"
                        class="form-control w-40"
                        v-model="formData.limit"
                        placeholder="Packet Limit"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.limit">{{ pagedata.errors.limit[0] }}</div>
                  </div>
              <div class="mt-3">
                <div>
                  <label class="form-label mr-5">Process Per Price</label>
                <label class="form-label">Salary Fixed</label>
                </div>
                <div class="sm:grid grid-cols-2 gap-2 items-center">
                  <div class="flex items-center justify-around">
                    <div class="input-group">
                      <input
                        type="checkbox"
                        v-model="formData.processper"
                        class="form-check-switch my-2"
                      />
                    </div>
                    <div class="mr-5 input-group">
                      <input
                        type="checkbox"
                        v-model="formData.fixed"
                        class="form-check-switch my-2"
                      />
                    </div>
                  </div>
                  <div v-if="formData.fixed">
                    <div class="input-group sm:mt-0">
                      <div id="input-group-4" class="input-group-text">Salary</div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="formData.salary"
                        placeholder="Salary"
                        aria-describedby="input-group-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right mt-5">
                <router-link :to="{ name: 'employee-details' }" class="btn btn-outline-secondary w-24 mr-1">
                  Cancel
                </router-link>
                <button type="button" @click="SaveManager" class="btn btn-primary w-24">Save</button>
              </div>
          </div>
        </div>
        <!-- END: Vertical Form -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import moment from 'moment'
import { stringify } from 'json5'
export default defineComponent({
  setup() {
    const pagedata = reactive({
      processes : [],
      managers : [],
      errors: [],
      error: ''
    })
    const route = useRoute()
    const formData = reactive({
      id: '',
      first_name : '',
      last_name : '',
      short_name : '',
      email : '',
      mobile_no : '',
      r_name : '',
      r_mobile : '',
      bdate : '',
      addr_current: '',
      addr_permant: '',
      city: '',
      state: '',
      pin: '',
      material: 'unmerried',
      manager_id: '0',
      s_date: '',
      e_date: '',
      process: [],
      fixed: false,
      processper: true,
      salary: '',
      user_role: 7,
      limit: '',
      user_type: 2,
    })
    function subtractYears(date, years) {
        // 👇 make copy with "Date" constructor
        const dateCopy = new Date(date);

        dateCopy.setFullYear(date.getFullYear() - years);
        dateCopy.setDate(1);
        dateCopy.setMonth(1);

        return dateCopy;
      }
    onMounted(() => {
      const date = new Date();

      formData.bdate = moment(subtractYears(date, 18)).format('DD MMM, YYYY');
      getManagers()
      if(route.params.id) {
        formData.id = route.params.id
        getEmployeeDetails()
      }
    })
    function getManagers() {
      axios({
        url: 'api/master/dp_managers',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.managers = res.data.items
      })
    }
    function getProcesses() {
      if(formData.manager_id == '0') {
        formData.process = []
      } else {

        const params = {
          manager_id : formData.manager_id
        }
        // formData.process = []
        axios({
          url: 'api/master/get_process_by_manager',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.processes = res.data.items
        })
      }

    }
    function SaveManager() {
      formData.bdate = moment(formData.bdate).format('YYYY-MM-DD');
      formData.s_date = moment(formData.s_date).format('YYYY-MM-DD');
      formData.e_date = moment(formData.e_date).format('YYYY-MM-DD');
      if(route.params.id) {
        axios({
          url: 'api/master/update_user_details',
          method: 'post',
          baseURL: BASE_URL,
          data: formData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then(() => {
          router.push({ name: 'employee-details' })
        }).catch((err) => {
          if(err.response.data.error) {
            pagedata.error = err.response.data.error
            swal("Warning!", pagedata.error, "warning");
          } else {
            pagedata.errors = err.response.data.errors
            setTimeout(() => {
              pagedata.errors = []
            }, 3000);
          }
        })
      } else {
          axios({
            url: 'api/master/add_new_user',
          method: 'post',
          baseURL: BASE_URL,
          data: formData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then(() => {
          router.push({ name: 'employee-details' })
        }).catch((err) => {
          if(err.response.data.error) {
            pagedata.error = err.response.data.error
            swal("Warning!", pagedata.error, "warning");
          } else {
            pagedata.errors = err.response.data.errors
            setTimeout(() => {
              pagedata.errors = []
            }, 3000);
          }
        })
      }
    }
    function getEmployeeDetails() {
      const params = {
        id : route.params.id, user_type: formData.user_type
      }
      axios({
          url: 'api/master/show_user_details',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          formData.first_name = res.data.item.first_name
          formData.last_name = res.data.item.last_name
          formData.short_name = res.data.item.short_name
          formData.email = res.data.item.email
          formData.mobile_no = res.data.item.mobile_no
          formData.r_name = res.data.item.ref_name
          formData.r_mobile = res.data.item.ref_mobile
          formData.bdate = res.data.item.dob
          formData.addr_current = res.data.item.current_addr
          formData.addr_permant = res.data.item.permenamt_addr
          formData.city = res.data.item.city
          formData.state = res.data.item.state
          formData.pin = res.data.item.pincode
          formData.material = res.data.item.merital_status == 0 ? 'unmerried':'merried'
          formData.manager_id = stringify(res.data.item.manager_id)
          formData.s_date = res.data.item.joining_date
          formData.e_date = res.data.item.resign_date
          formData.fixed = res.data.item.salary_fixed ? true:false
          formData.salary = res.data.item.salary
          formData.user_role = res.data.item.user_role
          formData.limit = res.data.item.limit
          formData.process = res.data.item.process == null ? []:res.data.item.process
        })

    }
    watch(() => formData.fixed, (CurrentVal, OldVal) => {
        formData.processper = !formData.fixed
    })
    watch(() => formData.processper, (CurrentVal, OldVal) => {
        formData.fixed = !formData.processper
    })
    return {
      formData,
      pagedata,
      SaveManager,
      getProcesses
    }
  }
})
</script>
