<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rough Kapans</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col">
        <div class="grid grid-cols-12 gap-x-2">
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-1">Packet / Kapan</label>
              <input id="tabulator-html-filter-value" v-model="filter_data.kapan_no" type="text"
                class="form-control 2xl:w-full mt-2 sm:mt-0" placeholder="Packet / Kapan" />
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="[1, 2, 8].includes(user_details.user_role)">
            <div>
              <label for="update-profile-form-6" class="form-label py-1">Select Planner</label>
              <TomSelect v-model="filter_data.planner_id" :options="{
                placeholder: 'Select Party',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Party'
                  }
                }
              }" class="w-full">
                <option value="0">Select Planner</option>
                <option v-for="item in page_data.planner_details" :key="item.id" :value="item.id">{{ item.firm_name ?
                  item.firm_name : item.first_name + ' ' + item.last_name }}</option>
              </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-1">Select Stone</label>
              <TomSelect v-model="filter_data.stone_id" :options="{
                placeholder: 'Select Party',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Party'
                  }
                }
              }" class="w-full">
                <option value="0">Select Stone</option>
                <option value="1">N</option>
                <option value="2">CVD</option>
                <option value="3">HPHT</option>
              </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-1">Final Plan</label>
              <TomSelect v-model="filter_data.final_plan" :options="{
                placeholder: 'Final Plan',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Final Plan'
                  }
                }
              }" class="w-full">
                <option value="0">All</option>
                <option value="1">Final Plan</option>
              </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-2">
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label">Select Start
                  Date</label><input class="show-code form-check-switch mr-0 ml-3 h-6" type="checkbox"
                  v-model="filter_data.issue_pending" /></div>
              <input type="date" id="update-profile-form-13" class="form-control" :disabled="filter_data.issue_pending"
                placeholder="" v-model="filter_data.start_date" />
            </div>

          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-1">Select End Date</label>
              <input type="date" id="update-profile-form-13" class="form-control" placeholder=""
                :disabled="filter_data.issue_pending" v-model="filter_data.end_date" />
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="[1, 2, 8].includes(user_details.user_role)">
            <div>
              <label for="update-profile-form-6" class="form-label py-1">Report Type</label>
              <TomSelect v-model="filter_data.report_type" :options="{
                placeholder: 'Select Party',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Report Type'
                  }
                }
              }" class="w-full">
                <option value="1">Summary</option>
                <option value="2">Lot Wise</option>
              </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-10" v-if="[1, 2, 8].includes(user_details.user_role)">
            <div class="mt-7 flex justify-end pt-2">

              <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary mx-2 py-2"
                @click="onResetFilter">
                Reset
              </button>
              <button id="tabulator-html-filter-go" type="button" class="btn btn-primary mx-2 py-2" tabindex="5"
                @click="initTabulator()">
                Go
              </button>
              <button
                class="btn bg-white w-full sm:w-auto mx-1" @click="export_planner_info"
                aria-expanded="false"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Export
                <ChevronUpIcon class="w-4 h-4 ml-auto sm:ml-2" />
              </button>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2" v-else>
            <div class="mt-7 flex justify-end pt-2">

              <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary mx-2 py-2"
                @click="onResetFilter">
                Reset
              </button>
              <button id="tabulator-html-filter-go" type="button" class="btn btn-primary mx-2 py-2" tabindex="5"
                @click="initTabulator()">
                Go
              </button>
              <button
                class="btn bg-white w-full sm:w-auto mx-1" @click="export_planner_info"
                aria-expanded="false"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Export
                <ChevronUpIcon class="w-4 h-4 ml-auto sm:ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
          <div class="report-box zoom-in">
            <div class="box p-2">
              <div class="flex items-center justify-between">
                <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                <div class="text-base text-gray-600">Total Packets</div>
                <div class="text-xl font-medium leading-8">{{ page_data.total.total_packets ?
                  page_data.total.total_packets : 0 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
          <div class="report-box zoom-in">
            <div class="box p-2">
              <div class="flex items-center justify-between">
                <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                <div class="text-base text-gray-600">Issue Weight</div>
                <div class="text-xl font-medium leading-8">{{ page_data.total.total_weight ?
                  $h.myFloat(page_data.total.total_weight) + ' ct' : '0.00 ct' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
          <div class="report-box zoom-in">
            <div class="box p-2">
              <div class="flex items-center justify-between">
                <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                <div class="text-base text-gray-600">Final Weight</div>
                <div class="text-xl font-medium leading-8">{{ page_data.total.final_polish_weight ?
                  $h.myFloat(page_data.total.final_polish_weight) + ' ct' : '0.00 ct' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
          <div class="report-box zoom-in">
            <div class="box p-2">
              <div class="flex items-center justify-between">
                <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                <div class="text-base text-gray-600">Total Majuri</div>
                <div class="text-xl font-medium leading-8">{{ page_data.total.total_majuri ?
                  $h.myFloat(page_data.total.total_majuri) : '0' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Plans Details -->
    <div id="planning-detail-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <div class="flex items-center justify-between w-full">
              <h2 class="font-medium text-base mr-auto">Packet Planning Details</h2>
            </div>
          </div>
          <div class="modal-body p-3" id="planning-details">

            <div class="flex flex-wrap">

              <p class="font-medium text-base mr-auto"><b>Packet Number : </b>{{ page_data.pack_code }} {{
                page_data.packet_details.vepari_no ? ' ( ' + page_data.packet_details.vepari_no + ' ) ' : '' }} <span
                  v-if="page_data.cosing_value">( {{ page_data.cosing_value }} $ )</span></p>
              <p class="font-medium text-base mr-auto" v-if="page_data.packet_details.final_plan_date"><b>Final Plan Date
                  : </b>{{ page_data.packet_details.final_plan_date ? page_data.packet_details.final_plan_date : '' }}</p>
            </div>
            <div class="mt-3">
              <table class="mt-3 w-full border-2">
                <thead>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">No</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Makeable Wt (Ct)</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt (Ct)</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Pieces</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Planner / Checker / Job Party</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Total Plan Value ($)</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Plan Date</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Status</th>
                  </tr>
                </thead>
                <tbody v-for="(plan, index) in page_data.plan_details" :key="index">
                  <tr class="border-b bg-gray-100 dark:bg-dark-1">
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{ index + 1
                    }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{
                      plan.total_weight }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{
                      plan.total_pwt }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{
                      plan.pieces }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{
                      [1, 2, 8].includes(plan.planner_role) ? (plan.manager_first_name + ' ' + plan.manager_last_name) :
                      ([5, 6].includes(plan.planner_role) ? (plan.first_name + ' ' + plan.last_name) :
                        ([4].includes(plan.planner_role) ? (plan.party_first_name + ' ' + plan.party_last_name) : "-")) }}
                    </th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{
                      plan.price }}
                      <span v-if="page_data.cosing_value">
                        <span v-if="plan.price - page_data.cosing_value > 0" class="text-theme-9">
                          <ArrowUpIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (plan.price - page_data.cosing_value)
                            / page_data.cosing_value).toFixed(2) }} % )
                        </span>
                        <span v-else class="text-theme-6">
                          <ArrowDownIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (page_data.cosing_value -
                            plan.price) / page_data.cosing_value).toFixed(2) }} % )
                        </span>
                      </span>
                      <span v-if="page_data.cosing_value_manager && [1, 2, 8, 6, 3].includes(user_details.user_role)">
                        <span v-if="plan.price - page_data.cosing_value_manager > 0" class="text-theme-9">
                          <ArrowUpIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (plan.price -
                            page_data.cosing_value_manager) / page_data.cosing_value_manager).toFixed(2) }} % )
                        </span>
                        <span v-else class="text-theme-6">
                          <ArrowDownIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (page_data.cosing_value_manager -
                            plan.price) / page_data.cosing_value_manager).toFixed(2) }} % )
                        </span>
                      </span>
                    </th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer w-20">{{
                      plan.created_at }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer"><span
                        class="text-theme-6" v-if="plan.status == 0">{{ plan.status == 0 ? 'Active' : 'Selected'
                        }}</span><span class="text-theme-9" v-else>{{ plan.status == 0 ? 'Active' : 'Selected' }}</span>
                    </th>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <table class="my-3 w-full border-2">
                        <thead>
                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">No</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Makeable Wt (Ct)</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt (Ct)</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Shp</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Clr</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Prt</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Cut</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">PL</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Symm</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Fluc</th>
                            <!-- <th class="py-2 border-b text-xs lg:text-md text-center border-r">TN</th> -->
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stn</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Ratio</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Tabl</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Height</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Repo</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Value</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Back</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(plan_det, index) in plan.plan_details" :key="index"
                            class="border hover:bg-gray-200 p-0 cursor-pointer">
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ index + 1 }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.weight }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.polish_weight }}
                            </td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.shape }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.color }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.purity }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.cut }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.polish }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.symmetry }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.fluerocent }}
                            </td>
                            <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.tension }}</td> -->
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.stone }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.ratio }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.tabl }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.height }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.r_rate }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.value }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.discount }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="index == 0"
                              :rowspan="plan.plan_details.length">{{ $h.myFloat(plan.total_value) }}</td>
                          </tr>

                        </tbody>
                      </table>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Plan Details -->
  </div>
</template>

<script>
/* eslint-disable */
import {
  BASE_URL
} from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  watch
} from 'vue'
import {
  helper as $h
} from '@/utils/helper'
import store from '@/store'
import moment from 'moment';
import FileDownload from "js-file-download";

export default defineComponent({
  components: {
    // ActionDropdown,
  },
  setup() {
    const swal = inject('$swal')
    const user_details = computed(() => store.state.user.currentUser)
    const tableRef = ref()
    const tabulator = ref()

    const page_data = reactive({
      planner_details: [],
      plan_details: [],
      total: [],
      packet_details: [],

    })

    const filter_data = reactive({
      kapan_no: '',
      planner_id: '0',
      stone_id: '0',
      issue_pending: false,
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      final_plan: '0',
      report_type: '2',
    })

    const export_data = reactive({
      kapan_no: '',
      planner_id: '0',
      stone_id: '0',
      issue_pending: false,
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      final_plan: '0',
      report_type: '2',
    })

    const initTabulator = () => {

      export_data.kapan_no = filter_data.kapan_no
      export_data.planner_id = filter_data.planner_id
      export_data.stone_id = filter_data.stone_id
      export_data.issue_pending = filter_data.issue_pending
      export_data.start_date = filter_data.start_date
      export_data.end_date = filter_data.end_date
      export_data.final_plan = filter_data.final_plan
      export_data.report_type = filter_data.report_type

      const params = {
        kapan_no: (filter_data.kapan_no == '' ? '' : window.btoa(filter_data.kapan_no)),
        planner_id: (filter_data.planner_id == '0' ? '' : window.btoa(filter_data.planner_id)),
        stone_id: (filter_data.stone_id == '0' ? '' : window.btoa(filter_data.stone_id)),
        report_type: (filter_data.report_type == '0' ? '' : window.btoa(filter_data.report_type)),
        issue_pending: (filter_data.issue_pending),
        start_date: (filter_data.start_date),
        end_date: (filter_data.end_date),
        final_plan: (filter_data.final_plan == '0' ? '' : window.btoa(filter_data.final_plan)),
      }

      if (filter_data.report_type == 1) {
        if ([1, 2, 8].includes(user_details.value.user_role)) {

          tabulator.value = new Tabulator(tableRef.value, {
            ajaxURL: BASE_URL + 'api/report/report-gpp',
            ajaxConfig: {
              method: "GET", //set request type to Position
              headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
              },
            },
            ajaxParams: params,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            headerSort: false,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: 'collapse',
            placeholder: 'No matching records found',
            columns: [

              // For HTML table
              {
                title: 'Planner',
                field: 'planner',
                responsive: 0,
                vertAlign: 'middle',
                hozAlign: 'left',
                minWidth: 90,
                print: false,
                download: false,
                formatter(cell) {
                  return `<div>
      <div class="whitespace-nowrap">${(cell.getData().planner_first_name ? cell.getData().planner_first_name : '-') + ' ' + (cell.getData().planner_last_name ? cell.getData().planner_last_name : '')
                    }</div>
    </div>`
                },
                cellClick: function (e, cell) {

                  // Open Model Of Planning
                  // get_plan_details(cell.getData().id)

                }
              },
              {
                title: 'Total Packets',
                field: 'total_packets',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              },
              {
                title: 'Total Issue Weight',
                field: 'issue_weight',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              },
              {
                title: 'Total Plan',
                field: 'total_plan',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              },
              {
                title: 'Final Plan',
                field: 'final_plan',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              },
              {
                title: 'Final Plan Issue (CT)',
                field: 'final_plan_issue_weight',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              },
              {
                title: 'Total Majuri',
                field: 'total_majuri',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              }
            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
        }


        axios({
          url: 'api/report/report-gpp-total',
          method: 'post',
          baseURL: BASE_URL,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          data: params,
          config: {
            headers: {
              'Accept': 'application/json'
            }
          }
        }).then((res) => {
          page_data.total = res.data.items
        })
      } else if (filter_data.report_type == 2) {

        if ([1, 2, 8].includes(user_details.value.user_role)) {

          tabulator.value = new Tabulator(tableRef.value, {
            ajaxURL: BASE_URL + 'api/report/report-gpp',
            ajaxConfig: {
              method: "GET", //set request type to Position
              headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
              },
            },
            ajaxParams: params,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            headerSort: false,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: 'collapse',
            placeholder: 'No matching records found',
            columns: [

              // For HTML table
              {
                title: 'Packet No',
                field: 'number',
                responsive: 0,
                vertAlign: 'middle',
                hozAlign: 'left',
                minWidth: 90,
                print: false,
                download: false,
                formatter: "textarea",
                cellClick: function (e, cell) {

                  // Open Model Of Planning
                  get_plan_details(cell.getData().id)

                }
              },
              {
                title: 'Planner',
                field: 'planner',
                responsive: 0,
                vertAlign: 'middle',
                hozAlign: 'left',
                minWidth: 90,
                print: false,
                download: false,
                formatter(cell) {
                  return `<div>
                      <div class="whitespace-nowrap">${(cell.getData().planner_first_name ? cell.getData().planner_first_name : '-') + ' ' + (cell.getData().planner_last_name ? cell.getData().planner_last_name : '')
                    }</div>
                    </div>`
                },
                cellClick: function (e, cell) {

                  // Open Model Of Planning
                  get_plan_details(cell.getData().id)

                }
              },
              {
                title: 'Plan Weight',
                field: 'issue_weight',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              },
              {
                title: 'Total Plan',
                field: 'total_plan',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              },
              {
                title: 'Final Plan',
                field: 'plan_status',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              },
              {
                title: 'Total Majuri',
                field: 'total_majuri',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              }
            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
        } else if ([5, 6].includes(user_details.value.user_role)) {

          tabulator.value = new Tabulator(tableRef.value, {
            ajaxURL: BASE_URL + 'api/report/report-gpp',
            ajaxConfig: {
              method: "GET", //set request type to Position
              headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
              },
            },
            ajaxParams: params,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            headerSort: false,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: 'collapse',
            placeholder: 'No matching records found',
            columns: [

              // For HTML table
              {
                title: 'Packet No',
                field: 'number',
                responsive: 0,
                vertAlign: 'middle',
                hozAlign: 'left',
                minWidth: 90,
                print: false,
                download: false,
                formatter: "textarea",
                cellClick: function (e, cell) {

                  // Open Model Of Planning
                  get_plan_details(cell.getData().id)

                }
              },
              {
                title: 'Plan Weight',
                field: 'issue_weight',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              },
              {
                title: 'Total Plan',
                field: 'total_plan',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              },
              {
                title: 'Final Plan',
                field: 'plan_status',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              },
              {
                title: 'Total Majuri',
                field: 'total_majuri',
                minWidth: 100,
                vertAlign: 'middle',
                hozAlign: 'center',
                print: false,
                download: false,
                responsive: 0,
                formatter: "textarea"
              }
            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
        } else if ([4].includes(user_details.value.user_role)) {

          tabulator.value = new Tabulator(tableRef.value, {
            ajaxURL: BASE_URL + 'api/report/report-gpp',
            ajaxConfig: {
              method: "GET", //set request type to Position
              headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
              },
            },
            ajaxParams: params,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            headerSort: false,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: 'collapse',
            placeholder: 'No matching records found',
            columns: [{
              formatter: 'responsiveCollapse',
              width: 40,
              minWidth: 30,
              hozAlign: 'center',
              resizable: false,
              headerSort: false
            },

            // For HTML table
            {
              title: 'Packet No',
              field: 'number',
              responsive: 0,
              vertAlign: 'middle',
              hozAlign: 'left',
              minWidth: 90,
              print: false,
              download: false,
              formatter: "textarea",
              cellClick: function (e, cell) {

                // Open Model Of Planning
                get_plan_details(cell.getData().id)

              }
            },
            {
              title: 'Plan Weight',
              field: 'issue_weight',
              minWidth: 100,
              vertAlign: 'middle',
              hozAlign: 'center',
              print: false,
              download: false,
              responsive: 0,
              formatter: "textarea"
            },
            {
              title: 'Total Plan',
              field: 'total_plan',
              minWidth: 100,
              vertAlign: 'middle',
              hozAlign: 'center',
              print: false,
              download: false,
              responsive: 0,
              formatter: "textarea"
            },
            {
              title: 'Final Plan',
              field: 'plan_status',
              minWidth: 100,
              vertAlign: 'middle',
              hozAlign: 'center',
              print: false,
              download: false,
              responsive: 0,
              formatter: "textarea"
            },
            {
              title: 'Total Majuri',
              field: 'total_majuri',
              minWidth: 100,
              vertAlign: 'middle',
              hozAlign: 'center',
              print: false,
              download: false,
              responsive: 0,
              formatter: "textarea"
            }
            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
        }


        axios({
          url: 'api/report/report-gpp-total',
          method: 'post',
          baseURL: BASE_URL,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          data: params,
          config: {
            headers: {
              'Accept': 'application/json'
            }
          }
        }).then((res) => {
          page_data.total = res.data.items
        })
      }

    }

    const get_plan_details = (id) => {

      cash('#planning-detail-modal').modal('show')

      axios({
        url: 'api/report/report-gpp-plans',
        method: 'post',
        baseURL: BASE_URL,
        data: {
          packet_id: window.btoa(id)
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {

        page_data.packet_details = res.data.packet_item
        page_data.plan_details = res.data.items

      }).catch(() => {
        page_data.packet_details = []
        page_data.plan_details = []
      })

    }

    const get_planner_details = () => {

      axios({
        url: 'api/master/dp_planner',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        page_data.planner_details = res.data.items
      })
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    const onResetFilter = () => {

      filter_data.kapan_no = ""
      filter_data.planner_id = "0"
      filter_data.stone_id = "0"
      filter_data.issue_pending = false
      filter_data.start_date = moment().startOf('month').format('YYYY-MM-DD')
      filter_data.end_date = moment().format('YYYY-MM-DD')
      filter_data.final_plan = "0"

      if ([5, 6].includes(user_details.value.user_role)) {
        filter_data.report_type = "2"
      } else if ([1, 2, 8].includes(user_details.value.user_role)) {
        filter_data.report_type = "1"
      }
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    const export_planner_info = () => {
      let objectDate = new Date();

          let day = objectDate.getDate();
          let month = objectDate.getMonth();
          let year = objectDate.getFullYear();

          let format1 = day + "" + month + "" + year;


          let filename = "planning-report-" + format1 + ".xlsx";
          // let filename = "kapan-purchase" + ".xlsx";

          // let selected_items = []
          // if(page_data.selected_items.length > 0) {

          //   page_data.selected_items.forEach(element => {

          //     selected_items.push(element.id)
          //   });

          // }

          const params = {
            kapan_no: (export_data.kapan_no == '' ? '' : window.btoa(export_data.kapan_no)),
            planner_id: (export_data.planner_id == '0' ? '' : window.btoa(export_data.planner_id)),
            stone_id: (export_data.stone_id == '0' ? '' : window.btoa(export_data.stone_id)),
            report_type: (export_data.report_type == '0' ? '' : window.btoa(export_data.report_type)),
            issue_pending: (export_data.issue_pending),
            start_date: (export_data.start_date),
            end_date: (export_data.end_date),
            final_plan: (export_data.final_plan == '0' ? '' : window.btoa(export_data.final_plan)),
          }
          let promise = axios({
              url: "/api/export/download_search_report",
              method: "post",
              data: params,
              baseURL: BASE_URL,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              },
              responseType: "blob",
          });

          return promise
              .then((result) => result.data)
              .then((data) => {
                  FileDownload(data, filename);
              })
              .catch((_error) => {
                  return [];
              });
    }

    onMounted(() => {
      if ([5, 6].includes(user_details.value.user_role)) {
        filter_data.report_type = "2"
      } else if ([1, 2, 8].includes(user_details.value.user_role)) {
        filter_data.report_type = "1"
      }
      get_planner_details()
      initTabulator()

    })
    return {
      page_data,
      user_details,
      filter_data,
      initTabulator,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      tableRef,
      onResetFilter,
      export_planner_info

    }
  }
})
</script>
