<template>
  <div>
    <div class="pos intro-y grid grid-cols-12 gap-2 mt-2">
      <!-- BEGIN: Ticket -->
      <div class="col-span-12">
        <div class="intro-y pr-1">
          <div class="pos__tabs nav nav-tabs justify-center" role="tablist">
            <a id="department-summary" data-toggle="tab" data-target="#department-summary-details" href="javascript:;"
              class="flex-1 py-1 rounded-md text-center box border mx-1 active" role="tab"
              aria-controls="department-summary-details" aria-selected="true">Department Summary</a>
            <a id="manager-summary" data-toggle="tab" data-target="#manager-summary-details" href="javascript:;"
              class="flex-1 py-1 rounded-md text-center box border mx-1" role="tab"
              aria-controls="manager-summary-details" aria-selected="false">Manager Summary</a>
            <a id="process-summary" data-toggle="tab" data-target="#process-summary-details" href="javascript:;"
              class="flex-1 py-1 rounded-md text-center box border mx-1" role="tab"
              aria-controls="process-summary-details" aria-selected="false">Process Summary</a>
            <a id="kapan-summary" data-toggle="tab" data-target="#kapan-summary-details" href="javascript:;"
              class="flex-1 py-1 rounded-md text-center box border mx-1" role="tab"
              aria-controls="kapan-summary-details" aria-selected="false">Kapan Summary</a>
            <a id="lot-details" data-toggle="tab" data-target="#lot-details-details" href="javascript:;"
              class="flex-1 py-1 rounded-md text-center box border mx-1" role="tab" aria-controls="lot-details-details"
              aria-selected="false">Lot Details</a>
          </div>
        </div>
        <div class="tab-content">
          <div id="department-summary-details" class="tab-pane active" role="tabpanel"
            aria-labelledby="department-summary-tab">
            <div class="w-full">
              <DepartmentSymmary @export="onExportXlsx" ref="DepartmentSymmaryMenu" />
            </div>
          </div>
          <div id="manager-summary-details" class="tab-pane" role="tabpanel" aria-labelledby="manager-summary-tab">
            <div class="w-full">
              <ManagerSymmary @export="onExportXlsx" ref="ManagerSymmaryMenu" />
            </div>
          </div>
          <div id="process-summary-details" class="tab-pane" role="tabpanel" aria-labelledby="process-summary-tab">
            <div class="w-full">
              <ProcessSummary @export="onExportXlsx" ref="ProcessSummaryMenu" />
            </div>
          </div>
          <div id="kapan-summary-details" class="tab-pane" role="tabpanel" aria-labelledby="kapan-summary-tab">
            <div class="w-full">
              <KapanSummary @export="onExportXlsx" ref="KapanSummaryMenu" />
            </div>
          </div>
          <div id="lot-details-details" class="tab-pane" role="tabpanel" aria-labelledby="lot-details-tab">
            <div class="w-full">
              <LotSummary @export="onExportXlsx" ref="LotSummaryMenu" />
            </div>
          </div>
        </div>
      </div>
      <!-- END: Ticket -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import { defineComponent, onMounted, reactive,ref, toRef, watch, computed } from 'vue';
import DepartmentSymmary from '@/views/Report/stock-summary/Inventory/department.vue'
import ManagerSymmary from '@/views/Report/stock-summary/Inventory/manager.vue'
import ProcessSummary from '@/views/Report/stock-summary/Inventory/process.vue'
import KapanSummary from '@/views/Report/stock-summary/Inventory/kapan.vue'
import LotSummary from '@/views/Report/stock-summary/Inventory/lot.vue'
import FileDownload from "js-file-download";
import store from '@/store'

export default defineComponent({
  components: {
    DepartmentSymmary,
    ManagerSymmary,
    ProcessSummary,
    KapanSummary,
    LotSummary
  },

  setup() {


    const page_data = reactive({
      manager_items: [],
      process_items: [],
      departments: [],
      parties: [],
      lot_items: [],
      employee_items: [],
      parameters: {
        color: [],
        cut: [],
        purity: [],
        polish: [],
        shape: [],
        symmetry: [],
        fluerocent: [],
        tension: [],
        stone: []
      }
    })

    const DepartmentSymmaryMenu = ref();
    const ManagerSymmaryMenu = ref();
    const ProcessSummaryMenu = ref();
    const KapanSummaryMenu = ref();
    const LotSummaryMenu = ref();
    const user_details = computed(() => store.state.user.currentUser)

    const getManagerDetails = async () => {
      let params = {
      }
      let api_url = 'api/master/dp_managers'
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.manager_items = data.items

          DepartmentSymmaryMenu.value.setManagerItems(page_data.manager_items)
          ManagerSymmaryMenu.value.setManagerItems(page_data.manager_items)
          ProcessSummaryMenu.value.setManagerItems(page_data.manager_items)
          KapanSummaryMenu.value.setManagerItems(page_data.manager_items)
          LotSummaryMenu.value.setManagerItems(page_data.manager_items)
        })
        .catch(_error => {
          page_data.manager_items = []
        });
    }

    const getProcessDetails = async () => {
      let api_url = 'api/master/dp_process'

      let promise = axios({
        url: api_url,
        method: "post",
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.process_items = data.items

          DepartmentSymmaryMenu.value.setProcessItems(page_data.process_items)
          ManagerSymmaryMenu.value.setProcessItems(page_data.process_items)
          ProcessSummaryMenu.value.setProcessItems(page_data.process_items)
          KapanSummaryMenu.value.setProcessItems(page_data.process_items)
          LotSummaryMenu.value.setProcessItems(page_data.process_items)
        })
        .catch(_error => {
          page_data.process_items = []
        });
    }

    const getLotDetails = async () => {
      let api_url = 'api/master/dp_lot_all_details'

      let promise = axios({
        url: api_url,
        method: "post",
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.lot_items = data.items

          DepartmentSymmaryMenu.value.setLotItems(page_data.lot_items)
          ManagerSymmaryMenu.value.setLotItems(page_data.lot_items)
          ProcessSummaryMenu.value.setLotItems(page_data.lot_items)
          KapanSummaryMenu.value.setLotItems(page_data.lot_items)
          LotSummaryMenu.value.setLotItems(page_data.lot_items)
        })
        .catch(_error => {
          page_data.lot_items = []
        });
    }

    const getPartyDetails = () => {

      let params = {
      }
      let api_url = 'api/master/dp_parties'
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.parties = data.items

          DepartmentSymmaryMenu.value.setPartyItems(page_data.parties)
          ManagerSymmaryMenu.value.setPartyItems(page_data.parties)
          ProcessSummaryMenu.value.setPartyItems(page_data.parties)
          KapanSummaryMenu.value.setPartyItems(page_data.parties)
          LotSummaryMenu.value.setPartyItems(page_data.parties)
        })
        .catch(_error => {
          page_data.parties = []
        });
    }

    const getDiamontData = () => {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept': 'application/json' } }
      }).then((res) => {
        page_data.parameters.color = res.data.color
        page_data.parameters.cut = res.data.cut
        page_data.parameters.purity = res.data.purity
        page_data.parameters.symmetry = res.data.symmetry
        page_data.parameters.polish = res.data.polish
        page_data.parameters.fluerocent = res.data.fluerocent
        page_data.parameters.tension = res.data.tension
        page_data.parameters.shape = res.data.shape
        page_data.parameters.stone = res.data.stone

        // LotSummaryMenu.value.setParamsItems(page_data.parameters)

      })
    }

    const onExportXlsx = (filters) =>{

      const today = new Date();
      const date = today.getDate() + '' + (today.getMonth() + 1) + '' + today.getFullYear();
      // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date;
      let filename = "EXCELLENCE_" +dateTime + ".xlsx";

      let promise = axios({
          url: "/api/report/ssr-sse",
          method: "post",
          data: filters,
          baseURL: BASE_URL,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
              headers: {
                  Accept: "application/json"
              }
          },
          responseType: "blob",
      });

      return promise
          .then((result) => result.data)
          .then((data) => {
              FileDownload(data, filename);
          })
          .catch((_error) => {
              return [];
          });
    }
    const getDepartmentDetails = () => {
      let params = {
      }
      let api_url = 'api/master/dp_department'
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.departments = data.items

          DepartmentSymmaryMenu.value.setDepartmentItems(page_data.departments)
          ManagerSymmaryMenu.value.setDepartmentItems(page_data.departments)
          ProcessSummaryMenu.value.setDepartmentItems(page_data.departments)
          KapanSummaryMenu.value.setDepartmentItems(page_data.departments)
          LotSummaryMenu.value.setDepartmentItems(page_data.departments)
        })
        .catch(_error => {
          page_data.departments = []
        });
    }

    onMounted(() => {
      getManagerDetails()
      getProcessDetails()
      getPartyDetails()
      getDepartmentDetails()
      // getDiamontData()
      getLotDetails()
    })


    return {

      user_details,
      DepartmentSymmaryMenu,
      ManagerSymmaryMenu,
      ProcessSummaryMenu,
      KapanSummaryMenu,
      LotSummaryMenu,
      onExportXlsx
    }
  }
})
</script>
