<template>
  <div>
    <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
        <div class="font-medium text-primary-3" v-if="page_data.error">{{ page_data.error }}</div>
    </div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Available Stock</h2>
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <div class="text-sm font-bold mr-2 mt-5" v-if="page_data.packet_list.length > 0">Selected Stock : {{ page_data.packet_list.length }}</div>
              <div class="flex items-center mt-2 ml-2">
                  <div class="mr-2 flex items-center">
                      <input id="remember-me" type="checkbox" v-model="page_data.check" class="form-check-input border mr-2 bg-white" />
                      <label for="remember-me" class="text-md font-medium">Manual</label>
                  </div>
              </div>
              <div class="w-full flex sm:w-auto mt-3 ml-2">
                  <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
                      <input type="text" v-model="page_data.search" @change="getPacketDetailsScan" autofocus ref="focusMe" :disabled="page_data.scan_disable == true" class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Scan Packet" />
                      <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
                  </div>
                  <button class="btn btn-primary shadow-md mr-2" @click="add_stock_to_list">Add</button>
                  <button class="btn btn-primary shadow-md mr-2" @click="MergePacket" v-if="page_data.packet_list.length > 0">Merge Packet</button>
              </div>
          </div>
      </div>

      <!-- Begin: Scanned Lot Table -->
      <div class="intro-y box py-5 px-3 mt-5" v-if="page_data.packet_list.length > 0">
        <div class="overflow-auto">
          <table class="table w-full border">
              <thead>
                  <tr>
                      <th class="whitespace-nowrap">Pkt No</th>
                      <th class="whitespace-nowrap">Stock No</th>
                      <th class="whitespace-nowrap">Cr. Weight</th>
                      <th class="whitespace-nowrap">Exp. Wt</th>
                      <th class="whitespace-nowrap">Shape</th>
                      <th class="whitespace-nowrap">Color</th>
                      <th class="whitespace-nowrap">Purity</th>
                      <th class="whitespace-nowrap">Comment</th>
                      <th class="whitespace-nowrap">Issue Comment</th>
                      <th class="whitespace-nowrap"></th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(packet, index) in page_data.packet_list" :key="index" class="border hover:bg-gray-200 p-0 cursor-pointer">
                      <td class="whitespace-nowrap">{{ packet.number }}</td>
                      <td class="whitespace-nowrap">{{ packet.vepari_no ? packet.vepari_no : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.cr_weight ? packet.cr_weight : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.cr_polish_weight ? packet.cr_polish_weight : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.shape ? packet.shape : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.color ? packet.color : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.purity ? packet.purity : '-' }}</td>
                      <td class="whitespace-nowrap">{{ packet.comment ? packet.comment : '-' }}</td>
                      <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="packet.new_comment" class="form-control rounded-none" placeholder="Comment" aria-describedby="input-group-3" /></td>
                      <td class="py-2 font-medium text-center">
                          <div class="flex justify-start items-center p-0 m-0">
                            <a href="javascript:;" @click="removeItem(index)" class="btn btn-danger btn-sm mx-2">
                                  <XIcon class="w-4 h-4" /></a>
                          </div>
                      </td>
                  </tr>
              </tbody>
          </table>
        </div>
      </div>
      <!-- End: Scanned Lot Table -->
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
          <div>
              <div class="grid grid-cols-12 gap-5">
                  <!-- <div class="col-span-12 md:col-span-12 lg:col-span-3 xl:col-span-2">
                          <h2 class="text-lg font-bold">Available Packets</h2>
                      </div> -->
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                      <div>
                          <input id="tabulator-html-filter-value" v-model="filter_data.stock_no" type="text" class="form-control 2xl:w-full" placeholder="Stock No" />
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                      <div>
                          <TomSelect v-model="filter_data.kapan_id" :options="{
                    placeholder: 'Select Kapan',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Kapan'
                      }
                    }
                  }" class="w-full">
                              <option value="0">SELECT KAPAN</option>
                              <option v-for="(item, index) in page_data.kapan_details" :key="index" :value="item.id">{{item.lot_no}} ({{ item.voucher_no }})</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                      <div>
                          <TomSelect v-model="filter_data.packet_type" :options="{
                    placeholder: 'Select Packet Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Packet Type'
                      }
                    }
                  }" class="w-full">
                              <option value="0">PACKET TYPE</option>
                              <option value="makeable">MAKEABLE STOCK</option>
                              <option value="rough">ROUGH STOCK</option>
                              <option value="merged">MERGED STOCK</option>
                              <!-- <option value="video">Video</option> -->
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div>
                          <button class="btn btn-secondary mr-2" @click="resetFilters">
                              Reset
                          </button>
                          <button class="btn btn-primary " @click="initTabulator">
                              Generate
                          </button>
                      </div>
                  </div>
                  <div class="col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-3">
                      <div class="flex flex-wrap">

                          <div class="text-sm font-bold mr-4">Total Stock: {{ page_data.total.total_packets ? page_data.total.total_packets : 0 }}</div>
                          <div class="text-sm font-bold">Total WT(Ct): {{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) : 0 }} Ct</div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="overflow-x-auto scrollbar-hidden">
              <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
          </div>
      </div>
      <!-- END: HTML Table Data -->
      <div class="p-0 m-0 hidden">
        <button id="print_barcode_btn" v-print="`#print_barcode`"></button>
        <div id="print_barcode" class="absolute top-0 left-0 p-0 m-0 mt-2">
            <table class="p-0 m-0 w-full text-black" border="collapse">
                <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                    <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ page_data.packet_item.number }}</th>
                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ page_data.packet_item.vepari_no }}</th>
                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ page_data.packet_item.mk_weight ? $h.myFloat(page_data.packet_item.mk_weight) : $h.myFloat(page_data.packet_item.weight) }}</th>
                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="page_data.packet_item.mk_weight != null">PW : {{ page_data.packet_item.polish_weight }}</th>
                </tr>
                <tr class="text-center m-0 p-0">
                    <th colspan="9" class="text-center">
                        <VueBarcode :value="page_data.packet_item.barnum" :options="{ displayValue: false,height:22,width:2 }" tag="svg"></VueBarcode>
                    </th>
                </tr>
            </table>
            <p class="p-0 text-black w-full text-right"
            style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span
              class="text-right">{{ page_data.packet_item.stone }}</span> / <span class="text-right">Kodllin
              TECHONOLOGY</span></p>
        </div>
    </div>
  </div>
  </template>

  <script>
  /* eslint-disable */
  import {
      BASE_URL
  } from '@/constants/config'
  import axios from 'axios'
  import cash from 'cash-dom'
  import {
      computed,
      defineComponent,
      inject,
      onMounted,
      reactive,
      ref,
      toRef,
      watch
  } from 'vue'
  import print from 'vue3-print-nb'
  import feather from 'feather-icons'
  import Tabulator from 'tabulator-tables'
  import Toastify from 'toastify-js'
  import store from '@/store'
  import router from '@/router'
  import user from '@/store/user'
  export default defineComponent({
      directives: {
          print
      },
      setup() {
          const user_details = computed(() => store.state.user.currentUser)
          const focusMe = ref()
          const swal = inject('$swal')
          const page_data = reactive({
              search: '',
              search_type: '',
              check: false,
              type: '',
              addlist: [],
              packet_list: [],
              addornot: false,
              submit: false,
              message: '',
              error: '',
              kapan_id: '',

              total: [],
              kapan_details: [],
              packet_item: [],
          })
          // const success = toRef(page_data, 'success')
          const filter_data = reactive({
              kapan_id: '0',
              stock_no: '',
              packet_type: '0',
              for_p: false,
              semi_polish: false,
          })
          const tableRef = ref()
          const tabulator = ref()

          const initTabulator = async () => {

              let filter_params = {
                  kapan_id: filter_data.kapan_id == '0' ? '' : window.btoa(filter_data.kapan_id),
                  stock_no: filter_data.stock_no == '' ? '' : window.btoa(filter_data.stock_no),
                  packet_type: filter_data.packet_type == '0' ? '' : window.btoa(filter_data.packet_type),
              }
              tabulator.value = new Tabulator(tableRef.value, {
                  ajaxURL: BASE_URL + 'api/purchase/mpc_gmap',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: filter_params,
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  headerSort: false,
                  pagination: 'remote',
                  paginationSize: 20,
                  paginationSizeSelector: [20, 30, 40],
                  layout: 'fitColumns',
                  // responsiveLayout: 'collapse',
                  placeholder: 'No matching records found',
                  columns: [
                      // {
                      //       formatter: 'responsiveCollapse',
                      //       width: 40,
                      //       minWidth: 30,
                      //       hozAlign: 'center',
                      //       resizable: false,
                      //       headerSort: false
                      //   },

                      // For HTML table
                      {
                          title: 'Packet No',
                          responsive: 0,
                          minWidth: 130,
                          field: 'number',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().urgent == 1 ? '<span class="text-theme-6">'+ cell.getData().number +'</span>' : cell.getData().number
                }</div>
              </div>`
                          }
                      },
                      {
                          title: 'Stock No',
                          responsive: 0,
                          field: 'type',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 130,
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
              <div class="whitespace-nowrap">${
                cell.getData().vepari_no
              }</div>
            </div>`
                          }
                      },
                      {
                          title: 'Type',
                          responsive: 0,
                          field: 'type',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 100,
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().type
                }</div>
              </div>`
                          }
                      },
                      {
                          title: 'Cr.Weight(Ct)',
                          responsive: 0,
                          field: 'cr_weight',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          minWidth: 130,
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().cr_weight
                }</div>
              </div>`
                          }
                      },
                      {
                          title: 'Stn',
                          field: 'stone',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false
                      },
                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })

              let promise = axios({
                  url: "api/purchase/mpc_ampt",
                  method: "post",
                  data: filter_params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      console.log(data);
                      page_data.total = data.total
                  })
                  .catch(_error => {
                      page_data.total = []
                  });
          }

          const add_stock_to_list = () => {
              if (page_data.search != '') {
                  if (page_data.addlist.length <= 0) {
                      verify_stock()
                  } else {
                      for (let i = 0; i < page_data.packet_list.length; i++) {
                          if (page_data.packet_list[i].number == page_data.search || page_data.packet_list[i].barnum == page_data.search || page_data.packet_list[i].vepari_no == page_data.search) {
                              swal("Warning", "Scanned stock already in list", "warning")
                              page_data.search = ''
                              return;
                          } else {
                              page_data.addornot = false
                          }

                      }
                      if (page_data.addornot == false) {
                          verify_stock()
                      }
                  }
              }
          }

          const verify_stock = () => {
              const params = {
                kapan_id: window.btoa(page_data.kapan_id),
                  number: window.btoa(page_data.search),
              }
              axios({
                  url: 'api/purchase/mpc_sap',
                  method: 'post',
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  const index = ref(page_data.addlist.length)
                  page_data.addlist[index.value] = res.data.packet.number
                  page_data.packet_list[index.value] = res.data.packet
                  page_data.kapan_id = res.data.packet.purchase
                  page_data.search = ''
                  focusMe.value.focus()
                  // page_data.checklist[page_data.addlist[index.value].id] = true
              }).catch((err) => {
                  page_data.error = err.response.data.error
                  page_data.search = ''
                  focusMe.value.focus()
                  console.warn(page_data.error)
                  setTimeout(() => {
                      basicNonStickyNotificationPacket()
                  }, 100)
                  // setTimeout(() => {
                  //   page_data.search = ''
                  // }, 1000);
                  setTimeout(() => {
                      page_data.error = ''
                  }, 3000);

              })
          }

          const basicNonStickyNotificationPacket = () => {
            Toastify({
                node: cash('#basic-non-sticky-notification-packet')
                    .clone()
                    .removeClass('hidden')[0],
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true
            }).showToast()
        }

          const removeItem = (id) => {

              page_data.addlist.splice(id, 1);
              page_data.packet_list.splice(id, 1);

              if (page_data.addlist.length <= 0) {
                  page_data.kapan_id = ''
              }
          }

          const getLotDetails = () => {
              axios({
                  url: 'api/master/dp_lot_details',
                  method: 'post',
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  page_data.kapan_details = res.data.items
              })
          }

          const MergePacket = () => {

            swal({
                title: "Are you sure?",
                text: "You are about to take Merge Stock!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Merge Stock",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                preConfirm: login => {

                  const params = {
                      stock_arr: page_data.packet_list,
                      kapan_id: page_data.kapan_id,
                  }
                  page_data.submit = true
                  axios({
                      url: 'api/purchase/mpc_mp',
                      method: 'post',
                      baseURL: BASE_URL,
                      data: params,
                      headers: {
                          Authorization: 'Bearer ' + localStorage.getItem('token')
                      },
                      config: {
                          headers: {
                              'Accept': 'application/json'
                          }
                      }
                  }).then((res) => {

                    page_data.submit = false
                    if(res.data.status == "success") {
                      page_data.addlist = []
                      page_data.packet_list = []
                      page_data.packet_item = res.data.packet_item
                      setTimeout(() => {
                        document.getElementById('print_barcode_btn').click()
                      }, 100);
                      page_data.kapan_id = ''
                      initTabulator()
                      swal("Success!", res.data.message, "success");
                    } else {

                      swal("Error!", res.data.message, "error");
                    }
                  }).catch((err) => {
                      page_data.submit = false
                      swal("Warning!", err.response.data.error, "warning");
                  })
                },
                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.value) {
                }
            });
          }

          const getPacketDetailsScan = () => {
              if (!page_data.check && page_data.search) {
                  add_stock_to_list();
              }
          }

          const resetFilters = () => {
              filter_data.packet_type = '0'
              filter_data.kapan_id = '0'
              filter_data.stock_no = ''
              filter_data.for_p = false
              filter_data.semi_polish = false

              initTabulator()
          }

          onMounted(() => {
              initTabulator()
              getLotDetails()
              focusMe.value.focus()
          })

          return {
              page_data,
              filter_data,
              focusMe,
              user_details,
              getPacketDetailsScan,
              tableRef,
              add_stock_to_list,
              removeItem,
              MergePacket,
              initTabulator,
              resetFilters,
              basicNonStickyNotificationPacket
          }
      }
      // ,
      // mounted() {
      //   this.$refs.focusMe.focus()
      // }
  })
  </script>
