<template>
  <div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-2">

          <div class="grid grid-cols-12 gap-x-2">
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0">Report Type</label>
                      <TomSelect v-model="filter.report_type" :options="{
                    placeholder: 'Report Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Report'
                      }
                    }
                  }" class="w-full">
                          <option value="1">Summary</option>
                          <option value="2">Employee Wise</option>
                          <option value="3">Process Wise</option>
                          <option value="4">Kapan Wise</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Packet / Lot</label>
                      <input id="tabulator-html-filter-value" v-model="filter.packet_no" type="text" class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0" placeholder="Search Packet / Kapan" />
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Kapan</label>
                      <TomSelect v-model="filter.kapan_id" :options="{
                    placeholder: 'Select Kapan',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Kapan</option>
                          <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select Party</label
                    >
                    <TomSelect
                      v-model="filter.party_id"
                      :options="{
                        placeholder: 'Select Party',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Party'
                          }
                        }
                      }"
                      class="w-full"
                    >
                    <option value="0">Select Party</option>
                    <option v-for="party in page_data.parties" :key="party.id" :value="party.id">{{party.first_name}} {{ party.last_name }}</option>
                    </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select Department</label
                    >
                    <TomSelect
                      v-model="filter.department_id"
                      :options="{
                        placeholder: 'Select Department',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Department'
                          }
                        }
                      }"
                      class="w-full"
                    >
                    <option value="0">Select Department</option>
                    <option v-for="department in page_data.departments" :key="department.id" :value="department.id">{{department.department_name}} ( {{ department.department_short_code ? department.department_short_code : '-' }} )</option>
                    </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Process</label>
                      <TomSelect v-model="filter.process_id" @change="getProcessChange" :options="{
                    placeholder: 'Select Process',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Process</option>
                          <option value="stock">In Stock</option>
                          <option value="in_process">In Process</option>
                          <option v-for="item in page_data.process_items" :key="item.id" :value="item.id">{{item.process_name}} ( {{ item.process_short_code ? item.process_short_code : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_role == 1">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Manager</label>
                      <TomSelect v-model="filter.manager_id" @change="getEmployeeDetails" :options="{
                    placeholder: 'Select Manager',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Manager'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Manager</option>
                          <option v-for="item in page_data.manager_items" :key="item.id" :value="item.id">{{item.first_name}} {{item.last_name}} ( {{ item.short_code ? item.short_code : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Karigar Type</label>
                      <TomSelect v-model="filter.employee_type" @change="getEmployeeDetails" :options="{
                    placeholder: 'Select Karigar Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Karigar Type'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Karigar Type</option>
                          <option value="1">Employee</option>
                          <option value="2">Planner / Checker</option>
                          <option value="3">Job Party</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="filter.employee_type != '0'">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Employee</label>
                      <TomSelect v-model="filter.employee_id" :options="{
                    placeholder: 'Select Employee',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Employee'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Employee</option>
                          <option v-for="item in page_data.employee_items" :key="item.id" :value="item.id">{{item.first_name}} {{item.last_name}} ( {{ item.process_short_code ? item.short_code : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Type</label>
                      <TomSelect v-model="filter.packet_type" :options="{
                    placeholder: 'Select Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Type'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Type</option>
                          <option value="makeable">Makeable</option>
                          <option value="rough">Rough</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Stock Type</label>
                      <TomSelect v-model="filter.stock_type" :options="{
                    placeholder: 'Stock Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Stock Type'
                      }
                    }
                  }" class="w-full">
                          <option value="0">All</option>
                          <option value="1">Home Party</option>
                          <option value="2">Job Work</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Stock Status</label>
                      <TomSelect v-model="filter.stock_status" :options="{
                    placeholder: 'Stock Status',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Stock Status'
                      }
                    }
                  }" class="w-full">
                          <option value="0">All</option>
                          <option value="1">Internal</option>
                          <option value="2">External</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
              <div>
                <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-0"
                  >Select Start Date</label
                ><input
                  class="show-code form-check-switch mr-0 ml-3 h-5"
                  type="checkbox" v-model="filter.issue_pending"
                /></div>
                <input
                  type= "date"
                  id="update-profile-form-13"
                  :disabled="filter.issue_pending"
                  class="form-control"
                  placeholder=""
                  v-model="filter.issue_date"
                />
              </div>

          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-0"
                >Select End Date</label
              >
              <input
                type= "date"
                id="update-profile-form-13"

                class="form-control"
                placeholder=""
                :disabled="filter.issue_pending"
                v-model="filter.receive_date"
              />
            </div>
          </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex">

                      <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                          Reset
                      </button>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex">

                      <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="getReportDetails()">
                          Generate
                      </button>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="exportReportDetails">
                        Export
                    </button>
                </div>
              </div>
          </div>

          <div>
              <!-- BEgin: Process Report Start-->
              <div id="responsive-table" class="p-5" v-if="page_data.loading">
                  <div class="preview">
                      <div class="overflow-x-auto">
                          <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                              <LoadingIcon icon="bars" class="w-8 h-8" />
                              <div class="text-center text-xs mt-2">Getting Latest Price</div>
                          </div>
                      </div>
                  </div>
              </div>

              <div v-else>
                  <div class="scrollbar-hidden">
                      <div class="intro-y col-span-12 overflow-auto tableFixHead mt-3">
                          <table class="w-full border-2" v-if="api_filter.report_type == 1">
                              <thead>
                                  <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">MANAGER</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">TOTAL PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">TOTAL CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">AVAILABLE PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">AVAILABLE CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PROCESS PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PROCESS CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">HOLD PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">HOLD CT</th>
                                    </tr>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_pieces ? page_data.total.total_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.available_pieces ? page_data.total.available_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.available_weight ? $h.myFloat(page_data.total.available_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.porcess_pieces ? page_data.total.porcess_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.porcess_weight ? $h.myFloat(page_data.total.porcess_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.stock_hold_pieces ? page_data.total.stock_hold_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.stock_hold_weight ? $h.myFloat(page_data.total.stock_hold_weight) + ' Ct' : '-' }}</td>
                                    </tr>
                              </thead>
                                  <tbody>
                                  <tr v-for="(item,index) in page_data.items" :key="index">
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ index + 1 }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ item.first_name + ' ' + item.last_name + ' ( ' + item.short_name + ' )'  }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.total_pieces ? item.total_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.total_weight ? $h.myFloat(item.total_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.available_pieces ? item.available_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.available_weight ? $h.myFloat(item.available_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.porcess_pieces ? item.porcess_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.porcess_weight ? $h.myFloat(item.porcess_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.stock_hold_pieces ? item.stock_hold_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.stock_hold_weight ? $h.myFloat(item.stock_hold_weight) : '0') }}</td>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_pieces ? page_data.total.total_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.available_pieces ? page_data.total.available_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.available_weight ? $h.myFloat(page_data.total.available_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.porcess_pieces ? page_data.total.porcess_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.porcess_weight ? $h.myFloat(page_data.total.porcess_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.stock_hold_pieces ? page_data.total.stock_hold_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.stock_hold_weight ? $h.myFloat(page_data.total.stock_hold_weight) + ' Ct' : '-' }}</td>
                                  </tr>
                                </tbody>

                          </table>
                          <table class="w-full border-2" v-else-if="api_filter.report_type == 2">
                              <thead>
                                  <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">MANAGER</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">EMPLOYEE</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">TOTAL PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">TOTAL CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">AVAILABLE PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">AVAILABLE CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PROCESS PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PROCESS CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">HOLD PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">HOLD CT</th>
                                    </tr>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_pieces ? page_data.total.total_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.available_pieces ? page_data.total.available_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.available_weight ? $h.myFloat(page_data.total.available_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.porcess_pieces ? page_data.total.porcess_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.porcess_weight ? $h.myFloat(page_data.total.porcess_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.stock_hold_pieces ? page_data.total.stock_hold_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.stock_hold_weight ? $h.myFloat(page_data.total.stock_hold_weight) + ' Ct' : '-' }}</td>
                                    </tr>
                              </thead>
                                <tbody v-for="(item,index) in page_data.items" :key="index">
                                  <tr v-for="(single_item,ind) in item.sub_details" :key="ind" >
                                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                    <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r">{{ ind == 0 ? ( item.first_name + ' ' + item.last_name + ' ( ' + item.short_name + ' )'  ) : '' }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.employee_short_name ? single_item.employee_short_name : '-') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.total_pieces ? single_item.total_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.total_weight ? $h.myFloat(single_item.total_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.available_pieces ? single_item.available_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.available_weight ? $h.myFloat(single_item.available_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.porcess_pieces ? single_item.porcess_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.porcess_weight ? $h.myFloat(single_item.porcess_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.stock_hold_pieces ? single_item.stock_hold_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.stock_hold_weight ? $h.myFloat(single_item.stock_hold_weight) : '0') }}</td>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r"></td>
                                    <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r">-</td>
                                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.total_pieces ? item.total_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.total_weight ? $h.myFloat(item.total_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.available_pieces ? item.available_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.available_weight ? $h.myFloat(item.available_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.porcess_pieces ? item.porcess_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.porcess_weight ? $h.myFloat(item.porcess_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.stock_hold_pieces ? item.stock_hold_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.stock_hold_weight ? $h.myFloat(item.stock_hold_weight) : '0') }}</td>
                                  </tr>
                                </tbody>

                          </table>
                          <table class="w-full border-2" v-else-if="api_filter.report_type == 3">
                              <thead>
                                  <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">MANAGER</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PROCESS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">TOTAL PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">TOTAL CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">AVAILABLE PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">AVAILABLE CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PROCESS PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PROCESS CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">HOLD PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">HOLD CT</th>
                                    </tr>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_pieces ? page_data.total.total_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.available_pieces ? page_data.total.available_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.available_weight ? $h.myFloat(page_data.total.available_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.porcess_pieces ? page_data.total.porcess_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.porcess_weight ? $h.myFloat(page_data.total.porcess_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.stock_hold_pieces ? page_data.total.stock_hold_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.stock_hold_weight ? $h.myFloat(page_data.total.stock_hold_weight) + ' Ct' : '-' }}</td>
                                    </tr>
                              </thead>
                                <tbody v-for="(item,index) in page_data.items" :key="index">
                                  <tr v-for="(single_item,ind) in item.sub_details" :key="ind" >
                                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                    <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r">{{ ind == 0 ? ( item.first_name + ' ' + item.last_name + ' (' + item.short_name + ' )'  ) : '' }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.process_id ? single_item.process_name : 'IN Stock') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.total_pieces ? single_item.total_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.total_weight ? $h.myFloat(single_item.total_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.available_pieces ? single_item.available_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.available_weight ? $h.myFloat(single_item.available_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.porcess_pieces ? single_item.porcess_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.porcess_weight ? $h.myFloat(single_item.porcess_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.stock_hold_pieces ? single_item.stock_hold_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.stock_hold_weight ? $h.myFloat(single_item.stock_hold_weight) : '0') }}</td>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r"></td>
                                    <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r">-</td>
                                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.total_pieces ? item.total_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.total_weight ? $h.myFloat(item.total_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.available_pieces ? item.available_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.available_weight ? $h.myFloat(item.available_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.porcess_pieces ? item.porcess_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.porcess_weight ? $h.myFloat(item.porcess_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.stock_hold_pieces ? item.stock_hold_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.stock_hold_weight ? $h.myFloat(item.stock_hold_weight) : '0') }}</td>
                                  </tr>
                                </tbody>

                          </table>
                          <table class="w-full border-2" v-else-if="api_filter.report_type == 4">
                              <thead>
                                  <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">MANAGER</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">KAPAN</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">TOTAL PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">TOTAL CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">AVAILABLE PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">AVAILABLE CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PROCESS PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PROCESS CT</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">HOLD PCS</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">HOLD CT</th>
                                    </tr>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_pieces ? page_data.total.total_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.available_pieces ? page_data.total.available_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.available_weight ? $h.myFloat(page_data.total.available_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.porcess_pieces ? page_data.total.porcess_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.porcess_weight ? $h.myFloat(page_data.total.porcess_weight) + ' Ct' : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.stock_hold_pieces ? page_data.total.stock_hold_pieces : '-' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.stock_hold_weight ? $h.myFloat(page_data.total.stock_hold_weight) + ' Ct' : '-' }}</td>
                                    </tr>
                              </thead>
                                <tbody v-for="(item,index) in page_data.items" :key="index">
                                  <tr v-for="(single_item,ind) in item.sub_details" :key="ind" >
                                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                    <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r">{{ ind == 0 ? ( item.first_name + ' ' + item.last_name + ' (' + item.short_name + ' )'  ) : '' }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ single_item.lot_no + ' ( ' + single_item.voucher_no + ' )' }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.total_pieces ? single_item.total_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.total_weight ? $h.myFloat(single_item.total_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.available_pieces ? single_item.available_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.available_weight ? $h.myFloat(single_item.available_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.porcess_pieces ? single_item.porcess_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.porcess_weight ? $h.myFloat(single_item.porcess_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.stock_hold_pieces ? single_item.stock_hold_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.stock_hold_weight ? $h.myFloat(single_item.stock_hold_weight) : '0') }}</td>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r"></td>
                                    <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r">-</td>
                                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.total_pieces ? item.total_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.total_weight ? $h.myFloat(item.total_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.available_pieces ? item.available_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.available_weight ? $h.myFloat(item.available_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.porcess_pieces ? item.porcess_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.porcess_weight ? $h.myFloat(item.porcess_weight) : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.stock_hold_pieces ? item.stock_hold_pieces : '0') }}</td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (item.stock_hold_weight ? $h.myFloat(item.stock_hold_weight) : '0') }}</td>
                                  </tr>
                                </tbody>

                          </table>
                          <!-- END: Pagination -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- END: HTML Table Data -->

  </div>
  </template>

  <script setup>
  /* eslint-disable */
  import {
      BASE_URL
  } from '@/constants/config';
  import axios from 'axios';
  import moment from 'moment';
  import {
      defineComponent,
      onMounted,
      reactive,
      toRef,
      watch,
      computed
  } from 'vue';
  import store from '@/store'
  import { helper as $h } from '@/utils/helper'

  // export default defineComponent({
  //     setup() {
    const emit = defineEmits(['export'])
          const user_details = computed(() => store.state.user.currentUser)
          const page_data = reactive({
              total: [],
              items: [],
              manager_items: [],
              departments: [],
              process_items: [],
              lot_items: [],
              parties: [],
              employee_items: [],
              loading: false,
          })

          const filter = reactive({
            report_type: "1",
            packet_no: "",
            kapan_id: '0',
            manager_id: '0',
            process_id: '0',
            employee_id: '0',
            employee_type: '0',
            stock_type: '0',
            department_id: '0',
            packet_type: '0',
            stock_status: '0',
            party_id: '0',
            issue_pending : true,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD')
          })

          const api_filter = reactive({
            report_type: "1",
            packet_no: "",
            kapan_id: '0',
            manager_id: '0',
            process_id: '0',
            employee_id: '0',
            employee_type: '0',
            stock_type: '0',
            department_id: '0',
            packet_type: '0',
            stock_status: '0',
            party_id: '0',
            issue_pending : true,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD')
          })

          const load_details = () => {

          }

          const getReportDetails = async () => {

              api_filter.report_type = filter.report_type
              api_filter.packet_no = filter.packet_no
              api_filter.kapan_id = filter.kapan_id
              api_filter.manager_id = filter.manager_id
              api_filter.process_id = filter.process_id
              api_filter.employee_id = filter.employee_id
              api_filter.employee_type = filter.employee_type
              api_filter.stock_type = filter.stock_type
              api_filter.packet_type = filter.packet_type
              api_filter.department_id = filter.department_id
              api_filter.issue_pending = filter.issue_pending
              api_filter.issue_date = filter.issue_date
              api_filter.stock_status = filter.stock_status
              api_filter.party_id = filter.party_id
              api_filter.receive_date = filter.receive_date

              const params = {
                report_control: window.btoa("manager"),
                report_type: window.btoa(api_filter.report_type),
                stock_status: api_filter.stock_status == '0' ? '' : window.btoa(api_filter.stock_status),
                party_id: api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id),
                packet_no: window.btoa(api_filter.packet_no),
                process_id: api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id),
                kapan_id: api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
                manager_id: api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id),
                department_id: api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id),
                employee_id: api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id),
                employee_type: api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type),
                stock_type: api_filter.stock_type == '0' ? '' : window.btoa(api_filter.stock_type),
                packet_type: api_filter.packet_type == '0' ? '' : window.btoa(api_filter.packet_type),
                issue_pending : api_filter.issue_pending,
                issue_date : api_filter.issue_date,
                receive_date : api_filter.receive_date,
              }
              page_data.loading = true

              let promise = axios({
                  url: 'api/report/ssr-ssr',
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      page_data.items = data.items
                      page_data.total = data.total
                      page_data.loading = false
                  })
                  .catch(_error => {
                      page_data.items = []
                      page_data.total = []
                      page_data.loading = false
                  });
          }

          const exportReportDetails = async () => {


            const params = {
                report_control: window.btoa("manager"),
                report_type: window.btoa(api_filter.report_type),
                stock_status: api_filter.stock_status == '0' ? '' : window.btoa(api_filter.stock_status),
                party_id: api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id),
                packet_no: window.btoa(api_filter.packet_no),
                process_id: api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id),
                kapan_id: api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
                manager_id: api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id),
                department_id: api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id),
                employee_id: api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id),
                employee_type: api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type),
                stock_type: api_filter.stock_type == '0' ? '' : window.btoa(api_filter.stock_type),
                packet_type: api_filter.packet_type == '0' ? '' : window.btoa(api_filter.packet_type),
                issue_pending : api_filter.issue_pending,
                issue_date : api_filter.issue_date,
                receive_date : api_filter.receive_date,
              }

            emit('export', params)
          }
          const getManagerDetails = async () => {
              let params = {
                  process_id: filter.process_id == '0' ? '' : window.btoa(filter.process_id)
              }
              let api_url = ''

              if (filter.process_id == '0') {

                  api_url = 'api/master/dp_managers'
              } else {
                  api_url = 'api/master/get_manager_by_department'
              }
              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.manager_id = '0'
                      page_data.manager_items = data.items
                  })
                  .catch(_error => {
                      filter.manager_id = '0'
                      page_data.manager_items = []
                  });
          }

          const getProcessDetails = async () => {
              let params = {
                  id: window.btoa(filter.manager_id)
              }
              let api_url = ''

              if (filter.manager_id == '0') {

                  api_url = 'api/master/dp_process'
              } else {
                  api_url = 'api/master/dp_process'
              }
              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.process_id = '0'
                      page_data.process_items = data.items
                  })
                  .catch(_error => {
                      filter.process_id = '0'
                      page_data.process_items = []
                  });
          }

          const getLotDetails = async () => {
              let params = {
                  id: window.btoa(filter.manager_id)
              }
              let api_url = 'api/master/dp_lot_all_details'

              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.kapan_id = '0'
                      page_data.lot_items = data.items
                  })
                  .catch(_error => {
                      filter.kapan_id = '0'
                      page_data.lot_items = []
                  });
          }

          const getEmployeeDetails = async () => {

              let params = {
                  manager_id: user_details.value.user_role == 1 ? (filter.manager_id == '0' ? '' : window.btoa(filter.manager_id)) : user_details.value.account_id,
                  process_id: filter.process_id == '0' ? '' : window.btoa(filter.process_id),
                  employee_type: filter.employee_type == '0' ? '' : window.btoa(filter.employee_type),
              }
              let api_url = ''

              if (filter.department_id == '0') {

                  api_url = 'api/master/get_employees_by_manager_employee_type'
              } else {
                  api_url = 'api/master/get_employees_by_manager_employee_type'
              }
              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.employee_id = '0'
                      page_data.employee_items = data.items
                  })
                  .catch(_error => {
                      filter.employee_id = '0'
                      page_data.employee_items = []
                  });
          }

          function onResetFilter() {
            filter.report_type = "1";
            filter.packet_no = "";
            filter.kapan_id = '0';
            filter.manager_id = '0';
            filter.process_id = '0';
            filter.employee_id = '0';
            filter.employee_type = '0';
            filter.department_id = '0';
            filter.stock_type = '0';
            filter.packet_type = '0';
            filter.stock_status = '0';
            filter.party_id = '0';
            filter.issue_pending = true;
            filter.issue_date = moment().startOf('month').format('YYYY-MM-DD');
            filter.receive_date = moment().format('YYYY-MM-DD');
          }

          const getDepartmentDetails = () => {
            let params = {
              // department_id : window.btoa(filter.department_id)
            }
            let api_url = 'api/master/dp_department'
            let promise = axios({
              url: api_url,
              method: "post",
              data: params,
              baseURL: BASE_URL,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: { headers: { Accept: "application/json" } }
            });
            return promise
              .then(result => result.data)
              .then(data => {
                page_data.departments = data.items
              })
              .catch(_error => {
                page_data.departments = []
              });
          }
          const getPartyDetails = () => {

            let params = {
              // department_id : window.btoa(filter.department_id)
            }
            let api_url = 'api/master/dp_parties'
            let promise = axios({
              url: api_url,
              method: "post",
              data: params,
              baseURL: BASE_URL,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: { headers: { Accept: "application/json" } }
            });
            return promise
              .then(result => result.data)
              .then(data => {
                page_data.parties = data.items
              })
              .catch(_error => {
                page_data.parties = []
              });
            }

          function getProcessChange() {
              if (user_details.value.user_role == 1) {
                  getManagerDetails()
              }
              getEmployeeDetails()
          }

          const setProcessItems = (items) => {
      filter.process_id = '0'
      page_data.process_items = items
    }
    const setKapanItems = (items) => {
      filter.kapan_id = '0'
      page_data.lot_items = items
    }
    const setPartyItems = (items) => {
      filter.party_id = '0'
      page_data.parties = items
    }
    const setManagerItems = (items) => {
      filter.manager_id = '0'
          page_data.manager_items = items
    }
    const setDepartmentItems = (items) => {
          page_data.departments = items
    }
    const setLotItems = (items) => {
          page_data.lot_items = items
    }
    const setParamsItems = (items) => {

      page_data.parameters.color = items.color
      page_data.parameters.cut = items.cut
      page_data.parameters.purity = items.purity
      page_data.parameters.symmetry = items.symmetry
      page_data.parameters.polish = items.polish
      page_data.parameters.fluerocent = items.fluerocent
      page_data.parameters.tension = items.tension
      page_data.parameters.shape = items.shape
      page_data.parameters.stone = items.stone
    }
    defineExpose({
            setProcessItems,
            setKapanItems,
            setPartyItems,
            setManagerItems,
            setDepartmentItems,
            setParamsItems,
            setLotItems
          })
          onMounted(() => {
              getReportDetails()
          })
          // return {
          //     page_data,
          //     filter,
          //     api_filter,
          //     user_details,
          //     getReportDetails,
          //     getProcessDetails,
          //     getManagerDetails,
          //     onResetFilter,
          //     getEmployeeDetails,
          //     getProcessChange,
          //     defineExpose
          // }
  //     }
  // })
  </script>
  <style scoped>
  .tableFixHead {
          overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
          max-height: 650px; /* gives an initial height of 200px to the table */
        }
        .tableFixHead thead {
          position: sticky; /* make the table heads sticky */
          top: 0px; /* table head will be placed from the top of the table and sticks to it */
        }
        </style>
